import { getIndicatorsList } from './indicators';
import { TokenRequest, ActionWithEntityById, ActionWithEntityByIdsArray } from './../types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Methodologies } from '../../api/Methodologies';
import { AuditEntitiesSharedSection } from '../../api/data-contracts';
import { logout } from '../../utils/logout';
import { ApiUrl } from './url';

const methodologiesApi = new Methodologies({
  baseUrl: ApiUrl,
  customFetch: (...fetchParams: Parameters<typeof fetch>) => {
    return fetch(...fetchParams).then((res) => {
      const status = res?.status;
      if (status === 401) logout();

      return res;
    });
  },
});

interface CreateOrUpdateSectionRequest extends TokenRequest {
  data: AuditEntitiesSharedSection;
}

export const getSections = createAsyncThunk(
  'sections/getSections',
  async ({ token }: TokenRequest) => {
    const res = await methodologiesApi.getSectionsList({ headers: { Authorization: token || '' } });
    return res.data;
  },
);

export const getSectionById = createAsyncThunk(
  'sections/getSectionById',
  async ({ token, id }: ActionWithEntityById) => {
    const res = await methodologiesApi.getSectionDetail(id, {
      headers: { Authorization: token || '' },
    });
    return res.data as AuditEntitiesSharedSection;
  },
);

export const updateSectionById = createAsyncThunk(
  'sections/updateSectionById',
  async ({ token, data }: CreateOrUpdateSectionRequest, { dispatch }) => {
    const res = await methodologiesApi.updateSectionPartialUpdate(data, {
      headers: { Authorization: token || '' },
    });

    dispatch(getSectionById({ id: data.id ?? '', token }));
    dispatch(getSections({ token }));
    return res.data;
  },
);

export const deleteSectionById = createAsyncThunk(
  'sections/deleteSectionById',
  async ({ token, id }: ActionWithEntityById, { dispatch }) => {
    const res = await methodologiesApi.removeSectionDelete(id, {
      headers: { Authorization: token || '' },
    });

    dispatch(getSections({ token }));
    return res.data;
  },
);

export const createSection = createAsyncThunk(
  'sections/createSections',
  async ({ token, data }: CreateOrUpdateSectionRequest, { dispatch }) => {
    const res = await methodologiesApi.createSectionCreate(data, {
      headers: { Authorization: token || '' },
    });

    dispatch(getSections({ token }));
    return res.data;
  },
);

export const getSectionsList = createAsyncThunk(
  'sections/getSectionsList',
  async ({ token, ids }: ActionWithEntityByIdsArray, { dispatch }) => {
    const res = await methodologiesApi.getSectionsListList(
      { ids },
      { headers: { Authorization: token || '' } },
    );

    const finalIds: string[] = [];
    res.data?.forEach((section) => finalIds.push(...(section.indicators || [])));
    dispatch(getIndicatorsList({ token, ids: finalIds || [] }));

    return res.data;
  },
);
