import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { Logo } from '../../components/Logo';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { Link, useNavigate } from 'react-router-dom';
import { authenticate } from '../../store/requests/auth';
import store from '../../store/store';
import { Heading } from '../../components/Heading';
import ReCAPTCHA from 'react-google-recaptcha';
import { verifyTokenApi } from '../../store/requests/captcha';
import { useSelector } from 'react-redux';
import { captchaSelector } from '../../store/selectors/captcha';

interface LoginData {
  email: string;
  password: string;
}

export function Login() {
  const [loginData, setLoginData] = useState<LoginData>({ email: '', password: '' });
  const navigate = useNavigate();
  const captchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Enter') handleLogin();
    };

    document.addEventListener('keypress', listener);

    return () => document.removeEventListener('keypress', listener);
  });

  const verifyToken = (token: string) => {
    try {
      return store.dispatch(verifyTokenApi({ token: token }));
    } catch (error) {
      console.log('error ', error);
    }
  };

  // const verifyToken = async (token: string) => {
  //   try {
  //     // const response = fetch('http://100.94.62.49:30900/captcha/verify-token', {
  //     //   method: 'POST',
  //     //   headers: {
  //     //     'Content-Type': 'application/json',
  //     //   },
  //     //   body: JSON.stringify({ token: token }),
  //     // })
  //     // return await response.then((res) => res.json());
  //     return await verifyTokenApi({ token: token });
  //   } catch (error) {
  //     console.error('error ', error);
  //   }
  // };

  const handleLogin = async () => {
    if (loginData?.email && loginData.password) {
      const token = captchaRef.current?.getValue();
      if (!token) return;
      // todo uncomment when fixed k8s external resources
      // await verifyToken(token);
      // const captchaToken = useSelector(captchaSelector);
      // if (valid_token === undefined) {
      //   alert('Invalid token');
      //   return;
      // }
      await store.dispatch(authenticate(loginData));
      navigate('/');
    }
  };

  const handleChangeLoginData = (e: ChangeEvent<HTMLInputElement>) => {
    const { type, value } = e.target;
    setLoginData({ ...loginData, [type]: value });
  };

  return (
    <div className="bg-main flex justify-center content-center items-center h-screen">
      <div className="bg-blue flex flex-col items-center px-[30px] py-[40px] border border-darkGray border-opacity-40 shadow-xl rounded-[16px] w-[380px]">
        <Logo version="transparent" size={60} className="mb-[20px]" />
        <Heading className="self-start" size="S">
          Вход в Личный кабинет
        </Heading>
        <Input
          className="w-full"
          type="email"
          placeholder="Почта"
          value={loginData?.email}
          onChange={handleChangeLoginData}
          validationRegExp={
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
          }
          errorMessage="Неверный Email"
        />
        <Input
          type="password"
          placeholder="Пароль"
          className="w-full mt-2"
          value={loginData?.password}
          onChange={handleChangeLoginData}
        />
        <Link
          className="mb-2 text-decoration-line: underline text-link self-end"
          to="/forgot-password"
        >
          Забыли пароль?
        </Link>
        <Link className="mb-2 text-decoration-line: underline text-link self-end" to="/register">
          Зарегистрироваться
        </Link>
        <ReCAPTCHA
          sitekey="6LfaxAcnAAAAAAlWDPPcbCnNl5BMhHaKHIfrQCpq"
          ref={captchaRef}
          className="mb-2"
        />
        <Button className="w-full" onClick={handleLogin}>
          Войти
        </Button>
      </div>
    </div>
  );
}
