import { getMethodologyById } from './methodologies';
import { AuditEntitiesProject } from './../../api/data-contracts';
import { TokenRequest, ActionWithEntityById } from './../types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Projects } from '../../api/Projects';
import { AuditContractsProjectsCreateProjectRequest } from '../../api/data-contracts';
import { logout } from '../../utils/logout';
import { filterProjectsByIdArray } from '../slices/projects';
import { getSections, getSectionsList } from './sections';
import { getIndicators } from './indicators';
import { getDocuments, getDocumentsList } from './documents';
import { getInterviews, getInterviewsList } from './interviews';
import { getAccountDetail, getAccountsList } from './user';
import { ApiUrl } from './url';

const projectsApi = new Projects({
  baseUrl: ApiUrl,
  customFetch: (...fetchParams: Parameters<typeof fetch>) => {
    return fetch(...fetchParams).then((res) => {
      const status = res?.status;
      if (status === 401) logout();

      return res;
    });
  },
});

interface CreateProjectProps extends TokenRequest {
  data: AuditContractsProjectsCreateProjectRequest;
  userId: string;
}

interface UpdateProjectProps extends TokenRequest {
  id: string;
  data: AuditEntitiesProject;
  userId: string;
}

interface UploadDocumentProps extends TokenRequest {
  documentId: string;
  file: File;
}

interface DownloadDocumentProps extends TokenRequest {
  id: string;
  fileName: string;
}

interface GetProjectsProps extends ActionWithEntityById {
  isSupervisor?: boolean;
}

export const createProject = createAsyncThunk(
  'projects/createProject',
  async ({ token, data, userId }: CreateProjectProps, { dispatch }) => {
    const res = await projectsApi.createProjectCreate(data, {
      headers: { Authorization: token || '' },
    });

    dispatch(getUserProjects({ token, id: userId }));
    dispatch(getSections({ token }));
    dispatch(getIndicators({ token }));
    dispatch(getDocuments({ token }));
    dispatch(getInterviews({ token }));

    return res.data;
  },
);

export const updateProject = createAsyncThunk(
  'projects/updateProject',
  async ({ id, userId, data, token }: UpdateProjectProps, { dispatch }) => {
    await projectsApi.updateProjectCreate(id, data, {
      headers: { Authorization: token || '' },
    });

    dispatch(getUserProjects({ token, id: userId }));
  },
);

export const getProjectsDetails = createAsyncThunk(
  'projects/getProjectsDetails',
  // eslint-disable-next-line complexity
  async ({ id, token, isSupervisor }: GetProjectsProps, { dispatch }) => {
    const res = await projectsApi.getProjectDetail(id, {
      headers: { Authorization: token || '' },
    });

    if (!isSupervisor) {
      dispatch(getAccountsList({ token, ids: res.data?.auditors || [] }));
      dispatch(getMethodologyById({ token, id: res.data?.methodologyId || '' }));
      dispatch(getDocumentsList({ token, ids: res.data?.documents || [] }));
      dispatch(getInterviewsList({ token, ids: res.data?.interviews || [] }));
    }
    dispatch(getSectionsList({ token, ids: res.data?.sections || [] }));

    return res.data;
  },
);

export const getUserProjects = createAsyncThunk(
  'projects/getUserProjects',
  async ({ id, token, isSupervisor }: GetProjectsProps, { dispatch }) => {
    const res = await projectsApi.getUserProjectsDetail(id, {
      headers: { Authorization: token || '' },
    });
    dispatch(filterProjectsByIdArray(res.data));
    res.data.forEach((id) => {
      if (!id) return;
      dispatch(getProjectsDetails({ id, token, isSupervisor }));
    });

    return res.data;
  },
);

export const uploadDocument = createAsyncThunk(
  'projects/uploadDocument',
  async ({ token, file, documentId }: UploadDocumentProps, { dispatch }) => {
    await projectsApi.uploadDocumentCreate(
      documentId,
      { file },
      {
        headers: { Authorization: token || '' },
      },
    );

    dispatch(getDocuments({ token }));
  },
);

export const downloadDocument = createAsyncThunk(
  'projects/downloadDocument',
  async ({ token, id, fileName }: DownloadDocumentProps) => {
    const res = await projectsApi.downloadDocumentDetail(id, {
      headers: { Authorization: token || '' },
    });
    const blob = await res.blob();
    const url = window.URL.createObjectURL(blob as Blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  },
);
