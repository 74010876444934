import React from 'react';
import { MdAdd } from 'react-icons/md';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { useModal } from '../../utils/hooks/useModal';
import { InterviewsListTable } from './InterviewsListTable';
import { EditInterviewModal } from './EditInterviewModal';

export function InterviewsList() {
  const { modalOpened, openModal, closeModal } = useModal();

  return (
    <>
      <Heading className="mb-8">Интервью</Heading>
      <Button className="mb-8" onClick={openModal}>
        <div className="flex">
          <MdAdd size={24} className="mr-1" />
          <span>Добавить интервью</span>
        </div>
      </Button>
      <InterviewsListTable />
      <EditInterviewModal modalOpened={modalOpened} onClose={closeModal} />
    </>
  );
}
