import { store } from '../store';
import { getUserProjects } from '../store/requests/projects';
import { getIndicators } from '../store/requests/indicators';
import { getSections } from '../store/requests/sections';
import { getAllMethodologies } from '../store/requests/methodologies';
import { getAllAccounts } from '../store/requests/user';
import { getInterviews } from '../store/requests/interviews';
import { getDocuments } from '../store/requests/documents';
import { AuditContractsAccountsAuthenticateResponse } from '../api/data-contracts';
import { getAllCompanies } from '../store/requests/companies';

interface GetDataProps extends AuditContractsAccountsAuthenticateResponse {
  isSupervisor: boolean;
  isCompanyMember: boolean;
}

export const getData = ({ token, userId, isSupervisor, isCompanyMember }: GetDataProps) => {
  if (!token || !userId) return;

  if (isSupervisor || isCompanyMember) {
    store.dispatch(getAllMethodologies({ token }));
    store.dispatch(getSections({ token }));
    store.dispatch(getIndicators({ token }));
    store.dispatch(getAllAccounts({ token }));
    store.dispatch(getInterviews({ token }));
    store.dispatch(getDocuments({ token }));
    store.dispatch(getAllCompanies({ token }));
  }

  store.dispatch(getUserProjects({ token, id: userId, isSupervisor }));
};
