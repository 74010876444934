/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AuditContractsAccountsAuthenticate,
  AuditContractsAccountsAuthenticateResponse,
  AuditContractsAccountsCreateRequest,
  AuditContractsAccountsForgotPasswordRequest,
  AuditContractsAccountsRegistrationRequest,
  AuditContractsAccountsResetPasswordRequest,
  AuditContractsAccountsRevokeTokenRequest,
  AuditContractsAccountsUpdatePasswordRequest,
  AuditContractsAccountsUpdateRequest,
  AuditContractsAccountsValidateResetTokenRequest,
  AuditEntitiesAccountAccount,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Accounts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Accounts
   * @name AuthenticateCreate
   * @summary Аутентификация пользователя
   * @request POST:/accounts/authenticate
   * @secure
   */
  authenticateCreate = (data: AuditContractsAccountsAuthenticate, params: RequestParams = {}) =>
    this.request<AuditContractsAccountsAuthenticateResponse, any>({
      path: `/accounts/authenticate`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name RefreshTokenCreate
   * @summary Обновление токена
   * @request POST:/accounts/refresh-token
   * @secure
   */
  refreshTokenCreate = (params: RequestParams = {}) =>
    this.request<AuditContractsAccountsAuthenticateResponse, any>({
      path: `/accounts/refresh-token`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name RevokeTokenCreate
   * @summary Удаление токена
   * @request POST:/accounts/revoke-token
   * @secure
   */
  revokeTokenCreate = (
    data: AuditContractsAccountsRevokeTokenRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/accounts/revoke-token`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name RegisterCreate
   * @summary Регистрация пользователя
   * @request POST:/accounts/register
   * @secure
   */
  registerCreate = (data: AuditContractsAccountsRegistrationRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/accounts/register`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name VerifyUserDetail
   * @summary Регистрация пользователя
   * @request GET:/accounts/verifyUser/{userId}
   * @secure
   */
  verifyUserDetail = (
    userId: string,
    query?: {
      userId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/accounts/verifyUser/${userId}`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name ForgotPasswordCreate
   * @summary Получение ссылки для сброса пароля
   * @request POST:/accounts/forgot-password
   * @secure
   */
  forgotPasswordCreate = (
    data: AuditContractsAccountsForgotPasswordRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/accounts/forgot-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name ValidateResetTokenCreate
   * @summary Валидация токена для сброса пароля
   * @request POST:/accounts/validate-reset-token
   * @secure
   */
  validateResetTokenCreate = (
    data: AuditContractsAccountsValidateResetTokenRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/accounts/validate-reset-token`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name ResetPasswordCreate
   * @summary Сброс пароля
   * @request POST:/accounts/reset-password
   * @secure
   */
  resetPasswordCreate = (
    data: AuditContractsAccountsResetPasswordRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/accounts/reset-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name GetAllAccountsList
   * @summary Получение всех пользователей
   * @request GET:/accounts/get-all-accounts
   * @secure
   */
  getAllAccountsList = (params: RequestParams = {}) =>
    this.request<AuditEntitiesAccountAccount[], any>({
      path: `/accounts/get-all-accounts`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name GetAccountsListList
   * @summary Получение аккаунтов по списку ID
   * @request GET:/accounts/get-accounts-list
   * @secure
   */
  getAccountsListList = (
    query?: {
      ids?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<AuditEntitiesAccountAccount[], any>({
      path: `/accounts/get-accounts-list`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name GetAccountDetail
   * @summary Получение пользователя по ID
   * @request GET:/accounts/get-account/{id}
   * @secure
   */
  getAccountDetail = (id: string, params: RequestParams = {}) =>
    this.request<AuditEntitiesAccountAccount, any>({
      path: `/accounts/get-account/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name CreateAccountCreate
   * @summary Добавление пользователя
   * @request POST:/accounts/create-account
   * @secure
   */
  createAccountCreate = (data: AuditContractsAccountsCreateRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/accounts/create-account`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Аудиторы могут обновлять свой аккаунт, остальные свои и чужие аккаунты
   *
   * @tags Accounts
   * @name UpdateAccountUpdate
   * @summary Обновление данных пользователя
   * @request PUT:/accounts/update-account/{id}
   * @secure
   */
  updateAccountUpdate = (
    id: string,
    data: AuditContractsAccountsUpdateRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/accounts/update-account/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name UpdatePasswordPartialUpdate
   * @summary Обновление пароля
   * @request PATCH:/accounts/update-password/{id}
   * @secure
   */
  updatePasswordPartialUpdate = (
    id: string,
    data: AuditContractsAccountsUpdatePasswordRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/accounts/update-password/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name DeleteAccountDelete
   * @summary Удаление пользователя
   * @request DELETE:/accounts/delete-account/{id}
   * @secure
   */
  deleteAccountDelete = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/accounts/delete-account/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
}
