import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuditEntitiesSharedSection } from '../../api/data-contracts';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { Table } from '../../components/Table';
import { indicatorsSelector } from '../../store/selectors/indicators';
import { methodologiesSelector } from '../../store/selectors/methodologies';
import { sectionsSelector } from '../../store/selectors/sections';
import { useSearch } from '../../utils/hooks';

const searchFilterFunction = (searchString: string, section: AuditEntitiesSharedSection) => {
  const searchStringLowercase = searchString.toLowerCase();
  const nameLowercase = section.name?.toLowerCase() || '';
  const descriptionLowercase = section.description?.toLowerCase() || '';

  return (
    (nameLowercase.includes(searchStringLowercase) ||
      descriptionLowercase.includes(searchStringLowercase)) ??
    false
  );
};

export function SectionsListTable() {
  const { sections } = useSelector(sectionsSelector);
  const { indicators } = useSelector(indicatorsSelector);
  const { methodologies } = useSelector(methodologiesSelector);

  const {
    filteredValue: filteredSections,
    searchInput,
    setSearchInput,
  } = useSearch(sections || [], searchFilterFunction);

  return (
    <div>
      <Input
        className="w-full"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder="Поиск по названию или описанию"
        disabled={!sections?.length}
      />
      {filteredSections?.length ? (
        <Table
          cols={['Название', 'Описание', 'Показатели', 'В каких методиках используется']}
          rows={filteredSections.map((section) => {
            const sectionIndicatorIds = section.indicators ?? [];
            const indicatorsFiltered = indicators?.filter((indicator) =>
              sectionIndicatorIds.some((id) => id === indicator.id),
            );
            const usedInMethodologies = methodologies?.filter((methodology) =>
              methodology.sections?.some((sectionId) => sectionId === section.id),
            );

            return [
              <Link
                className="text-decoration-line: underline text-link"
                to={`${section.id}/`}
                key={section.id}
              >
                {section.name}
              </Link>,
              section.description,
              indicatorsFiltered?.length ? (
                indicatorsFiltered?.map((indicator, index) => (
                  <React.Fragment key={indicator.id}>
                    <Link
                      to={`../indicators/${indicator.id}`}
                      className="text-decoration-line: underline text-link"
                    >
                      {indicator.name}
                    </Link>
                    {index !== indicatorsFiltered.length - 1 && <span>, </span>}
                  </React.Fragment>
                ))
              ) : (
                <span className="italic text-secondary-text">Ещё нет показателей</span>
              ),
              usedInMethodologies?.length ? (
                usedInMethodologies.map((methodology, index) => (
                  <React.Fragment key={methodology.id}>
                    <Link
                      to={`../methodology/${methodology.id}`}
                      className="text-decoration-line: underline text-link"
                    >
                      {methodology.name}
                    </Link>
                    {index !== usedInMethodologies.length - 1 && <span>, </span>}
                  </React.Fragment>
                ))
              ) : (
                <span className="italic text-secondary-text">Не используется в методиках</span>
              ),
            ];
          })}
          indexColAlign="left"
        />
      ) : (
        <Heading size="S" transparent>
          Разделы отсутствуют
        </Heading>
      )}
    </div>
  );
}
