import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { store } from '../../store';
import { Button } from '../../components/Button';
import { authSelector } from '../../store/selectors/auth';
import { Modal } from '../../components/Modal';
import { AuditEntitiesSharedInterview } from '../../api/data-contracts';
import { interviewsSelector } from '../../store/selectors/interviews';
import {
  changeInterviewForm,
  changeInterviewSubjectForm,
  dropInterviewForm,
  dropInterviewSubjectForm,
} from '../../store/slices/interviews';
import { createInterview, updateInterviewById } from '../../store/requests/interviews';

interface EditInterviewModalProps {
  interview?: AuditEntitiesSharedInterview;
  modalOpened: boolean;
  onClose: () => void;
  parentId?: string;
}

// eslint-disable-next-line complexity
export function EditInterviewModal({
  interview,
  modalOpened,
  onClose,
  parentId,
}: EditInterviewModalProps) {
  const { interviewForm } = useSelector(interviewsSelector);
  const { interviewSubjectForm } = useSelector(interviewsSelector);
  const { token } = useSelector(authSelector);

  useEffect(() => {
    if (interview) {
      store.dispatch(changeInterviewForm({ ...interview }));
      store.dispatch(changeInterviewSubjectForm({ ...interview.subject }));
    }
    return () => {
      store.dispatch(dropInterviewForm());
      store.dispatch(dropInterviewSubjectForm());
    };
  }, [interview]);

  // const handleChangeForm: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) =>
  //   store.dispatch(changeInterviewForm({ ...interviewForm, [e.target.name]: e.target.value }));

  const handleChangeSubjectForm: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) =>
    store.dispatch(
      changeInterviewSubjectForm({ ...interviewSubjectForm, [e.target.name]: e.target.value }),
    );

  const handleSubmit = () => {
    if (!interviewSubjectForm) return;
    if (interview) {
      store.dispatch(
        updateInterviewById({
          data: {
            ...interviewForm,
            subject: interviewSubjectForm,
            questions: interview.questions,
          },
          token,
        }),
      );
    } else {
      store.dispatch(
        createInterview({
          data: {
            ...interviewForm,
            subject: interviewSubjectForm,
            parentId,
          },
          token,
        }),
      );
      store.dispatch(dropInterviewForm());
      store.dispatch(dropInterviewSubjectForm());
    }
    onClose();
  };

  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        <div>
          <Heading className="mb-4" size="S">
            {interview ? 'Редактирование' : 'Создание'} интервью
          </Heading>
          {/* <Input
            title="Имя респондента"
            onChange={handleChangeSubjectForm}
            name="name"
            value={interviewSubjectForm?.name || ''}
            className="w-full"
          /> */}
          <Input
            title="Должность респондента"
            onChange={handleChangeSubjectForm}
            name="position"
            value={interviewSubjectForm?.position || ''}
            className="w-full"
          />
          {/* <Input
            title="Дата начала работы респондента в этой должности"
            onChange={handleChangeSubjectForm}
            name="inPositionFrom"
            value={interviewSubjectForm?.inPositionFrom || ''}
            className="w-full"
          />
          <Input
            title="Интервьюер"
            onChange={handleChangeForm}
            name="takenBy"
            value={interviewForm?.takenBy || ''}
            className="w-full"
          />
          <Input
            title="URL"
            onChange={handleChangeForm}
            name="audiofilePath"
            value={interviewForm?.audiofilePath || ''}
            className="w-full"
          /> */}
          <div className="flex justify-end gap-2">
            <Button onClick={onClose}>Отменить</Button>
            <Button onClick={handleSubmit} disabled={!interviewSubjectForm?.position}>
              {interview ? 'Обновить' : 'Создать'}
            </Button>
          </div>
        </div>
      }
    />
  );
}
