import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { store } from '../../store';
import { Textarea } from '../../components/Textarea';
import { Button } from '../../components/Button';
import { authSelector } from '../../store/selectors/auth';
import { Modal } from '../../components/Modal';
import { AuditEntitiesMethodology } from '../../api/data-contracts';
import { methodologiesSelector } from '../../store/selectors/methodologies';
import { changeMethodologyForm, dropMethodologyForm } from '../../store/slices/methodologies';
import { updateMethodology } from '../../store/requests/methodologies';
import { IndustrySearch } from './IndustrySearch';

interface EditMethodologyModalProps {
  methodology?: AuditEntitiesMethodology;
  modalOpened: boolean;
  onClose: () => void;
}

export function EditMethodologyModal({
  methodology,
  modalOpened,
  onClose,
}: EditMethodologyModalProps) {
  const { methodologyForm } = useSelector(methodologiesSelector);
  const { token } = useSelector(authSelector);

  useEffect(() => {
    if (methodology) {
      store.dispatch(changeMethodologyForm({ ...methodology }));
    }
    return () => {
      store.dispatch(dropMethodologyForm());
    };
  }, []);

  const handleChangeForm: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) =>
    store.dispatch(changeMethodologyForm({ ...methodologyForm, [e.target.name]: e.target.value }));

  const handleSubmit = () => {
    if (!methodologyForm || !methodology) return;
    store.dispatch(
      updateMethodology({
        id: methodology.id ?? '',
        token,
        data: { newVersion: false, methodology: methodologyForm },
      }),
    );
    onClose();
  };

  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        <div>
          <Heading className="mb-1.5">Редактирование методики</Heading>
          <Input
            title="Название методики"
            onChange={handleChangeForm}
            name="name"
            value={methodologyForm?.name || ''}
            className="w-full"
          />
          <Textarea
            title="Комментарий"
            onChange={handleChangeForm}
            name="comment"
            value={methodologyForm?.comment || ''}
            className="w-full h-40 mb-2"
          />
          <IndustrySearch
            onChoose={(str) =>
              store.dispatch(changeMethodologyForm({ ...methodologyForm, companyIndustry: str }))
            }
            initialState={methodologyForm?.companyIndustry || ''}
          />
          <Input
            title="Численность (чел.)"
            onChange={handleChangeForm}
            name="companySize"
            value={methodologyForm?.companySize || ''}
            className="w-full"
          />
          <div className="flex justify-end gap-2">
            <Button onClick={onClose}>Отменить</Button>
            <Button onClick={handleSubmit} disabled={!methodologyForm?.name}>
              Обновить
            </Button>
          </div>
        </div>
      }
    />
  );
}
