/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Heading } from '../../components/Heading';
import { store } from '../../store';
import { Button } from '../../components/Button';
import { authSelector } from '../../store/selectors/auth';
import { Modal } from '../../components/Modal';
import {
  AuditEntitiesSharedComponent,
  AuditEntitiesSharedIndicator,
  AuditEntitiesSharedQuestionModel,
} from '../../api/data-contracts';
import { indicatorsSelector } from '../../store/selectors/indicators';
import { changeComponentForm, dropComponentForm } from '../../store/slices/indicators';
import { Textarea } from '../../components/Textarea';
import { documentsSelector } from '../../store/selectors/documents';
import { updateIndicatorById } from '../../store/requests/indicators';
import { interviewsSelector } from '../../store/selectors/interviews';
import { getComponentDocuments, getComponentQuestions } from './helpers';
import { MdDeleteOutline } from 'react-icons/md';
import { DocumentWithComment, QuestionWithComment, QuestionWithPosition } from './types';
import { EditComponentSourceDocumentModal } from './EditComponentSourceDocumentModal';
import { useModal } from '../../utils/hooks/useModal';
import { EditComponentSourceQuestionModal } from './EditComponentSourceQuestionModal';
import { useParams } from 'react-router-dom';
import { Input } from '../../components/Input';
import { useGetRole } from '../../utils/hooks';

interface EditComponentModalProps {
  indicator: AuditEntitiesSharedIndicator;
  component?: AuditEntitiesSharedComponent & { tableIndex?: number };
  methodologyId?: string;
  modalOpened: boolean;
  onClose: () => void;
}

// eslint-disable-next-line complexity
export function EditComponentModal({
  indicator,
  component,
  modalOpened,
  onClose,
  methodologyId,
}: EditComponentModalProps) {
  const { projectId } = useParams();

  const { isAuditor, isCompanyMember } = useGetRole();
  const { componentForm } = useSelector(indicatorsSelector);
  const { token } = useSelector(authSelector);

  const [selectedDocuments, setSelectedDocuments] = useState<DocumentWithComment[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<QuestionWithComment[]>([]);

  const {
    modalOpened: addDocumentModalOpened,
    closeModal: closeAddDocumentModal,
    openModal: openAddDocumentModal,
  } = useModal();

  const {
    modalOpened: addQuestionModalOpened,
    closeModal: closeAddQuestionModal,
    openModal: openAddQuestionModal,
  } = useModal();

  const { interviews } = useSelector(interviewsSelector);
  const filteredInterviews = methodologyId
    ? interviews?.filter((interview) => interview.parentId === methodologyId)
    : interviews;

  const filteredQuestions: QuestionWithPosition[] | undefined = filteredInterviews
    ?.reduce(
      (acc: QuestionWithPosition[], interview) => [
        ...acc,
        ...(interview.questions ?? []).map((question) => ({
          ...question,
          position: interview.subject?.position ?? '',
        })),
      ],
      [],
    )
    .filter(
      (question) =>
        !selectedQuestions.some((selectedQuestion) => selectedQuestion.id === question.id),
    );

  const { documents } = useSelector(documentsSelector);
  const newDocs = documents || [];
  const filteredDocuments = (
    methodologyId ? newDocs.filter((document) => document.parentId === methodologyId) : documents
  )?.filter(
    (document) =>
      !selectedDocuments.some((selectedDocument) => selectedDocument.id === document.id),
  );

  const allQuestions: AuditEntitiesSharedQuestionModel[] | undefined = interviews?.reduce(
    (acc: AuditEntitiesSharedQuestionModel[], interview) => [
      ...acc,
      ...(interview.questions ?? []),
    ],
    [],
  );

  useEffect(() => {
    if (component) {
      const componentDocuments = getComponentDocuments(component, documents || []);
      const componentQuestions = getComponentQuestions(component, allQuestions || []);

      setSelectedDocuments(componentDocuments);
      setSelectedQuestions(componentQuestions);
      store.dispatch(changeComponentForm({ ...component }));
    }
    return () => {
      store.dispatch(dropComponentForm());
    };
  }, [component]);

  useEffect(() => {
    if (!modalOpened) {
      setSelectedDocuments([]);
      setSelectedQuestions([]);
    }
  }, [modalOpened]);

  const handleChangeForm: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) =>
    store.dispatch(changeComponentForm({ ...componentForm, [e.target.name]: e.target.value }));

  const handleChangeFormNumeric: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { value, name } = e.target;
    const matcher = value.match(/\d*/);

    if (!matcher) return;
    if (matcher[0] === value) {
      const newValue = Number(value) > 10 ? 10 : value;

      store.dispatch(
        changeComponentForm({ ...componentForm, [name]: newValue === '' ? '' : Number(newValue) }),
      );
    }
  };

  const handleSubmit = () => {
    if (component) {
      const indicatorComponents = [...(indicator.components ?? [])];
      if (typeof component.tableIndex === 'number') {
        indicatorComponents[component.tableIndex] = {
          ...componentForm,
          source: [
            ...selectedDocuments.map((document) => ({
              documentId: document.id,
              comment: document.sourceComment,
            })),
            ...selectedQuestions.map((question) => ({
              questionId: question.id,
              comment: question.sourceComment,
            })),
          ],
        };
      }
      store.dispatch(
        updateIndicatorById({
          data: {
            ...indicator,
            components: indicatorComponents,
          },
          token,
        }),
      );
    } else {
      store.dispatch(
        updateIndicatorById({
          data: {
            ...indicator,
            components: [
              ...(indicator.components ?? []),
              {
                ...componentForm,
                source: [
                  ...selectedDocuments.map((document) => ({ documentId: document.id })),
                  ...selectedQuestions.map((question) => ({ questionId: question.id })),
                ],
              },
            ],
          },
          token,
        }),
      );
      store.dispatch(dropComponentForm());
      setSelectedDocuments([]);
      setSelectedQuestions([]);
    }
    onClose();
  };

  const handleDeleteDocument = (index: number) => {
    setSelectedDocuments(selectedDocuments.filter((_, i) => i !== index));
  };

  const handleDeleteQuestion = (index: number) => {
    setSelectedQuestions(selectedQuestions.filter((_, i) => i !== index));
  };

  const editHeadingMessage = component ? 'Редактирование компонента' : 'Создание компонента';
  const headingMessage = projectId ? 'Заполнение компонента' : editHeadingMessage;
  const editActionMessage = component ? 'Обновить' : 'Создать';
  const actionMessage = projectId ? 'Сохранить' : editActionMessage;

  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        <div>
          <Heading className="mb-4" size="S">
            {headingMessage}
          </Heading>
          {projectId ? (
            <>
              <Textarea
                title="Собранные материалы и основания"
                onChange={handleChangeForm}
                name="justification"
                value={componentForm?.justification || ''}
                className="w-full h-40"
              />
              {isCompanyMember ? (
                <>
                  <Input
                    title="Оценка Компании (от 0 до 10)"
                    onChange={handleChangeFormNumeric}
                    name="companyRating"
                    value={componentForm?.companyRating || 0}
                    className="w-full"
                  />
                  <Textarea
                    title="Комментарии Компании"
                    onChange={handleChangeForm}
                    name="companyComment"
                    value={componentForm?.companyComment || ''}
                    className="w-full h-40"
                  />
                </>
              ) : (
                <>
                  <Input
                    title="Оценка Аудитора (от 0 до 10)"
                    onChange={handleChangeFormNumeric}
                    name="auditorComment"
                    value={componentForm?.auditorComment || 0}
                    className="w-full"
                  />
                  <Textarea
                    title="Комментарии Аудитора"
                    onChange={handleChangeForm}
                    name="auditorRating"
                    value={componentForm?.auditorRating || ''}
                    className="w-full h-40"
                  />
                </>
              )}
            </>
          ) : (
            <>
              <Textarea
                title="Что оценивается"
                onChange={handleChangeForm}
                name="measuring"
                value={componentForm?.measuring || ''}
                className="w-full h-40"
              />
              <div className="font-bold mt-4">Документы:</div>
              {selectedDocuments.length !== 0 ? (
                selectedDocuments.map((document, index) => (
                  <div key={document.id} className="flex items-center gap-4">
                    <>
                      <div>{document.type ?? ''}</div>
                      {document.sourceComment && (
                        <div className="italic">Комменатрий: {document.sourceComment}</div>
                      )}
                    </>
                    <div>
                      <MdDeleteOutline
                        className="cursor-pointer"
                        size={18}
                        onClick={() => handleDeleteDocument(index)}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="italic">Нет документов</div>
              )}
              <Button className="mt-2" scale="small" onClick={openAddDocumentModal}>
                Добавить документ
              </Button>
              <div className="font-bold mt-4">Вопросы:</div>
              {selectedQuestions.length !== 0 ? (
                selectedQuestions.map((question, index) => (
                  <div key={question.id} className="flex items-center gap-4">
                    <>
                      <div>{question.question ?? ''}</div>
                      {question.sourceComment && (
                        <div className="italic">Комменатрий: {question.sourceComment}</div>
                      )}
                    </>
                    <div>
                      <MdDeleteOutline
                        className="cursor-pointer"
                        size={18}
                        onClick={() => handleDeleteQuestion(index)}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="italic">Вопросы отсутствуют</div>
              )}
              <Button className="mt-2" scale="small" onClick={openAddQuestionModal}>
                Добавить вопрос
              </Button>
              {/* <MultiSelect
            title="Документы"
            items={filteredDocuments}
            itemToString={(document) => document.type ?? ''}
            onChange={setSelectedDocuments}
            selectedItems={selectedDocuments ?? []}
            filterFunction={(searchString, item) =>
              (item.type ?? '').includes(searchString) ?? false
            }
          /> */}
              {/* <Select
            title="Документы"
            items={filteredDocuments}
            itemToString={(document) => document.type ?? ''}
            onChoose={setSelectedDocument}
            filterFunction={(searchString, item) =>
              (item.type ?? '').includes(searchString) ?? false
            }
          /> */}
              {/* <Select
            title="Вопрос"
            items={filteredQuestions}
            itemToString={(question) => `${question.position}: ${question.question}`}
            onChoose={setSelectedQuestion}
            filterFunction={(searchString, question) =>
              `${question.position}: ${question.question}`
                .toLowerCase()
                .includes(searchString.toLowerCase()) ?? false
            }
          /> */}
            </>
          )}

          <div className="flex justify-end gap-2">
            <Button onClick={onClose}>Отменить</Button>
            <Button disabled={!componentForm?.measuring} onClick={handleSubmit}>
              {actionMessage}
            </Button>
          </div>
          <EditComponentSourceDocumentModal
            modalOpened={addDocumentModalOpened}
            onClose={closeAddDocumentModal}
            onSave={(document) => setSelectedDocuments([...selectedDocuments, document])}
            documents={filteredDocuments || []}
          />
          <EditComponentSourceQuestionModal
            modalOpened={addQuestionModalOpened}
            onClose={closeAddQuestionModal}
            onSave={(question) => setSelectedQuestions([...selectedQuestions, question])}
            questions={filteredQuestions || []}
          />
        </div>
      }
    />
  );
}
