import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuditEntitiesSharedDocument } from '../../api/data-contracts';
import { getDocuments, getDocumentsList } from '../requests/documents';

interface DocumentsSliceProps {
  documents?: AuditEntitiesSharedDocument[];
  documentForm?: AuditEntitiesSharedDocument;
}

const initialState: DocumentsSliceProps = {
  documents: [],
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    dropDocuments: () => initialState,
    changeDocumentForm(state, { payload }: PayloadAction<DocumentsSliceProps['documentForm']>) {
      state.documentForm = payload;
    },
    dropDocumentForm(state) {
      state.documentForm = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDocuments.fulfilled, (state, { payload }) => {
      state.documents = payload;
    });
    builder.addCase(getDocumentsList.fulfilled, (state, { payload }) => {
      const newArr = state.documents || [];
      payload.forEach((item) => {
        if (!newArr?.find((_) => _.id === item.id)) {
          newArr?.push(item as AuditEntitiesSharedDocument);
        }
      });
      state.documents = newArr;
    });
  },
});

export const documentsReducer = documentsSlice.reducer;
export const { dropDocuments, changeDocumentForm, dropDocumentForm } = documentsSlice.actions;
