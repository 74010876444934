import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { AuditEntitiesSharedDocument } from '../../api/data-contracts';
import { Table } from '../../components/Table';
import { formatUnix } from '../../utils/formatUnix';
// import { formatUnix } from '../../utils/formatUnix';

type DocumentTableProps = {
  document: AuditEntitiesSharedDocument;
};

export const DocumentTable: FC<DocumentTableProps> = ({ document }) => {
  const { projectId } = useParams();

  const cols = ['Тип'];
  const rows = [document.type];
  if (projectId) {
    cols.push('Название', 'URL', 'Дата создания', 'Комментарий');
    rows.push(document.fileName, document.url, formatUnix(document.uploadedOn), document.comment);
  }

  return <Table className="mb-8" cols={cols} rows={[rows]} />;
};
