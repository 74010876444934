import React, { useState, ChangeEvent } from 'react';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword, updatePassword } from '../../store/requests/auth';
import store from '../../store/store';
import { Heading } from '../../components/Heading';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/selectors/auth';
import { userSelector } from '../../store/selectors/user';

type FormData = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

export function ResetPassword() {
  const { userData } = useSelector(userSelector);
  const { token } = useSelector(authSelector);

  const location = useLocation();
  const paramsToken = new URLSearchParams(location.search).get('token');

  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormData>({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });

  const handleChangeFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const saveDisabled =
    formData.password !== formData.confirmPassword ||
    !formData.password ||
    !formData.confirmPassword;

  const handleSave = async () => {
    if (!saveDisabled) {
      if (paramsToken) {
        await store.dispatch(
          resetPassword({
            token: paramsToken ?? token ?? '',
            password: formData.password,
            confirmPassword: formData.confirmPassword,
          }),
        );
      } else {
        await store.dispatch(
          updatePassword({
            token,
            id: userData?.id ?? '',
            data: {
              oldPassword: formData.oldPassword,
              newPassword: formData.password,
            },
          }),
        );
      }
      navigate(paramsToken ? '/' : '..');
    }
  };

  return (
    <div className={paramsToken ? 'p-12' : ''}>
      <Heading>Изменение пароля</Heading>
      <div className="bg-semiDarkGray rounded-xl px-8 py-10">
        <div className="mb-2 flex flex-wrap gap-x-8 basis-4/12">
          {!paramsToken && (
            <Input
              title="Старый пароль"
              placeholder="Cтарый пароль"
              type="password"
              name="oldPassword"
              onChange={handleChangeFormData}
              value={formData.oldPassword}
            />
          )}
          <Input
            title="Новый пароль"
            placeholder="Новый пароль"
            type="password"
            name="password"
            onChange={handleChangeFormData}
            value={formData.password}
          />
          <Input
            title="Повторите новый пароль"
            placeholder="Новый пароль"
            type="password"
            name="confirmPassword"
            onChange={handleChangeFormData}
            value={formData.confirmPassword}
          />
        </div>
        <Button disabled={saveDisabled} onClick={handleSave}>
          Сохранить
        </Button>
      </div>
    </div>
  );
}
