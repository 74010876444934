import { TokenRequest, ActionWithEntityById, ActionWithEntityByIdsArray } from './../types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Methodologies } from '../../api/Methodologies';
import { AuditEntitiesSharedIndicator } from '../../api/data-contracts';
import { logout } from '../../utils/logout';
import { ApiUrl } from './url';

const methodologiesApi = new Methodologies({
  baseUrl: ApiUrl,
  customFetch: (...fetchParams: Parameters<typeof fetch>) => {
    return fetch(...fetchParams).then((res) => {
      const status = res?.status;
      if (status === 401) logout();

      return res;
    });
  },
});

interface CreateOrUpdateIndicatorRequest extends TokenRequest {
  data: AuditEntitiesSharedIndicator;
}

export const getIndicators = createAsyncThunk(
  'indicators/getIndicators',
  async ({ token }: TokenRequest) => {
    const res = await methodologiesApi.getIndicatorsList({
      headers: { Authorization: token || '' },
    });
    return res.data;
  },
);

export const createIndicator = createAsyncThunk(
  'indicators/createIndicator',
  async ({ token, data }: CreateOrUpdateIndicatorRequest, { dispatch }) => {
    const res = await methodologiesApi.createIndicatorCreate(data, {
      headers: { Authorization: token || '' },
    });

    dispatch(getIndicators({ token }));
    return res.data;
  },
);

export const updateIndicatorById = createAsyncThunk(
  'indicators/updateIndicatorById',
  async ({ token, data }: CreateOrUpdateIndicatorRequest, { dispatch }) => {
    const res = await methodologiesApi.updateIndicatorPartialUpdate(data, {
      headers: { Authorization: token || '' },
    });

    dispatch(getIndicators({ token }));
    return res.data;
  },
);

export const deleteIndicatorById = createAsyncThunk(
  'indicators/deleteSectionById',
  async ({ token, id }: ActionWithEntityById, { dispatch }) => {
    const res = await methodologiesApi.removeIndicatorDelete(id, {
      headers: { Authorization: token || '' },
    });

    dispatch(getIndicators({ token }));
    return res.data;
  },
);

export const getIndicatorsList = createAsyncThunk(
  'indicators/getIndicatorsList',
  async ({ token, ids }: ActionWithEntityByIdsArray) => {
    const res = await methodologiesApi.getIndicatorsListList(
      { ids },
      {
        headers: { Authorization: token || '' },
      },
    );
    return res.data;
  },
);
