import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { authReducer } from './slices/auth';
import { userReducer } from './slices/user';
import { projectsReducer } from './slices/projects';
import { methodologiesReducer } from './slices/methodologies';
import { sectionsReducer } from './slices/sections';
import { indicatorsReducer } from './slices/indicators';
import { documentsReducer } from './slices/documents';
import { interviewsReducer } from './slices/interviews';
import { captchaReducer } from './slices/captcha';
import { companiesReducer } from './slices/companies';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
};

const reducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  projects: projectsReducer,
  methodologies: methodologiesReducer,
  sections: sectionsReducer,
  indicators: indicatorsReducer,
  documents: documentsReducer,
  interviews: interviewsReducer,
  captcha: captchaReducer,
  companies: companiesReducer,
});

const persistedReducer = persistReducer<ReturnType<typeof reducers>>(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefault) => getDefault({ serializableCheck: false }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export default store;
