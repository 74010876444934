import React, { FC } from 'react';
import { MdDeleteOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { AuditEntitiesSharedSection } from '../../api/data-contracts';
import { Button } from '../../components/Button';
import { SwapButtons } from '../../components/SwapButtons';
import { Table } from '../../components/Table';
import { store } from '../../store';
import { updateSectionById } from '../../store/requests/sections';
import { authSelector } from '../../store/selectors/auth';
import { indicatorsSelector } from '../../store/selectors/indicators';

type SectionTableProps = {
  section: AuditEntitiesSharedSection;
  onDeleteClick: (id: string) => void;
  isEditable: boolean;
};

export const SectionIndicatorsTable: FC<SectionTableProps> = ({
  section,
  onDeleteClick,
  isEditable,
}) => {
  const { methodologyId, projectId } = useParams();

  const { token } = useSelector(authSelector);
  const { indicators } = useSelector(indicatorsSelector);

  const onSwapIndicators = (from: number, to: number) => {
    handleSwapSections(from, to, section, token);
  };

  const cols = ['Название', 'Вес'];
  if (isEditable) {
    cols.unshift('');
    cols.push('');
  }

  return (
    <>
      {section.indicators && section.indicators.length ? (
        <Table
          className="mb-8"
          cols={cols}
          rows={section.indicators.map((indicatorId, index) => {
            const indicator = indicators?.find((indicator) => indicator.id === indicatorId);
            if (!indicator) return [];

            const rows = [
              <Link
                key={indicator.id}
                to={
                  methodologyId || projectId
                    ? `indicator/${indicatorId}`
                    : `../../indicators/${indicator.id}`
                }
                className="text-decoration-line: underline text-link"
              >
                {indicator.name}
              </Link>,
              indicator.weight,
            ];

            if (isEditable) {
              rows.unshift(
                <SwapButtons
                  key="swap"
                  index={index}
                  totalLength={section.indicators?.length ?? 0}
                  onSwap={onSwapIndicators}
                />,
              );
              rows.push(
                <Button
                  key={indicator.id}
                  variant="no-bg"
                  className="text-main"
                  onClick={() => onDeleteClick(indicator.id ?? '')}
                >
                  <MdDeleteOutline size={24} />
                </Button>,
              );
            }

            return rows;
          })}
        />
      ) : (
        <div>Нет показателей</div>
      )}
    </>
  );
};

const handleSwapSections = (
  from: number,
  to: number,
  section: AuditEntitiesSharedSection,
  token?: string | null,
  // eslint-disable-next-line max-params
) => {
  if (!section.indicators) return;

  const indicators = [...section.indicators];

  const temp = indicators[from];
  indicators[from] = indicators[to];
  indicators[to] = temp;

  store.dispatch(updateSectionById({ data: { ...section, indicators }, token }));
};
