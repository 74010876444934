import React, { ReactNode } from 'react';
import { Button } from '../../components/Button';
import { Modal } from '../../components/Modal';
import { Heading } from '../Heading';

interface CommonConfirmModalProps {
  onClose: () => void;
  modalOpened: boolean;
  title: ReactNode;
  description: ReactNode;
}

export function CommonConfirmModal({ modalOpened, onClose, title, description }: CommonConfirmModalProps) {
  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        <div>
          <Heading className="mb-4" size="S">
            {title}
          </Heading>
          <div className="mb-4">
            {description}
          </div>
          <div className="flex justify-end gap-2">
            <Button onClick={onClose}>
              Ок
            </Button>
          </div>
        </div>
      }
    />
  );
}
