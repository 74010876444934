import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { store } from '../../store';
import { Textarea } from '../../components/Textarea';
import { Button } from '../../components/Button';
import { changeSectionForm, dropSectionForm } from '../../store/slices/sections';
import { createSection, updateSectionById } from '../../store/requests/sections';
import { authSelector } from '../../store/selectors/auth';
import { Modal } from '../../components/Modal';
import { AuditEntitiesSharedSection } from '../../api/data-contracts';
import { sectionsSelector } from '../../store/selectors/sections';

interface EditSectionModalProps {
  section?: AuditEntitiesSharedSection;
  modalOpened: boolean;
  onClose: () => void;
}

export function EditSectionModal({ section, modalOpened, onClose }: EditSectionModalProps) {
  const { sectionForm } = useSelector(sectionsSelector);
  const { token } = useSelector(authSelector);

  useEffect(() => {
    if (section) {
      store.dispatch(changeSectionForm({ ...section }));
    }
    return () => {
      store.dispatch(dropSectionForm());
    };
  }, []);

  const handleChangeForm: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) =>
    store.dispatch(changeSectionForm({ ...sectionForm, [e.target.name]: e.target.value }));

  const handleSubmit = () => {
    if (!sectionForm) return;
    if (section) {
      store.dispatch(updateSectionById({ data: sectionForm, token }));
    } else {
      store.dispatch(createSection({ data: sectionForm, token }));
      store.dispatch(dropSectionForm());
    }
    onClose && onClose();
  };

  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        <div>
          <Heading className="mb-4" size="S">
            {section ? 'Редактирование' : 'Создание'} раздела
          </Heading>
          <Input
            title="Название раздела"
            onChange={handleChangeForm}
            name="name"
            value={sectionForm?.name || ''}
            className="w-full"
          />
          <Textarea
            title="Описание раздела"
            onChange={handleChangeForm}
            name="description"
            value={sectionForm?.description || ''}
            className="w-full h-40 mb-2"
          />
          <div className="mb-4 text-secondary-text">
            *Добавить показатели можно будет на странице раздела
          </div>
          <div className="flex justify-end gap-2">
            <Button onClick={onClose}>Отменить</Button>
            <Button onClick={handleSubmit} disabled={!sectionForm?.name}>
              {section ? 'Обновить' : 'Создать'}
            </Button>
          </div>
        </div>
      }
    />
  );
}
