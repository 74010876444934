/* eslint-disable complexity */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AuditEntitiesAccountAccount } from '../../api/data-contracts';
import { userSelector } from '../../store/selectors/user';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { buildFullName } from '../../utils/buildFullName';
import { useSearch } from '../../utils/hooks';
import { buildName } from '../../utils/buildName';
import { MdDeleteOutline } from 'react-icons/md';

interface ProjectCreateFormAuditorsSearchProps {
  onChange: (auditors: string[]) => void;
  initialUserIds?: string[];
  title?: string;
}

export function ProjectCreateFormAuditorsSearch({
  onChange,
  initialUserIds,
  title,
}: ProjectCreateFormAuditorsSearchProps) {
  const { usersList } = useSelector(userSelector);

  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [chosenAuditors, setChosenAuditors] = useState<AuditEntitiesAccountAccount[]>(
    initialUserIds ? usersList?.filter((_) => initialUserIds.includes(_.id || '')) || [] : [],
  );

  const toggleFocus = () => setInputFocus(!inputFocus);

  const searchFilterFunction = (searchString: string, user: AuditEntitiesAccountAccount) => {
    const searchStringLowercase = searchString.toLowerCase();
    const nameLowercase = buildFullName(user)?.toLowerCase() || '';

    return nameLowercase.includes(searchStringLowercase);
  };

  const {
    filteredValue: filteredUsers,
    searchInput,
    setSearchInput,
  } = useSearch(
    usersList?.filter((auditor) => !chosenAuditors.find((item) => item.id === auditor.id)) || [],
    searchFilterFunction,
  );

  const handleChoose = (auditor: AuditEntitiesAccountAccount) => {
    const newAuditors = [...chosenAuditors, auditor];
    setChosenAuditors(newAuditors);
    onChange(newAuditors.map((_) => _.id || ''));
  };

  const handleDelete = (auditor: AuditEntitiesAccountAccount) => {
    const newAuditors = [...chosenAuditors];
    const deletedAuditorIndex = newAuditors.findIndex((_) => _.id === auditor.id);

    if (deletedAuditorIndex > -1) {
      newAuditors.splice(deletedAuditorIndex, 1);
      setChosenAuditors(newAuditors);
      onChange(newAuditors.map((_) => _.id || ''));
    }
  };

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchInput(e.target.value);
    onChange([]);
  };

  return (
    <>
      <Input
        title={title || 'Поиск аудиторов'}
        className="w-full"
        value={searchInput}
        onChange={handleSearch}
        onFocus={toggleFocus}
        onBlur={toggleFocus}
      />
      {inputFocus ? (
        <div className="w-full z-20 flex absolute flex-col items-start rounded-xl bg-semiDarkGray border-darkGray border-opacity-40 shadow-xl">
          {filteredUsers?.length ? (
            filteredUsers.map((user) => {
              return (
                <Button
                  key={user.id}
                  variant="no-bg"
                  scale="large"
                  className="text-main-text pl-6 rounded-xl text-left w-full"
                  onMouseDown={() => handleChoose(user)}
                >
                  {buildName(user)}
                </Button>
              );
            })
          ) : (
            <div className="px-6 py-2">Не найдено аудиторов</div>
          )}
        </div>
      ) : null}
      {chosenAuditors?.length ? (
        <div className="-mt-4 mb-4 italic">
          Выбранные аудиторы:
          {chosenAuditors.map((auditor) => (
            <div key={auditor.id} className="flex items-center">
              <div>{buildName(auditor)}</div>
              <Button
                variant="no-bg"
                className="text-main-text ml-1"
                onClick={() => handleDelete(auditor)}
              >
                <MdDeleteOutline size={24} />
              </Button>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
}
