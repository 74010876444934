import React from 'react';
import { Logo } from '../Logo';
import { Button } from '../Button';
import { ProfileModal } from './ProfileModal';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/selectors/user';
import { buildName } from '../../utils/buildName';
import { useGetRole } from '../../utils/hooks';

export function Header() {
  const { userData = {} } = useSelector(userSelector);
  const { roleName } = useGetRole();

  return (
    <header className="z-10 sticky top-0 flex justify-between gap-12 bg-main py-5 px-24 select-none">
      <div className="flex justify-center items-center gap-4">
        <Logo version="minimized" size={44} />
        <div className="text-white text-xl">Этический аудит</div>
      </div>
      <div className="flex items-center gap-2.5 text-white group">
        <span className="text-secondary-text">{roleName}: </span>
        <Button className="text-white hover:bg-main pl-0">{buildName(userData)}</Button>
        <ProfileModal />
      </div>
    </header>
  );
}
