import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { store } from '../../store';
import { changeIndicatorForm, dropIndicatorForm } from '../../store/slices/indicators';
import { Button } from '../../components/Button';
import { createIndicator, updateIndicatorById } from '../../store/requests/indicators';
import { authSelector } from '../../store/selectors/auth';
import { AuditEntitiesSharedIndicator } from '../../api/data-contracts';
import { indicatorsSelector } from '../../store/selectors/indicators';

interface IndicatorsEditFormProps {
  indicator?: AuditEntitiesSharedIndicator;
  onClose: () => void;
}

export function IndicatorsEditForm({ indicator, onClose }: IndicatorsEditFormProps) {
  const { indicatorForm } = useSelector(indicatorsSelector);
  const { token } = useSelector(authSelector);

  useEffect(() => {
    if (indicator) {
      store.dispatch(changeIndicatorForm({ ...indicator }));
    }
    return () => {
      store.dispatch(dropIndicatorForm());
    };
  }, []);

  const handleChangeForm: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) =>
    store.dispatch(changeIndicatorForm({ ...indicatorForm, [e.target.name]: e.target.value }));

  const handleSubmit = () => {
    if (!indicatorForm) return;

    if (indicator) {
      store.dispatch(updateIndicatorById({ data: indicatorForm, token }));
    } else {
      store.dispatch(createIndicator({ data: indicatorForm, token }));
      store.dispatch(dropIndicatorForm());
    }

    onClose && onClose();
  };

  return (
    <div>
      <Heading className="mb-4" size="S">
        {indicator ? 'Редактирование' : 'Создание'} показателя
      </Heading>
      <Input
        title="Название показателя"
        onChange={handleChangeForm}
        name="name"
        value={indicatorForm?.name || ''}
        className="w-full"
      />
      <Input
        title="Вес"
        onChange={handleChangeForm}
        name="weight"
        value={indicatorForm?.weight || ''}
        className="w-full"
      />
      <div className="flex justify-end gap-2 mt-4">
        <Button onClick={onClose}>Отменить</Button>
        <Button onClick={handleSubmit} disabled={!indicatorForm?.name}>
          {indicator ? 'Обновить' : 'Создать'} показатель
        </Button>
      </div>
    </div>
  );
}
