import React, { useState } from 'react';
import { Heading } from '../../components/Heading';
import { Button } from '../../components/Button';
import { Modal } from '../../components/Modal';
import { AuditEntitiesSharedDocument } from '../../api/data-contracts';
import { Textarea } from '../../components/Textarea';
import { Select } from '../../components/Select';
import { DocumentWithComment } from './types';
import { MdEdit } from 'react-icons/md';

interface EditComponentSourceModalProps {
  modalOpened: boolean;
  onSave: (documentWithComment: DocumentWithComment) => void;
  onClose: () => void;
  documents: AuditEntitiesSharedDocument[];
}

export function EditComponentSourceDocumentModal({
  onSave,
  onClose,
  modalOpened,
  documents,
}: EditComponentSourceModalProps) {
  const [selectedDocument, setSelectedDocument] = useState<AuditEntitiesSharedDocument | null>(
    null,
  );
  const [comment, setComment] = useState('');

  const handleSave = () => {
    if (selectedDocument) {
      onSave({ ...selectedDocument, sourceComment: comment });
    }
    setSelectedDocument(null);
    setComment('');
    onClose();
  };

  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        <div>
          <Heading className="mb-4" size="S">
            Создание источника информации
          </Heading>
          {!selectedDocument && (
            <Select
              title="Документы"
              items={documents}
              itemToString={(document) => document.type ?? ''}
              onChoose={setSelectedDocument}
              filterFunction={(searchString, item) =>
                (item.type ?? '').includes(searchString) ?? false
              }
            />
          )}
          {selectedDocument && (
            <div className="text-primary-text mb-4 italic flex items-center">
              Выбранный документ - {selectedDocument.type}
              <Button
                variant="no-bg"
                className="ml-4 text-main-text"
                onClick={() => setSelectedDocument(null)}
              >
                <MdEdit size={24} />
              </Button>
            </div>
          )}
          <Textarea
            title="Комментарий"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            className="w-full h-40"
          />
          <div className="flex justify-end gap-2">
            <Button onClick={onClose}>Отменить</Button>
            <Button disabled={!selectedDocument} onClick={handleSave}>
              Создать
            </Button>
          </div>
        </div>
      }
    />
  );
}
