/* eslint-disable complexity */
/* eslint-disable max-lines */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from '../../components/Table';
import { userSelector } from '../../store/selectors/user';
import { companiesSelector } from '../../store/selectors/companies';
import { buildName } from '../../utils/buildName';
import { MdAdd, MdEdit } from 'react-icons/md';
import { Button } from '../../components/Button';
import { Modal } from '../../components/Modal';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { changeCompanyForm, dropCompanyForm } from '../../store/slices/companies';
import { store } from '../../store';
import { createCompany, updateCompany } from '../../store/requests/companies';
import { authSelector } from '../../store/selectors/auth';
import { AuditEntitiesAccountAccount, AuditEntitiesCompany } from '../../api/data-contracts';
import { IndustrySearch } from '../Methodology/IndustrySearch';
import { ProjectCreateFormAuditorsSearch } from '../Project/ProjectCreateFormAuditorsSearch';
import { updateAccount } from '../../store/requests/user';

type ModalState = undefined | 'update' | 'create';

export function CompaniesTable() {
  const { companyForm } = useSelector(companiesSelector);
  const [modalState, setModalState] = useState<ModalState>();
  const { token } = useSelector(authSelector);

  const { companies } = useSelector(companiesSelector);
  const { usersList } = useSelector(userSelector);

  const initialUsers = usersList?.filter((_) => _.companyIds?.includes(companyForm?.id || ''));

  const [workers, setWorkers] = useState<AuditEntitiesAccountAccount[]>(initialUsers || []);

  const closeModal = () => {
    setModalState(undefined);
    store.dispatch(dropCompanyForm());
  };

  const handleChangeCompany = (e: React.ChangeEvent<HTMLInputElement>, num: boolean) => {
    const { name, value } = e.target;

    const matcher = value.match(/\d*/);

    if (num && (!matcher || matcher[0] !== value)) return;
    store.dispatch(changeCompanyForm({ ...companyForm, [name]: value }));
  };

  const handleOpenEdit = (company: AuditEntitiesCompany) => {
    store.dispatch(changeCompanyForm(company));
    setModalState('update');
  };

  const handleCreateCompany = () => {
    store.dispatch(createCompany({ token, ...companyForm })).then(closeModal);
    if (workers.length > 0 && workers.length < 2) {
      return;
    }

    initialUsers?.forEach(handleRemoveWorkerFromCompany);
    workers?.forEach(handleAddWorkerToCompany);
    setWorkers([]);
  };

  const handleUpdateCompany = () => {
    store.dispatch(updateCompany({ token, ...companyForm })).then(closeModal);
    if (workers.length > 0 && workers.length < 2) {
      return;
    }

    initialUsers?.forEach(handleRemoveWorkerFromCompany);
    workers?.forEach(handleAddWorkerToCompany);
    setWorkers([]);
  };

  const handleAddWorkerToCompany = (account: AuditEntitiesAccountAccount) => {
    const companies = [...(account.companyIds || []), companyForm?.id || ''];
    store.dispatch(
      updateAccount({
        token,
        id: account.id || '',
        data: { ...account, companyIds: companies.filter((_, i) => companies.indexOf(_) === i) },
        isNotSelf: true,
      }),
    );
  };

  const handleRemoveWorkerFromCompany = (account: AuditEntitiesAccountAccount) => {
    if (
      initialUsers?.map((_) => _.id).includes(account?.id || '') &&
      !workers?.map((_) => _.id).includes(account?.id || '') &&
      workers.length > 0
    ) {
      const index = account.companyIds?.indexOf(companyForm?.id || '');
      if (index !== 0 && !index) {
        return;
      }
      const companies = account.companyIds || [];
      const newCompanies =
        companies.length > 1 ? [...(account.companyIds || [])].splice(index, 1) : [];
      if (index > -1) {
        store.dispatch(
          updateAccount({
            token,
            id: account.id || '',
            data: { ...account, companyIds: newCompanies },
            isNotSelf: true,
          }),
        );
      }
    }
  };

  const changeWorkers = (ids: string[]) => {
    setWorkers(usersList?.filter((_) => ids?.includes(_.id || '')) || []);
  };

  return (
    <div>
      <Table
        cols={[
          '',
          'Название компании',
          'Отрасль',
          'Численность, чел.',
          'ИНН',
          'Представители компании',
        ]}
        rows={companies?.map((_) => {
          return [
            <Button
              key="edit"
              variant="no-bg"
              className="ml-4 text-main"
              onClick={() => handleOpenEdit(_)}
            >
              <MdEdit size={24} />
            </Button>,
            _.name,
            _.industry,
            _.size,
            _.inn,
            usersList
              ?.filter((user) => user.companyIds?.includes(_.id || ''))
              .map(buildName)
              .join(', '),
          ];
        })}
      />
      <Button className="mt-6" onClick={() => setModalState('create')}>
        <div className="flex">
          <MdAdd size={24} className="mr-1" />
          <span>Создать компанию</span>
        </div>
      </Button>
      <Modal
        onClose={closeModal}
        visible={!!modalState}
        body={
          <div className="flex flex-col ">
            <Heading size="S">
              {modalState === 'update' ? 'Редактирование' : 'Создание'} компании
            </Heading>
            <Input
              title="Название компании"
              className="w-full"
              value={companyForm?.name || ''}
              name="name"
              onChange={(e) => handleChangeCompany(e, false)}
            />
            <Input
              title="ИНН"
              className="w-full"
              value={companyForm?.inn || ''}
              name="inn"
              onChange={(e) => handleChangeCompany(e, true)}
              errorCondition={String(companyForm?.inn).length > 10}
              errorMessage="Неверно указан ИНН"
            />
            <IndustrySearch
              onChoose={(str) =>
                store.dispatch(changeCompanyForm({ ...companyForm, industry: str }))
              }
            />
            <Input
              title="Размер компании"
              className="w-full"
              value={companyForm?.size || ''}
              name="size"
              onChange={(e) => handleChangeCompany(e, false)}
            />
            <ProjectCreateFormAuditorsSearch
              onChange={changeWorkers}
              title="Представители компании"
              initialUserIds={initialUsers?.map((_) => _.id || '')}
            />
            <div className="flex gap-x-2 justify-end">
              <Button version="secondary" onClick={closeModal}>
                Отменить
              </Button>
              <Button
                version="secondary"
                onClick={modalState === 'update' ? handleUpdateCompany : handleCreateCompany}
                disabled={!companyForm?.name || String(companyForm?.inn).length > 10}
              >
                Подтвердить
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
}
