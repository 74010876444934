import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../components/Button';
import { authSelector } from '../../store/selectors/auth';
import { store } from '../../store';
import { uploadDocument } from '../../store/requests/projects';
import { AuditEntitiesSharedDocument } from '../../api/data-contracts';
import { MdAdd, MdEdit } from 'react-icons/md';

interface ProjectUploadDocumentProps {
  document: AuditEntitiesSharedDocument;
}

export function ProjectUploadDocument({ document }: ProjectUploadDocumentProps) {
  const { token } = useSelector(authSelector);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUpload = (documentId?: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files.length || !documentId) {
      return;
    }
    store.dispatch(uploadDocument({ documentId, token, file: e.target.files[0] })).then(() => {
      if (fileInputRef.current) fileInputRef.current.value = '';
    });
  };

  return (
    <div>
      <Button onClick={() => fileInputRef.current?.click()}>
        <div className="flex">
          {document.fileName ? <MdEdit size={24} /> : <MdAdd size={24} />}
          <span className="ml-2">{document.fileName ? 'Изменить' : 'Загрузить'} документ</span>
        </div>
      </Button>
      <input
        className="hidden"
        type="file"
        accept=".pdf, .docx, .doc, .xls, .xlsx, .pptx, .ppt, .jpg, .tiff, .png"
        onChange={handleUpload(document.id || '')}
        ref={fileInputRef}
      />
    </div>
  );
}
