import React from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { FullscreenLoader } from '../../components/FullscreenLoader';
import { Heading } from '../../components/Heading';
import { store } from '../../store';
import { deleteDocumentById } from '../../store/requests/documents';
import { authSelector } from '../../store/selectors/auth';
import { documentsSelector } from '../../store/selectors/documents';
import { useModal } from '../../utils/hooks/useModal';
import { EditDocumentModal } from './EditDocumentModal';
import { DocumentTable } from './DocumentTable';
import { ProjectUploadDocument } from '../Project/ProjectUploadDocument';

export function Document() {
  const { documentId = '', projectId } = useParams();

  const { token } = useSelector(authSelector);
  const { documents } = useSelector(documentsSelector);

  const currentDocument = documents?.find((_) => _.id === documentId);

  const navigate = useNavigate();

  const {
    modalOpened: editModalOpened,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();

  const handleDeleteDocument = async () => {
    await store.dispatch(deleteDocumentById({ id: documentId, token }));
    navigate(-1);
  };

  return currentDocument ? (
    <>
      <Heading>Документ &#34;{currentDocument.type}&#34;</Heading>
      {!projectId ? (
        <>
          <Button className="mb-8 mr-4" onClick={openEditModal}>
            <div className="flex">
              <MdEdit size={24} className="mr-1" />
              <span>Редактировать документ</span>
            </div>
          </Button>
          <Button className="mb-8" onClick={handleDeleteDocument}>
            <div className="flex">
              <MdDelete size={24} className="mr-1" />
              <span>Удалить документ</span>
            </div>
          </Button>
        </>
      ) : (
        <div className="mb-8">
          <ProjectUploadDocument document={currentDocument} />
        </div>
      )}
      <DocumentTable document={currentDocument} />
      <EditDocumentModal
        document={currentDocument}
        modalOpened={editModalOpened}
        onClose={closeEditModal}
      />
    </>
  ) : (
    <FullscreenLoader />
  );
}
