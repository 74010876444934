import { ApiUrl } from './url';
import { logout } from '../../utils/logout';
import { VerifyToken } from '../../api/VerifyToken';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuditModelsTokenRequest } from '../../api/data-contracts';

const captchaApi = new VerifyToken({
  baseUrl: ApiUrl,
  customFetch: (...fetchParams: Parameters<typeof fetch>) => {
    return fetch(...fetchParams).then((res) => {
      const status = res?.status;
      if (status === 401) logout();

      return res;
    });
  },
});

export const verifyTokenApi = createAsyncThunk(
  'captcha/verify-token',
  async (data: AuditModelsTokenRequest) => {
    const res = await captchaApi.verifyTokenCreate(data);
    return res.data;
  },
);
