import React from 'react';
import { useSelector } from 'react-redux';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { store } from '../../store';
import { changeSectionForm } from '../../store/slices/sections';
import { Textarea } from '../../components/Textarea';
import { Button } from '../../components/Button';
import { createSection } from '../../store/requests/sections';
import { authSelector } from '../../store/selectors/auth';
import { sectionsSelector } from '../../store/selectors/sections';

interface MethodologyCreateSectionFormProps {
  onClose?: () => void;
}

export function MethodologyCreateSectionForm({ onClose }: MethodologyCreateSectionFormProps) {
  const { sectionForm, sections } = useSelector(sectionsSelector);
  const { token } = useSelector(authSelector);

  const handleChangeForm: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) =>
    store.dispatch(changeSectionForm({ ...sectionForm, [e.target.name]: e.target.value }));

  const handleCreate = () => {
    if (!sectionForm) return;
    store.dispatch(createSection({ data: sectionForm, token }));
    onClose && onClose();
  };

  const errorCondition = !!sections?.find((_) => _.name === sectionForm?.name);

  return (
    <div>
      <Heading className="mb-4" size="S">
        Создание раздела
      </Heading>
      <Input
        title="Название раздела"
        onChange={handleChangeForm}
        name="name"
        value={sectionForm?.name || ''}
        errorCondition={errorCondition}
        errorMessage="Раздел с таким названием уже существует"
        className="w-full"
      />
      <Textarea
        title="Описание раздела"
        onChange={handleChangeForm}
        name="description"
        value={sectionForm?.description || ''}
        className="w-full h-40 mb-4"
      />
      <div className="flex justify-end gap-2">
        <Button onClick={onClose}>Назад</Button>
        <Button onClick={handleCreate} disabled={!sectionForm?.name || errorCondition}>
          Создать
        </Button>
      </div>
    </div>
  );
}
