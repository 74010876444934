import React from 'react';
import { Button } from '../Button';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../utils/logout';
import { ButtonProps } from '../Button/Button';

export function ProfileModal() {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="hidden bg-semiDarkGray px-3 py-5 absolute top-16 right-[110px] rounded-xl group-hover:block  border-darkGray border-opacity-40 shadow-xl">
      {renderMenuButton(() => navigate('/profile'), 'Профиль пользователя')}
      {renderMenuButton(handleLogout, 'Выйти')}
    </div>
  );
}

const renderMenuButton = (onClick: ButtonProps['onClick'], text: string) => {
  return (
    <div>
      <Button
        variant="no-bg"
        scale="large"
        className="text-main-text pl-6 rounded-xl"
        onClick={onClick}
      >
        {text}
      </Button>
    </div>
  );
};
