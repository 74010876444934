import { createSlice } from '@reduxjs/toolkit';
import { verifyTokenApi } from '../requests/captcha';
import { AuditModelsTokenResponse } from '../../api/data-contracts';

interface VerifyTokenSliceProps {
  loading: boolean;
  error?: null | string | unknown;
  data?: null | AuditModelsTokenResponse;
}

const initialState: VerifyTokenSliceProps = {
  loading: false,
  error: null,
  data: null,
};

const verifyTokenSlice = createSlice({
  name: 'verifyToken',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyTokenApi.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.data = null;
      })
      .addCase(verifyTokenApi.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(verifyTokenApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const captchaReducer = verifyTokenSlice.reducer;
