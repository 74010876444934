import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { AuditEntitiesSharedSection, AuditEntitiesSharedIndicator } from '../../api/data-contracts';
import { Table } from '../../components/Table';

type IndicatorTableProps = {
  indicator?: AuditEntitiesSharedIndicator;
  sections: AuditEntitiesSharedSection[];
};

export const IndicatorTable: FC<IndicatorTableProps> = ({ indicator, sections }) => {
  return indicator ? (
    <Table
      className="mb-8"
      cols={['Название', 'Вес', 'Используется в разделах']}
      rows={[
        [
          indicator.name,
          indicator.weight,
          sections.length ? (
            sections.map((section, index) => (
              <React.Fragment key={section.id}>
                <Link
                  to={`../sections/${section.id}`}
                  className="text-decoration-line: underline text-link"
                >
                  {section.name}
                </Link>
                {index !== sections.length - 1 && <span>, </span>}
              </React.Fragment>
            ))
          ) : (
            <span className="italic text-secondary-text">Не используется в разделах</span>
          ),
        ],
      ]}
    />
  ) : null;
};
