import { AuditEntitiesAccountAccount } from '../api/data-contracts';

interface BuildNameProps {
  firstName?: AuditEntitiesAccountAccount['firstName'];
  lastName?: AuditEntitiesAccountAccount['lastName'];
  patronymic?: AuditEntitiesAccountAccount['patronymic'];
}

export function buildName({ firstName, lastName, patronymic }: BuildNameProps) {
  let nameString = lastName;
  if (firstName) nameString += ` ${firstName[0]}.`;
  if (patronymic) nameString += ` ${patronymic[0]}.`;

  return nameString;
}
