/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AuditContractsProjectsCreateProjectRequest,
  AuditContractsProjectsGetProjectsResponse,
  AuditEntitiesProject,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Projects<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Projects
   * @name GetProjectsList
   * @summary Получение проектов с пагинацией
   * @request GET:/projects/get-projects
   * @secure
   */
  getProjectsList = (
    query?: {
      /**
       * Какую страницу отобразить
       * @format int32
       * @default 0
       */
      page?: number;
      /**
       * Количество результатов на одной странице
       * @format int32
       * @default 0
       */
      perPage?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<AuditContractsProjectsGetProjectsResponse, any>({
      path: `/projects/get-projects`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Projects
   * @name GetUserProjectsDetail
   * @summary Получение всех проектов пользователя
   * @request GET:/projects/get-user-projects/{id}
   * @secure
   */
  getUserProjectsDetail = (id: string, params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/projects/get-user-projects/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Projects
   * @name GetProjectDetail
   * @summary Получение проекта по его ID
   * @request GET:/projects/get-project/{id}
   * @secure
   */
  getProjectDetail = (id: string, params: RequestParams = {}) =>
    this.request<AuditEntitiesProject, any>({
      path: `/projects/get-project/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Projects
   * @name CreateProjectCreate
   * @summary Создание проекта
   * @request POST:/projects/create-project
   * @secure
   */
  createProjectCreate = (
    data: AuditContractsProjectsCreateProjectRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/projects/create-project`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Projects
   * @name UpdateProjectCreate
   * @summary Обновление проекта
   * @request POST:/projects/update-project/{id}
   * @secure
   */
  updateProjectCreate = (id: string, data: AuditEntitiesProject, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/projects/update-project/${id}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Projects
   * @name UploadDocumentCreate
   * @summary Загрузка документов
   * @request POST:/projects/upload-document/{id}
   * @secure
   */
  uploadDocumentCreate = (
    id: string,
    data: {
      /** @format binary */
      file?: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/projects/upload-document/${id}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Projects
   * @name DownloadDocumentDetail
   * @summary Скачивание документов
   * @request GET:/projects/download-document/{id}
   * @secure
   */
  downloadDocumentDetail = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/projects/download-document/${id}`,
      method: 'GET',
      secure: true,
      ...params,
    });
}
