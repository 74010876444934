import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { store } from '../../store';
import { Button } from '../../components/Button';
import { changeDocumentForm, dropDocumentForm } from '../../store/slices/documents';
import { createDocument, updateDocumentById } from '../../store/requests/documents';
import { authSelector } from '../../store/selectors/auth';
import { Modal } from '../../components/Modal';
import { AuditEntitiesSharedDocument, AuditEntitiesMethodology } from '../../api/data-contracts';
import { documentsSelector } from '../../store/selectors/documents';

interface EditDocumentModalProps {
  parentId?: string;
  document?: AuditEntitiesSharedDocument;
  modalOpened: boolean;
  onClose: () => void;
  methodology?: AuditEntitiesMethodology;
}

export function EditDocumentModal({
  document,
  modalOpened,
  onClose,
  methodology,
  parentId,
}: EditDocumentModalProps) {
  const { documentForm } = useSelector(documentsSelector);
  const { token } = useSelector(authSelector);

  useEffect(() => {
    if (document) {
      store.dispatch(changeDocumentForm({ ...document }));
    }
    return () => {
      store.dispatch(dropDocumentForm());
    };
  }, [document]);

  const handleChangeForm: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) =>
    store.dispatch(changeDocumentForm({ ...documentForm, [e.target.name]: e.target.value }));

  const handleSubmit = () => {
    if (!documentForm) return;
    if (document) {
      store.dispatch(updateDocumentById({ data: documentForm, token }));
    } else {
      store.dispatch(createDocument({ data: { ...documentForm, parentId }, token, methodology }));
      store.dispatch(dropDocumentForm());
    }
    onClose();
  };

  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        <div>
          <Heading className="mb-4" size="S">
            {document ? 'Редактирование' : 'Создание'} документа
          </Heading>
          <Input
            title="Тип документа"
            onChange={handleChangeForm}
            name="type"
            value={documentForm?.type || ''}
            className="w-full"
          />
          <div className="flex justify-end gap-2">
            <Button onClick={onClose}>Отменить</Button>
            <Button onClick={handleSubmit} disabled={!documentForm?.type}>
              {document ? 'Обновить' : 'Создать'}
            </Button>
          </div>
        </div>
      }
    />
  );
}
