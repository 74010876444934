import React, { useState } from 'react';
import { useSearch } from '../../utils/hooks';
import { Button } from '../Button';
import { Input } from '../Input';

interface HasId {
  id?: string | undefined | null;
}

interface SelectProps<T extends HasId> {
  title: string;
  items: T[];
  itemToString: (item: T) => string;
  onChoose: (item: T) => void;
  filterFunction: (searchString: string, item: T) => boolean;
  disabled?: boolean;
}

export function Select<T extends HasId>({
  title,
  items,
  itemToString,
  onChoose,
  filterFunction,
  disabled
}: SelectProps<T>) {
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  const { filteredValue, searchInput, setSearchInput } = useSearch(items, filterFunction);

  const toggleFocus = () => setInputFocus(!inputFocus);

  return (
    <>
      <Input
        title={title}
        className="w-full"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onFocus={toggleFocus}
        onBlur={toggleFocus}
        disabled={disabled}
      />
      {inputFocus ? (
        <div className="w-full z-20 flex flex-col items-start rounded-xl bg-semiDarkGray border-darkGray border-opacity-40 shadow-xl">
          {filteredValue?.length ? (
            filteredValue.map((item) => (
              <Button
                key={item.id}
                variant="no-bg"
                scale="large"
                className="text-main-text pl-6 rounded-xl text-left w-full"
                onMouseDown={() => onChoose(item)}
              >
                {itemToString(item)}
              </Button>
            ))
          ) : (
            <div className="px-6 py-2">Не найдено</div>
          )}
        </div>
      ) : null}
    </>
  );
}
