import { createSlice } from '@reduxjs/toolkit';
import {
  AuditContractsAccountsAuthenticateResponse,
  AuditContractsAccountsRegistrationRequest,
  AuditEntitiesAccountRole,
} from '../../api/data-contracts';
import { authenticate } from '../requests/auth';

const initialFormState: AuditContractsAccountsRegistrationRequest = {
  firstName: '',
  lastName: '',
  patronymic: '',
  email: '',
  password: '',
  role: AuditEntitiesAccountRole.CompanyMember,
};

export interface AuthSliceProps {
  token?: AuditContractsAccountsAuthenticateResponse['token'];
  userId?: AuditContractsAccountsAuthenticateResponse['userId'];
  createAccountForm: AuditContractsAccountsRegistrationRequest;
}

const initialState: AuthSliceProps = {
  createAccountForm: initialFormState,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    dropAuth: () => initialState,
    changeAccountForm: (state, { payload }) => {
      state.createAccountForm = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authenticate.fulfilled, (state, { payload }) => {
      state.token = payload.token;
      state.userId = payload.userId;
    });
  },
});

export const authReducer = authSlice.reducer;
export const { dropAuth, changeAccountForm } = authSlice.actions;
