import React from 'react';
import { Modal } from '../../components/Modal';
import { AuditEntitiesSharedIndicator } from '../../api/data-contracts';
import { IndicatorsEditForm } from './IndicatorsEditForm';

interface IndicatorsEditModalProps {
  indicator?: AuditEntitiesSharedIndicator;
  modalOpened: boolean;
  onClose: () => void;
}

export function IndicatorsEditModal({ indicator, modalOpened, onClose }: IndicatorsEditModalProps) {
  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={<IndicatorsEditForm onClose={onClose} indicator={indicator} />}
    />
  );
}
