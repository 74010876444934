import React, { FC, useState } from 'react';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  AuditEntitiesSharedInterview,
  AuditEntitiesSharedQuestionModel,
} from '../../api/data-contracts';
import { Button } from '../../components/Button';
import { SwapButtons } from '../../components/SwapButtons';
import { Table } from '../../components/Table';
import { store } from '../../store';
import { updateInterviewById } from '../../store/requests/interviews';
import { authSelector } from '../../store/selectors/auth';
import { useModal } from '../../utils/hooks/useModal';
import { EditQuestionModal } from './EditQuestionModal';

type InterviewQuestionsTableProps = {
  interview: AuditEntitiesSharedInterview;
};

export const InterviewQuestionsTable: FC<InterviewQuestionsTableProps> = ({ interview }) => {
  const { projectId } = useParams();
  const { token } = useSelector(authSelector);

  const [selectedQuestion, setSelectedQuestion] = useState<AuditEntitiesSharedQuestionModel>();

  const { closeModal, modalOpened, openModal } = useModal();

  const questionsArray = interview.questions ?? [];

  const onSwapIndicators = (from: number, to: number) => {
    handleSwapSections(from, to, interview, token);
  };

  const handleDeleteQuestion = (id: string) => {
    const filteredQuestions = questionsArray.filter((question) => question.id !== id);
    store.dispatch(
      updateInterviewById({
        data: {
          ...interview,
          questions: filteredQuestions,
        },
        token,
      }),
    );
  };

  const handleEditQuestion = (question: AuditEntitiesSharedQuestionModel) => {
    setSelectedQuestion(question);
    openModal();
  };

  const cols = ['Вопрос'];
  if (!projectId) {
    cols.unshift('');
    cols.push('');
  } else {
    cols.push('Ответ');
    cols.push('Добавить ответ');
  }

  return (
    <>
      {questionsArray.length ? (
        <Table
          className="mb-8"
          cols={cols}
          rows={questionsArray.map((question, index) => {
            const rows: React.ReactNode[] = [question.question];
            if (projectId) {
              rows.push(question.answer);
              rows.push(
                <Button key={'button' + index} onClick={() => handleEditQuestion(question)}>
                  {question.answer ? 'Изменить' : 'Добавить'} ответ на вопрос
                </Button>,
              );
            } else {
              rows.unshift(
                <SwapButtons
                  key="swap"
                  index={index}
                  totalLength={questionsArray.length}
                  onSwap={onSwapIndicators}
                />,
              );
              rows.push(
                <div className="flex" key="edit-delete">
                  <Button
                    key="edit"
                    variant="no-bg"
                    className="ml-4 text-main"
                    onClick={() => handleEditQuestion(question)}
                  >
                    <MdEdit size={24} />
                  </Button>
                  <Button
                    key="delete"
                    variant="no-bg"
                    className="text-main"
                    onClick={() => {
                      handleDeleteQuestion(question.id ?? '');
                    }}
                  >
                    <MdDeleteOutline size={24} />
                  </Button>
                </div>,
              );
            }
            return rows;
          })}
        />
      ) : (
        <div>Вопросы в интервью отсутствуют</div>
      )}
      <EditQuestionModal
        interview={interview}
        question={selectedQuestion}
        modalOpened={modalOpened}
        onClose={closeModal}
      />
    </>
  );
};

const handleSwapSections = (
  from: number,
  to: number,
  interview: AuditEntitiesSharedInterview,
  token?: string | null,
  // eslint-disable-next-line max-params
) => {
  if (!interview.questions) return;

  const questions = [...interview.questions];

  const temp = questions[from];
  questions[from] = questions[to];
  questions[to] = temp;

  store.dispatch(
    updateInterviewById({
      data: {
        ...interview,
        questions,
      },
      token,
    }),
  );
};
