import { useSelector } from 'react-redux';
import { AuditEntitiesAccountRole } from '../../api/data-contracts';
import { userSelector } from '../../store/selectors/user';

export const useGetRole = () => {
  const { userData = {} } = useSelector(userSelector);
  const { role } = userData;

  const isCompanyMember = role === AuditEntitiesAccountRole.CompanyMember;
  const isSupervisor = role === AuditEntitiesAccountRole.Supervisor;
  const isAuditor = role === AuditEntitiesAccountRole.Auditor;
  const isRoot = role === AuditEntitiesAccountRole.Root;

  return { role, roleName: getRoleName(role), isCompanyMember, isSupervisor, isAuditor, isRoot };
};

export const getRoleName = (role?: AuditEntitiesAccountRole) => {
  let roleName = '';
  switch (role) {
    case AuditEntitiesAccountRole.Auditor:
      roleName = 'Аудитор';
      break;
    case AuditEntitiesAccountRole.Supervisor:
      roleName = 'Руководитель';
      break;
    case AuditEntitiesAccountRole.Root:
      roleName = 'Администратор';
      break;
    case AuditEntitiesAccountRole.CompanyMember:
      roleName = 'Представитель компании';
      break;
  }

  return roleName;
};
