import React from 'react';
import { CommonElementsProps } from '../types';

export type ButtonProps = JSX.IntrinsicElements['button'] & CommonElementsProps;

export function Button({
  scale = 'medium',
  version = 'primary',
  variant = 'default',
  className = '',
  disabled,
  onClick,
  ...rest
}: ButtonProps) {
  return (
    <button
      {...rest}
      onClick={disabled ? undefined : onClick}
      className={
        getButtonStyles(scale, version, variant) +
        ` ${className} ` +
        `${disabled ? 'opacity-50 cursor-default' : ''}`
      }
    />
  );
}

function getButtonStyles(
  scale?: ButtonProps['scale'],
  version?: ButtonProps['version'],
  variant?: ButtonProps['variant'],
) {
  let styles = 'box-border text-white duration-300 rounded ';

  switch (scale) {
    case 'small':
      styles += 'px-4 py-1.5 text-sm ';
      break;
    case 'medium':
      styles += 'px-6 py-2.5 text-base ';
      break;
    case 'large':
      styles += 'px-8 py-3.5 text-lg font-semibold ';
      break;
  }

  if (variant === 'default') {
    switch (version) {
      case 'primary':
        styles += 'bg-main hover:bg-secondary active:bg-main';
        break;
      case 'secondary':
        styles += 'bg-secondary hover:bg-main active:bg-secondary';
    }
  } else {
    styles += 'hover:text-gray pl-0';
  }

  return styles;
}
