import React, { useState, useEffect } from 'react';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { useSearch } from '../../utils/hooks';
import industries from './industries.json';

interface IndustrySearchProps {
  onChoose: (industryName: string) => void;
  initialState?: string;
}

export function IndustrySearch({ onChoose, initialState }: IndustrySearchProps) {
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  useEffect(() => {
    setSearchInput(initialState || '');
  }, []);

  const toggleFocus = () => setInputFocus(!inputFocus);

  const searchFilterFunction = (searchString: string, industry: string) => {
    const searchStringLowercase = searchString.toLowerCase();
    const nameLowercase = industry.toLowerCase() || '';

    return nameLowercase.includes(searchStringLowercase);
  };

  const {
    filteredValue: filteredIndustries,
    searchInput,
    setSearchInput,
  } = useSearch(industries, searchFilterFunction);

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchInput(e.target.value);
    onChoose(e.target.value);
  };

  return (
    <div className="relative">
      <Input
        title="Отрасль компании (есть возможность ввести своё значение)"
        className="w-full"
        value={searchInput}
        onChange={handleSearch}
        onFocus={toggleFocus}
        onBlur={toggleFocus}
      />
      {inputFocus ? (
        <div className="w-full flex z-20 absolute flex-col items-start rounded-xl bg-semiDarkGray border-darkGray border-opacity-40 shadow-xl">
          {filteredIndustries?.length ? (
            filteredIndustries.slice(0, 10).map((industry) => {
              return (
                <Button
                  key={industry}
                  variant="no-bg"
                  scale="medium"
                  className="text-main-text pl-6 rounded-xl"
                  onMouseDown={() => {
                    onChoose(industry);
                    setSearchInput(industry);
                  }}
                >
                  {industry}
                </Button>
              );
            })
          ) : (
            <div className="px-6 py-2">Не найдено подходящей отрасли</div>
          )}
        </div>
      ) : null}
    </div>
  );
}
