import React, { ReactNode } from 'react';
import { TableCell } from './TableCell';
import { TableRow } from './TableRow';
import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { Heading } from '../Heading';

type TableProps = JSX.IntrinsicElements['table'] & {
  title?: string;
  cols?: string[];
  rows?: ReactNode[][];
  indexColAlign?: 'left' | 'center' | 'right';
};

export function Table({
  title,
  cols,
  rows,
  className = '',
  indexColAlign = 'center',
  ...rest
}: TableProps) {
  return (
    <div className={className}>
      {title && <Heading size="S">{title}</Heading>}
      <table
        className="border-t border-darkGray border-opacity-20 w-full whitespace-pre-wrap"
        {...rest}
      >
        <TableHeader>
          <TableRow>
            {cols?.map((_, i) => (
              <TableCell
                key={i}
                className={
                  `${_ === '' && 'w-[48px]'} ` +
                  getCellClassNameByIndex(i, cols.length) +
                  (i === 0 ? ` text-${indexColAlign}` : '')
                }
              >
                {_}
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows?.map((_, i) => (
            <TableRow key={i}>
              {_.map((cell, i) => (
                <TableCell key={i} className={getCellClassNameByIndex(i, _.length)}>
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </table>
    </div>
  );
}

const getCellClassNameByIndex = (i: number, length: number) => {
  return i === length - 1 ? '' : 'border-r border-darkGray border-opacity-20';
};
