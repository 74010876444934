import React, { useState, useEffect } from 'react';
import { Logo } from '../../components/Logo';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { useNavigate } from 'react-router-dom';
import { sendResetPasswordLink } from '../../store/requests/auth';
import store from '../../store/store';
import { Heading } from '../../components/Heading';
import { MdChevronLeft } from 'react-icons/md';

export function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [linkSent, setLinkSent] = useState(false);

  const navigate = useNavigate();

  const handleSendLink = async () => {
    if (email) {
      await store.dispatch(sendResetPasswordLink({ email }));
      setLinkSent(true);
    }
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Enter') handleSendLink();
    };

    document.addEventListener('keypress', listener);

    return () => document.removeEventListener('keypress', listener);
  }, []);

  return (
    <div className="bg-main flex justify-center content-center items-center h-screen">
      <div className="bg-blue flex flex-col items-center px-[30px] py-[40px] border border-darkGray border-opacity-40 shadow-xl rounded-[16px] w-[380px]">
        <Logo version="transparent" size={60} className="mb-[20px]" />
        <Heading className="self-start mb-[10px]" size="S">
          Восстановление пароля
        </Heading>
        {linkSent ? (
          <>
            <div className="mb-[25px] text-secondary-gray">
              Если учетная запись &#34;{email}&#34; существует, будет отправлено электронное письмо
              с дальнейшими инструкциями.
            </div>
          </>
        ) : (
          <>
            <div className="mb-[25px] text-secondary-gray">
              Введите адрес электронной почты, который вы использовали при регистрации и мы вышлем
              вам инструкцию
            </div>
            <Input
              className="mb-2 w-full"
              placeholder="Почта"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              validationRegExp={
                /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
              }
              errorMessage="Неверный Email"
            />
            <Button className="w-full" onClick={handleSendLink}>
              Отправить ссылку
            </Button>
          </>
        )}
        <div className="mt-[50px] text-main cursor-pointer flex" onClick={() => navigate('/login')}>
          <MdChevronLeft size={24} />
          <div>Назад ко входу</div>
        </div>
      </div>
    </div>
  );
}
