import React, { useState } from 'react';
import { formatUnix } from '../../utils/formatUnix';
import { Table } from '../../components/Table';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal } from '../../components/Modal';
import { createMethodology } from '../../store/requests/methodologies';
import { store } from '../../store';
import { authSelector } from '../../store/selectors/auth';
import { MdAdd, MdCopyAll } from 'react-icons/md';
import { methodologiesSelector } from '../../store/selectors/methodologies';
import { Heading } from '../../components/Heading';
import { MethodologyCloneForm } from '../Methodology/MethodologyCloneForm';
import { IndustrySearch } from '../Methodology/IndustrySearch';

type ModalState = undefined | 'clone' | 'create';

export function MethodologiesTable() {
  const [modalState, setModalState] = useState<ModalState>();
  const [name, setName] = useState<string>();
  const [companyIndustry, setCompanyIndustry] = useState<string>();
  const [companySize, setCompanySize] = useState<string>();
  const [isSelfRating, setIsSelfRating] = useState<boolean>(false);

  const { methodologies } = useSelector(methodologiesSelector);
  const { token, userId } = useSelector(authSelector);

  const openClone = () => setModalState('clone');
  const openCreate = () => setModalState('create');
  const closeModal = () => setModalState(undefined);

  const handleChangeName: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
  };
  const handleChangeQty: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCompanySize(e.target.value);
  };

  const handleCreateMethodology = () => {
    if (userId && name) {
      store.dispatch(createMethodology({ name, token, userId, isSelfRating }));
      closeModal();
    }
  };

  return (
    <div>
      <Table
        cols={['Название', 'Дата создания', 'Дата изменения', 'Комментарий']}
        rows={methodologies?.map((item) => {
          const createdOn = formatUnix(item.createdOn);
          const changedOn = formatUnix(item.changedOn);

          return [
            <Link
              key={item.id}
              to={`methodology/${item.id}`}
              className="text-decoration-line: underline text-link"
            >
              {item.name}
            </Link>,
            createdOn,
            changedOn,
            item.comment,
          ];
        })}
      />
      <Button className="mt-6" onClick={openCreate}>
        <div className="flex">
          <MdAdd size={24} className="mr-1" />
          <span>Создать методику</span>
        </div>
      </Button>
      <Button className="ml-4" onClick={openClone}>
        <div className="flex">
          <MdCopyAll size={24} className="mr-1" />
          <span>Клонировать методику</span>
        </div>
      </Button>
      <Modal
        onClose={closeModal}
        visible={!!modalState}
        body={
          modalState === 'create' ? (
            <div className="flex flex-col">
              <Heading size="S">Создание методики</Heading>
              <Input
                title="Введите название методики"
                className="w-full"
                value={name}
                onChange={handleChangeName}
              />
              <IndustrySearch onChoose={setCompanyIndustry} />
              <Input
                title="Численность (чел.)"
                onChange={handleChangeQty}
                name="companySize"
                value={companySize}
                className="w-full"
              />
              <div className="flex align-start" onClick={() => setIsSelfRating(!isSelfRating)}>
                <Input type="checkbox" className="mr-1" checked={isSelfRating} />
                <span className="text-secondary-text">Методика самооценки</span>
              </div>
              <div className="flex gap-x-2 justify-end">
                <Button version="secondary" onClick={closeModal}>
                  Отменить
                </Button>
                <Button version="secondary" onClick={handleCreateMethodology} disabled={!name}>
                  Подтвердить
                </Button>
              </div>
            </div>
          ) : (
            <MethodologyCloneForm onClose={closeModal} />
          )
        }
      />
    </div>
  );
}
