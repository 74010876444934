/* eslint-disable complexity */
import React from 'react';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { store } from '../../store';
import { deleteIndicatorById } from '../../store/requests/indicators';
import { authSelector } from '../../store/selectors/auth';
import { indicatorsSelector } from '../../store/selectors/indicators';
import { sectionsSelector } from '../../store/selectors/sections';
import { useModal } from '../../utils/hooks/useModal';
import { EditComponentModal } from './EditComponentModal';
import { IndicatorComponentsTable } from './IndicatorComponentsTable';
import { IndicatorsEditModal } from './IndicatorsEditModal';
import { IndicatorTable } from './IndicatorTable';

export function Indicator() {
  const { indicatorId = '', methodologyId, projectId } = useParams();
  const { indicators } = useSelector(indicatorsSelector);
  const { sections } = useSelector(sectionsSelector);
  const { token } = useSelector(authSelector);

  const navigate = useNavigate();

  const currentIndicator = indicators?.find((_) => _.id === indicatorId);

  const usedInSections = sections?.filter((section) =>
    section.indicators?.some((id) => id === indicatorId),
  );

  const {
    modalOpened: editModalOpened,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();

  const {
    modalOpened: componentModalOpened,
    closeModal: closeComponentModal,
    openModal: openComponentModal,
  } = useModal();

  const handleDeleteIndicator = async () => {
    if (usedInSections?.length) return;

    await store.dispatch(deleteIndicatorById({ id: indicatorId, token }));
    navigate(-1);
  };

  const indicatorData = { avg: 0, sum: 0, compAvg: 0, compSum: 0 };
  const filledLength = currentIndicator?.components?.filter((_) => !!_.auditorRating).length || 0;
  const compFilledLength =
    currentIndicator?.components?.filter((_) => !!_.companyRating).length || 0;

  currentIndicator?.components?.forEach((component) => {
    const ratingNum = isNaN(Number(component.auditorRating)) ? 0 : Number(component.auditorRating);
    const compRatingNum = isNaN(Number(component.companyRating))
      ? 0
      : Number(component.companyRating);

    indicatorData.sum += ratingNum;
    indicatorData.compSum += compRatingNum;
    indicatorData.avg =
      Math.round((indicatorData.sum / (filledLength || 1) + Number.EPSILON) * 100) / 100;
    indicatorData.compAvg =
      Math.round((indicatorData.compSum / (compFilledLength || 1) + Number.EPSILON) * 100) / 100;
  });

  return (
    <>
      <Heading>Показатель &#34;{currentIndicator?.name}&#34;</Heading>
      {projectId ? null : (
        <>
          <Button className="mb-8 mr-4" onClick={openEditModal}>
            <div className="flex">
              <MdEdit size={24} className="mr-1" />
              <span>Редактировать показатель</span>
            </div>
          </Button>
          <Button className="mb-8" onClick={handleDeleteIndicator}>
            <div className="flex">
              <MdDelete size={24} className="mr-1" />
              <span>Удалить показатель</span>
            </div>
          </Button>
        </>
      )}
      <IndicatorTable indicator={currentIndicator} sections={usedInSections || []} />
      <div className="flex justify-between items-center">
        <div className="text-main-text font-semibold text-2xl mb-4">Компоненты показателя</div>
        {projectId ? (
          <div className="flex flex-col align-end">
            <Heading size="XS">
              Оценка - {indicatorData.avg}, Сумма баллов - {indicatorData.sum}
            </Heading>
            <Heading size="XS">
              Оценка компании - {indicatorData.compAvg}, Сумма баллов компании -
              {indicatorData.compSum}
            </Heading>
          </div>
        ) : null}
      </div>
      {projectId ? null : (
        <Button className="mb-8" onClick={openComponentModal}>
          <div className="flex">
            <MdAdd size={24} className="mr-1" />
            <span>Добавить компонент</span>
          </div>
        </Button>
      )}
      <IndicatorComponentsTable indicator={currentIndicator || {}} />
      <IndicatorsEditModal
        modalOpened={editModalOpened}
        indicator={currentIndicator}
        onClose={closeEditModal}
      />
      {currentIndicator && (
        <EditComponentModal
          methodologyId={methodologyId}
          modalOpened={componentModalOpened}
          onClose={closeComponentModal}
          indicator={currentIndicator}
        />
      )}
    </>
  );
}
