import { AuditEntitiesSharedSection } from './../../api/data-contracts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSections, getSectionById, getSectionsList } from '../requests/sections';

interface SectionsSliceProps {
  sections?: AuditEntitiesSharedSection[];
  sectionForm?: AuditEntitiesSharedSection;
  chosenSections?: string[];
  currentSection?: AuditEntitiesSharedSection;
}

const initialState: SectionsSliceProps = {
  sections: [],
};

export const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    dropSections: () => initialState,
    changeSectionForm(state, { payload }: PayloadAction<SectionsSliceProps['sectionForm']>) {
      state.sectionForm = payload;
    },
    setChosenSections(state, { payload }: PayloadAction<SectionsSliceProps['chosenSections']>) {
      state.chosenSections = payload;
    },
    dropCurrentSection(state) {
      state.currentSection = undefined;
    },
    dropSectionForm(state) {
      state.sectionForm = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSections.fulfilled, (state, { payload }) => {
      state.sections = payload;
    });
    builder.addCase(getSectionsList.fulfilled, (state, { payload }) => {
      const newArr = state.sections || [];
      payload.forEach((item) => {
        if (!newArr?.find((_) => _.id === item.id)) {
          newArr?.push(item as AuditEntitiesSharedSection);
        }
      });
      state.sections = newArr;
    });
    builder.addCase(getSectionById.fulfilled, (state, { payload }) => {
      state.currentSection = payload;
    });
  },
});

export const sectionsReducer = sectionsSlice.reducer;
export const {
  dropSections,
  changeSectionForm,
  setChosenSections,
  dropCurrentSection,
  dropSectionForm,
} = sectionsSlice.actions;
