import React, { useEffect } from 'react';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { CommonConfirmModal } from '../../components/CommonConfirmModal';
import { FullscreenLoader } from '../../components/FullscreenLoader';
import { Heading } from '../../components/Heading';
import { store } from '../../store';
import {
  deleteSectionById,
  getSectionById,
  updateSectionById,
} from '../../store/requests/sections';
import { authSelector } from '../../store/selectors/auth';
import { methodologiesSelector } from '../../store/selectors/methodologies';
import { sectionsSelector } from '../../store/selectors/sections';
import { dropCurrentSection } from '../../store/slices/sections';
import { useModal } from '../../utils/hooks/useModal';
import { AddIndicatorModal } from './AddIndicatorModal';
import { EditSectionModal } from './EditSectionModal';
import { SectionIndicatorsTable } from './SectionIndicatorsTable';
import { SectionTable } from './SectionTable';

export function Section() {
  const { sectionId = '', projectId } = useParams();

  const { token } = useSelector(authSelector);
  const { currentSection } = useSelector(sectionsSelector);
  const { methodologies } = useSelector(methodologiesSelector);

  const navigate = useNavigate();

  const usedInMethodologies = methodologies?.filter((methodology) =>
    methodology.sections?.some((sectionId) => sectionId === currentSection?.id),
  );

  const {
    modalOpened: editModalOpened,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();

  const {
    modalOpened: addIndicatorModalOpened,
    openModal: openAddIndicatorModal,
    closeModal: closeAddIndicatorModal,
  } = useModal();

  const {
    modalOpened: confirmModalOpened,
    openModal: openConfirmModal,
    closeModal: closeConfirmModal,
  } = useModal();

  const handleDeleteSection = async () => {
    if (usedInMethodologies?.length) {
      openConfirmModal();
      return;
    }
    await store.dispatch(deleteSectionById({ id: sectionId, token }));
    navigate(-1);
  };

  const handleDeleteIndicator = (id: string) => {
    store.dispatch(
      updateSectionById({
        data: {
          ...currentSection,
          indicators: currentSection?.indicators?.filter((indicatorId) => indicatorId !== id),
        },
        token,
      }),
    );
  };

  useEffect(() => {
    store.dispatch(getSectionById({ id: sectionId, token }));
    return () => {
      store.dispatch(dropCurrentSection());
    };
  }, []);

  return currentSection ? (
    <>
      <Heading>Раздел &#34;{currentSection.name}&#34;</Heading>
      {projectId ? null : (
        <>
          <Button className="mb-8 mr-4" onClick={openEditModal}>
            <div className="flex">
              <MdEdit size={24} className="mr-1" />
              <span>Редактировать раздел</span>
            </div>
          </Button>
          <Button className="mb-8" onClick={handleDeleteSection}>
            <div className="flex">
              <MdDelete size={24} className="mr-1" />
              <span>Удалить раздел</span>
            </div>
          </Button>
        </>
      )}
      <SectionTable section={currentSection} methodologies={usedInMethodologies} />
      <div className="text-main-text font-semibold text-2xl mb-4">Показатели в разделе</div>
      {projectId ? null : (
        <Button className="mb-8" onClick={openAddIndicatorModal}>
          <div className="flex">
            <MdAdd size={24} className="mr-1" />
            <span>Добавить показатель</span>
          </div>
        </Button>
      )}
      <SectionIndicatorsTable
        section={currentSection}
        onDeleteClick={handleDeleteIndicator}
        isEditable={!projectId}
      />
      <EditSectionModal
        section={currentSection}
        modalOpened={editModalOpened}
        onClose={closeEditModal}
      />
      <AddIndicatorModal
        section={currentSection}
        modalOpened={addIndicatorModalOpened}
        onClose={closeAddIndicatorModal}
      />
      <CommonConfirmModal
        title="Вы не можете удалить раздел"
        description={
          <div>
            Данный раздел используется в методиках{' '}
            {usedInMethodologies?.map((methodology, index) => (
              <>
                <Link
                  key={methodology.id}
                  className="text-decoration-line: underline text-link"
                  to={`../methodology/${methodology.id}`}
                >
                  {methodology.name}
                </Link>
                {index !== usedInMethodologies.length - 1 && ', '}
              </>
            ))}
            Для удаления раздела перейдите на страницу методике и удалите его из соответствующей
            таблицы.
          </div>
        }
        modalOpened={confirmModalOpened}
        onClose={closeConfirmModal}
      />
    </>
  ) : (
    <FullscreenLoader />
  );
}
