/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Запрос на аутентификаци. */
export interface AuditContractsAccountsAuthenticate {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * Пароль
   * @minLength 1
   */
  password: string;
}

/** Ответ по аутентификации */
export interface AuditContractsAccountsAuthenticateResponse {
  /** ID Пользователя */
  userId?: string | null;
  /** JWT Токен */
  token?: string | null;
}

/** Запрос на создание аккаунта */
export interface AuditContractsAccountsCreateRequest {
  /**
   * Имя
   * @minLength 1
   */
  firstName: string;
  /**
   * Фамилия
   * @minLength 1
   */
  lastName: string;
  /**
   * Роль
   * @minLength 1
   */
  role: string;
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * Пароль
   * @minLength 6
   */
  password: string;
  /**
   * Повторить пароль
   * @minLength 1
   */
  confirmPassword: string;
}

/** Запрос если забыл пароль */
export interface AuditContractsAccountsForgotPasswordRequest {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
}

/** Запрос на регистрацию пользователя */
export interface AuditContractsAccountsRegistrationRequest {
  /**
   * Имя
   * @minLength 1
   */
  firstName: string;
  /**
   * Фамилия
   * @minLength 1
   */
  lastName: string;
  /** Отчество */
  patronymic?: string | null;
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * Пароль
   * @minLength 1
   */
  password: string;
  /** Роли в системе */
  role: AuditEntitiesAccountRole;
}

/** Запрос на сброс пароля */
export interface AuditContractsAccountsResetPasswordRequest {
  /**
   * Токен
   * @minLength 1
   */
  token: string;
  /**
   * Пароль
   * @minLength 6
   */
  password: string;
  /**
   * Повторить пароль
   * @minLength 1
   */
  confirmPassword: string;
}

/** Запрос на отзыв токена */
export interface AuditContractsAccountsRevokeTokenRequest {
  /** Токен */
  token?: string | null;
}

/** Запрос на обновление пароля */
export interface AuditContractsAccountsUpdatePasswordRequest {
  /**
   * Старый пароль
   * @minLength 1
   */
  oldPassword: string;
  /**
   * Новый пароль
   * @minLength 1
   */
  newPassword: string;
}

/** Запрос на обновление информации о пользователе */
export interface AuditContractsAccountsUpdateRequest {
  /** Имя */
  firstName?: string | null;
  /** Фамилия */
  lastName?: string | null;
  /** Отчество */
  patronymic?: string | null;
  /** Номер телефона */
  phoneNumber?: string | null;
  /** Название организации */
  organization?: string | null;
  /** Email */
  email?: string | null;
  /** Список айди компаний пользователя */
  companyIds?: string[] | null;
}

/** Валидация токена сброса */
export interface AuditContractsAccountsValidateResetTokenRequest {
  /**
   * Токен
   * @minLength 1
   */
  token: string;
}

/** Запрос на создание методики */
export interface AuditContractsProjectsCreateMethodologyRequest {
  /**
   * Название методики
   * @minLength 1
   */
  name: string;
  /**
   * ID создателя
   * @minLength 1
   */
  userId: string;
  /** Отрасль компании */
  companyIndustry?: string | null;
  /** Размер компании */
  companySize?: string | null;
  /** Методика самооценки */
  isSelfRating: boolean;
}

/** Запрос на создание проекта */
export interface AuditContractsProjectsCreateProjectRequest {
  /**
   * Название проекта
   * @minLength 1
   */
  name: string;
  /**
   * ID пользователя который создал проект
   * @minLength 1
   */
  createdBy: string;
  /** Список ID аудиторов дополнительных аудиторов проекта */
  auditors: string[];
  /**
   * ID методики по которой создается проект
   * @minLength 1
   */
  methodologyId: string;
  /** Комментарий */
  comment?: string | null;
  /** Список айди компаний пользователя */
  companyIds?: string[] | null;
}

/** Запрос на получение списка документов */
export interface AuditContractsProjectsGetDocumentListRequest {
  /** ID документов */
  documentIds?: string[] | null;
}

/** Ответ на получение всех методик */
export interface AuditContractsProjectsGetProjectsResponse {
  /**
   * Всего страниц
   * @format double
   */
  totalPages?: number;
  /**
   * Всего результатов
   * @format int64
   */
  totalProjects?: number;
  /** Проекты по запросу */
  projects?: AuditEntitiesProject[] | null;
}

/** Запрос на обновление методики */
export interface AuditContractsProjectsUpdateMethodologyRequest {
  /** Создать новую версию */
  newVersion?: boolean;
  /** Название версии */
  versionName?: string | null;
  /** Модель методики */
  methodology?: AuditEntitiesMethodology;
}

/** Модель аккаунта пользователя */
export interface AuditEntitiesAccountAccount {
  /** ID */
  id?: string | null;
  /** Имя */
  firstName?: string | null;
  /** Отчество */
  patronymic?: string | null;
  /** Фамилия */
  lastName?: string | null;
  /** Email */
  email?: string | null;
  /** Организация */
  organization?: string | null;
  /** Номер телефона */
  phoneNumber?: string | null;
  /** Роли в системе */
  role?: AuditEntitiesAccountRole;
  /** Верицифирован ли */
  isVerified?: boolean;
  /**
   * Дата создания
   * @format int64
   */
  createdAt?: number;
  /**
   * Дата обновления
   * @format int64
   */
  updatedAt?: number | null;
  /** Айди компаний которые представляет пользолватель */
  companyIds?: string[] | null;
}

/** Роли в системе */
export enum AuditEntitiesAccountRole {
  Root = 'Root',
  Supervisor = 'Supervisor',
  Auditor = 'Auditor',
  CompanyMember = 'CompanyMember',
}

/** Модель компании */
export interface AuditEntitiesCompany {
  /** ID */
  id?: string | null;
  /** Название */
  name?: string | null;
  /** Отрасль */
  industry?: string | null;
  /** Размер компании */
  size?: string | null;
  /**
   * Инн
   * @format int64
   */
  inn?: number;
}

/** Модель методики */
export interface AuditEntitiesMethodology {
  /** ID */
  id?: string | null;
  /** Название */
  name?: string | null;
  /** ID создателя */
  createdBy?: string | null;
  /**
   * Дата создания
   * @format int64
   */
  createdOn?: number;
  /**
   * Последнее изменение
   * @format int64
   */
  changedOn?: number;
  /** Последнее изменение */
  changedBy?: string | null;
  /** Методика самооценки */
  isSelfRating?: boolean;
  /** Отрасль компании */
  companyIndustry?: string | null;
  /** Размер компании */
  companySize?: string | null;
  /** Комментарий */
  comment?: string | null;
  /** Разделы */
  sections?: string[] | null;
  /** Документы методики */
  documents?: string[] | null;
  /** Интервью методики */
  interviews?: string[] | null;
}

/** Проект */
export interface AuditEntitiesProject {
  /** ID */
  id?: string | null;
  /** Название */
  name?: string | null;
  /** ID создателя */
  createdBy?: string | null;
  /**
   * Дата создания
   * @format int64
   */
  createdOn?: number;
  /**
   * Последнее изменение
   * @format int64
   */
  changedOn?: number;
  /** Последнее изменение */
  changedBy?: string | null;
  /** Методика самооценки */
  isSelfRating?: boolean;
  /** Отрасль компании */
  companyIndustry?: string | null;
  /** Размер компании */
  companySize?: string | null;
  /** Комментарий */
  comment?: string | null;
  /** Разделы */
  sections?: string[] | null;
  /** Документы методики */
  documents?: string[] | null;
  /** Интервью методики */
  interviews?: string[] | null;
  /** ID методики на которой основан проект */
  methodologyId?: string | null;
  /**
   * Дата заверщения проекта
   * @format int64
   */
  finishedOn?: number;
  /** Аудиторы участвующие в проекте */
  auditors?: string[] | null;
  /**
   * Прогресс завершения проекта
   * @format int32
   */
  progress?: number;
  /** Список компаний */
  companyIds?: string[] | null;
}

/** Строка показателя */
export interface AuditEntitiesSharedComponent {
  /** Что оценивается */
  measuring?: string | null;
  /** Источники информации */
  source?: AuditEntitiesSharedSource[] | null;
  /** Примеры оценки */
  examples?: string | null;
  /** Собранные материалы и обоснование */
  justification?: string | null;
  /**
   * Оценка
   * @format int32
   */
  auditorRating?: number;
  /** Комментарий */
  auditorComment?: string | null;
  /**
   * Оценка
   * @format int32
   */
  companyRating?: number;
  /** Комментарий */
  companyComment?: string | null;
}

/** Документ */
export interface AuditEntitiesSharedDocument {
  /** ID документа */
  id?: string | null;
  /** ID родителя (проекта или методики) */
  parentId?: string | null;
  /** Тип документа */
  type?: string | null;
  /** Название документа */
  name?: string | null;
  /** Полное имя файла */
  fileName?: string | null;
  /** URL источника */
  url?: string | null;
  /**
   * Дата загрузки
   * @format int64
   */
  uploadedOn?: number;
  /** Комментарий */
  comment?: string | null;
  /** Поле для псевдо удаления документа */
  isDeleted?: boolean;
}

/** Показатель раздела методики */
export interface AuditEntitiesSharedIndicator {
  /** ID раздела */
  id?: string | null;
  /** Название */
  name?: string | null;
  /**
   * Вес
   * @format int32
   */
  weight?: number;
  /** Строки показателя */
  components?: AuditEntitiesSharedComponent[] | null;
  /** Поле для псевдо удаления документа */
  isDeleted?: boolean;
}

/** Интервью */
export interface AuditEntitiesSharedInterview {
  /** ID документа */
  id?: string | null;
  /** ID родителя (проекта или методики) */
  parentId?: string | null;
  /** Респондент */
  subject?: AuditEntitiesSharedInterviewSubject;
  /**
   * Дата
   * @format int64
   */
  date?: number;
  /** Кем взято интервью */
  takenBy?: string | null;
  /** Путь к аудиофайлу */
  audiofilePath?: string | null;
  /** Словарь, где ключ - вопрос, значение - ответ */
  questions?: AuditEntitiesSharedQuestionModel[] | null;
  /** Поле для псевдо удаления документа */
  isDeleted?: boolean;
}

/** Респондент */
export interface AuditEntitiesSharedInterviewSubject {
  /** ФИО */
  name?: string | null;
  /** Должность */
  position?: string | null;
  /** Дата начала работы в этой должности */
  inPositionFrom?: string | null;
}

/** Модель объекта вопроса */
export interface AuditEntitiesSharedQuestionModel {
  /** ID */
  id?: string | null;
  /** Вопрос */
  question?: string | null;
  /** Ответ в интервью */
  answer?: string | null;
}

/** Раздел методики */
export interface AuditEntitiesSharedSection {
  /** ID раздела */
  id?: string | null;
  /** Название раздела */
  name?: string | null;
  /** Описание */
  description?: string | null;
  /** Список показателей */
  indicators?: string[] | null;
  /** Поле для псевдо удаления документа */
  isDeleted?: boolean;
}

/** Модель объекта источника */
export interface AuditEntitiesSharedSource {
  /** ID интервью */
  interviewId?: string | null;
  /** ID вопроса в интервью */
  questionId?: string | null;
  /** ID документа */
  documentId?: string | null;
  /** Комментарий к источнику */
  comment?: string | null;
}

/** Токен */
export interface AuditModelsTokenRequest {
  /**
   * Токен
   * @minLength 1
   */
  token: string;
}

/** Token response */
export interface AuditModelsTokenResponse {
  /** Успешно ли прошла проверка */
  success: boolean;
  /**
   * Время проверки
   * @minLength 1
   */
  challenge_ts: string;
  /**
   * Имя хоста
   * @minLength 1
   */
  hostname: string;
  /** Коды ошибок */
  'error-codes': string[];
}
