import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AuditEntitiesMethodology } from '../../api/data-contracts';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { store } from '../../store';
import { updateMethodology } from '../../store/requests/methodologies';
import { authSelector } from '../../store/selectors/auth';
import { MethodologyCreateSectionForm } from './MethodologyCreateSectionForm';
import { setChosenSections } from '../../store/slices/sections';
import { MethodologySectionsChooseListItem } from './MethodologySectionsChooseListItem';
import { MdDeleteOutline } from 'react-icons/md';
import { sectionsSelector } from '../../store/selectors/sections';

interface MethodologySectionsChooseProps {
  onClose?: () => void;
  methodology?: AuditEntitiesMethodology;
}

export function MethodologySectionsChoose({
  onClose,
  methodology,
}: MethodologySectionsChooseProps) {
  const { sections, chosenSections } = useSelector(sectionsSelector);
  const { token } = useSelector(authSelector);

  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const toggleForm = () => setFormVisible(!formVisible);
  const toggleFocus = () => setInputFocus(!inputFocus);

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearch(e.target.value);
  };

  const handleChoose = (id?: string | null) => {
    if (!id) return;
    store.dispatch(setChosenSections([...(chosenSections || []), id]));
  };

  const handleDelete = (id?: string | null) => {
    const newSections = [...(chosenSections || [])];
    const deletedSectionIndex = newSections.findIndex((_) => _ === id);

    if (deletedSectionIndex > -1) {
      newSections.splice(deletedSectionIndex, 1);
      store.dispatch(setChosenSections(newSections));
    }
  };

  const handleUpdate = () => {
    if (!(methodology && methodology.id && token && chosenSections)) return;

    store.dispatch(
      updateMethodology({
        token,
        data: {
          methodology: {
            ...methodology,
            sections: [...(methodology.sections || []), ...chosenSections],
          },
        },
        id: methodology.id,
      }),
    );

    store.dispatch(setChosenSections([]));

    onClose && onClose();
  };

  const filteredSections = sections?.filter(
    (section) =>
      section.name?.toLowerCase().includes(search.toLowerCase()) &&
      !methodology?.sections?.includes(section.id || '') &&
      !chosenSections?.includes(section.id || ''),
  );

  return sections?.length ? (
    <div className="relative">
      {!formVisible ? (
        <>
          <div>
            Вы можете выбрать из имеющихся разделов или
            <Button variant="no-bg" className="text-link ml-1" onClick={toggleForm}>
              создать новый раздел
            </Button>
          </div>
          <div>
            <Input
              title="Поиск раздела"
              className="w-full"
              value={search}
              onChange={handleSearch}
              onFocus={toggleFocus}
              onBlur={toggleFocus}
            />
            {inputFocus ? (
              <div className="w-full z-20 flex absolute flex-col items-start rounded-xl bg-semiDarkGray border-darkGray border-opacity-40 shadow-xl">
                {filteredSections?.length ? (
                  filteredSections.map((section) => (
                    <MethodologySectionsChooseListItem
                      key={section.id}
                      section={section}
                      onChoose={handleChoose}
                    />
                  ))
                ) : (
                  <div className="px-6 py-2">Не найдено разделов</div>
                )}
              </div>
            ) : null}
          </div>
          {chosenSections?.length ? (
            <div>
              Выбранные разделы:
              {chosenSections.map((id) => (
                <div key={id} className="flex items-center">
                  <div>{sections.find((_) => _.id === id)?.name}</div>
                  <Button
                    variant="no-bg"
                    className="text-main-text ml-1"
                    onClick={() => handleDelete(id)}
                  >
                    <MdDeleteOutline size={24} />
                  </Button>
                </div>
              ))}
            </div>
          ) : null}
          <div>
            <div className="flex gap-x-2 justify-end">
              <Button version="secondary" onClick={onClose}>
                Отменить
              </Button>
              <Button version="secondary" onClick={handleUpdate} disabled={!chosenSections?.length}>
                Подтвердить
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div>
          <MethodologyCreateSectionForm onClose={toggleForm} />
        </div>
      )}
    </div>
  ) : (
    <div>
      <MethodologyCreateSectionForm onClose={onClose} />
    </div>
  );
}
