import React, { useEffect } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { AuditEntitiesAccountRole } from '../../api/data-contracts';
import { authSelector } from '../../store/selectors/auth';
import { Button } from '../Button';
import { Header } from '../Header';
import { Heading } from '../Heading';
import { useGetRole } from '../../utils/hooks';
import { userSelector } from '../../store/selectors/user';
import { getData } from '../../utils/getData';

interface PrivateRouterProps {
  children: React.ReactElement;
}

export function PrivateRouter({ children }: PrivateRouterProps) {
  const authData = useSelector(authSelector);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isSupervisor, isCompanyMember } = useGetRole();

  useEffect(() => {
    const requestData = {
      ...authData,
      isSupervisor,
      isCompanyMember,
    };

    getData(requestData);

    const intervalCallback = () => {
      getData(requestData);
    };

    const interval = setInterval(intervalCallback, 30000);

    return () => clearInterval(interval);
  }, [authData, isSupervisor, isCompanyMember]);

  return authData.token ? (
    <div className="text-main-text tracking-wide font-normal">
      <Header />
      <div className="py-10 px-24">
        {pathname !== '/' ? (
          <Button variant="no-bg" onClick={() => navigate(-1)}>
            <Heading size="XS" className="flex items-center text-link">
              <MdArrowBack size={24} className="mr-2" />
              Назад
            </Heading>
          </Button>
        ) : null}
        {children}
      </div>
    </div>
  ) : (
    <Navigate to="/login" replace />
  );
}
