import React from 'react';
import { AuditEntitiesSharedSection } from '../../api/data-contracts';
import { Button } from '../../components/Button';

interface MethodologySectionsChooseListItemProps {
  section: AuditEntitiesSharedSection;
  onChoose: (id: AuditEntitiesSharedSection['id']) => void;
}

export function MethodologySectionsChooseListItem({
  onChoose,
  section,
}: MethodologySectionsChooseListItemProps) {
  return (
    <Button
      key={section.id}
      variant="no-bg"
      scale="large"
      className="text-main-text pl-6 rounded-xl text-left w-full"
      onMouseDown={() => onChoose(section.id)}
    >
      {section.name}
    </Button>
  );
}
