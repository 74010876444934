import { store } from '../store';
import { dropAuth } from '../store/slices/auth';
import { dropUser } from '../store/slices/user';
import { dropProjects } from '../store/slices/projects';
import { dropMethodologies } from '../store/slices/methodologies';
import { dropIndicators } from '../store/slices/indicators';
import { dropSections } from '../store/slices/sections';
import { dropDocuments } from '../store/slices/documents';
import { dropCompanies } from '../store/slices/companies';
import { dropInterviews } from '../store/slices/interviews';

export const logout = () => {
  store.dispatch(dropAuth());
  store.dispatch(dropUser());
  store.dispatch(dropProjects());
  store.dispatch(dropMethodologies());
  store.dispatch(dropIndicators());
  store.dispatch(dropSections());
  store.dispatch(dropDocuments());
  store.dispatch(dropInterviews());
  store.dispatch(dropCompanies());
};
