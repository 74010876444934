import React from 'react';
import { MdAdd } from 'react-icons/md';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { useModal } from '../../utils/hooks/useModal';
import { IndicatorsEditModal } from './IndicatorsEditModal';
import { IndicatorsListTable } from './IndicatorsListTable';

export function IndicatorsList() {
  const { modalOpened, openModal, closeModal } = useModal();

  return (
    <>
      <Heading className="mb-8">Показатели</Heading>
      <Button className="mb-8" onClick={openModal}>
        <div className="flex">
          <MdAdd size={24} className="mr-1" />
          <span>Добавить показатель</span>
        </div>
      </Button>
      <IndicatorsListTable />
      <IndicatorsEditModal modalOpened={modalOpened} onClose={closeModal} />
    </>
  );
}
