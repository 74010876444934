import React, { useState } from 'react';
import { Logo } from '../../components/Logo';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { useNavigate } from 'react-router-dom';
import { createAccount } from '../../store/requests/auth';
import store from '../../store/store';
import { Heading } from '../../components/Heading';
import { MdChevronLeft } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/selectors/auth';
import { changeAccountForm } from '../../store/slices/auth';
import { AuditEntitiesAccountRole } from '../../api/data-contracts';

export function Register() {
  const { token, createAccountForm } = useSelector(authSelector);
  const { firstName, lastName, email, password, patronymic } = createAccountForm;
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const navigate = useNavigate();

  const handleCreateAccount = () => {
    store
      .dispatch(createAccount({ token, data: createAccountForm }))
      .then(() => navigate('/login'));
  };

  const handleChangeRole: React.SelectHTMLAttributes<HTMLSelectElement>['onChange'] = (e) => {
    store.dispatch(changeAccountForm({ ...createAccountForm, [e.target.name]: e.target.value }));
  };

  const handleChangeForm: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    store.dispatch(changeAccountForm({ ...createAccountForm, [e.target.name]: e.target.value }));
  };

  return (
    <div className="bg-main flex justify-center content-center items-center h-screen">
      <div className="bg-blue flex flex-col items-center px-[30px] py-[40px] border border-darkGray border-opacity-40 shadow-xl rounded-[16px] w-[380px]">
        <Logo version="transparent" size={60} className="mb-[20px]" />
        <Heading className="self-start mb-[10px]" size="S">
          Регистрация
        </Heading>
        <div className="mb-[25px] text-secondary-gray">
          Обратите внимание - регистрация возможна только для пользователей с ролью
          &quot;Представитель компании&quot;
        </div>
        <Input
          className="mb-2 w-full"
          placeholder="Имя"
          name="firstName"
          value={firstName}
          onChange={handleChangeForm}
        />
        <Input
          className="mb-2 w-full"
          placeholder="Фамилия"
          name="lastName"
          value={lastName}
          onChange={handleChangeForm}
        />
        <Input
          className="mb-2 w-full"
          placeholder="Отчество (при наличии)"
          name="patronymic"
          value={patronymic || ''}
          onChange={handleChangeForm}
        />
        <Input
          className="mb-2 w-full"
          placeholder="E-mail"
          name="email"
          value={email}
          onChange={handleChangeForm}
        />
        <Input
          className="mb-2 w-full"
          placeholder="Пароль"
          name="password"
          value={password}
          onChange={handleChangeForm}
          type="password"
        />
        <Input
          className="w-full mb-2"
          placeholder="Подтвердите пароль"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          errorCondition={!!confirmPassword.length && confirmPassword !== password}
          errorMessage="Пароли не совпадают"
        />
        <div className="text-primary-text w-full mb-2">
          <select
            id="role"
            name="role"
            className="w-full duration-300 rounded focus-visible:outline-auto px-4 py-2.5 text-base border border-darkGray border-opacity-40"
            placeholder="Роль"
            onChange={handleChangeRole}
          >
            <option value={AuditEntitiesAccountRole.CompanyMember}>Представитель компании</option>
            <option value={AuditEntitiesAccountRole.Auditor}>Аудитор</option>
            <option value={AuditEntitiesAccountRole.Supervisor}>Руководитель</option>
          </select>
        </div>
        <Button
          className="w-full mt-2"
          onClick={handleCreateAccount}
          disabled={
            !firstName || !email || !password || !confirmPassword || confirmPassword !== password
          }
        >
          Подтвердить
        </Button>
        <div className="mt-6 text-main cursor-pointer flex" onClick={() => navigate('/login')}>
          <MdChevronLeft size={24} />
          <div>Назад ко входу</div>
        </div>
      </div>
    </div>
  );
}
