import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PrivateRouter } from './PrivateRouter';
import { Login } from '../../pages/Login';
import { Home } from '../../pages/Home';
import { Project } from '../../pages/Project';
import { Profile } from '../../pages/Profile';
import { Methodology } from '../../pages/Methodology';
import { Section, SectionsList } from '../../pages/Sections';
import { Indicator, IndicatorsList } from '../../pages/Indicators';
import { Document, DocumentsList } from '../../pages/Documents';
import { ForgotPassword } from '../../pages/ForgotPassword';
import { ResetPassword } from '../../pages/ResetPassword';
import { Interview, InterviewsList } from '../../pages/Interviews';
import { useGetRole } from '../../utils/hooks';
import { Register } from '../../pages/Register';

export const privateRoutes = [
  { path: '/', name: 'Главная', index: true, element: <Home /> },
  { path: '/profile', name: 'Профиль', element: <Profile /> },
  { path: '/profile/reset-password', name: 'Изменить пароль', element: <ResetPassword /> },
  { path: '/project/:projectId', name: 'Проект', element: <Project /> },
  {
    path: '/project/:projectId/interview/:interviewId',
    name: 'Интервью',
    element: <Interview />,
  },
  {
    path: '/project/:projectId/document/:documentId',
    name: 'Документ',
    element: <Document />,
  },
  { path: '/project/:projectId/section/:sectionId', name: 'Раздел', element: <Section /> },
  {
    path: '/project/:projectId/section/:sectionId/indicator/:indicatorId',
    name: 'Показатель',
    element: <Indicator />,
  },
];

const publicRoutes = [
  { path: '/login', name: 'Логин', element: <Login /> },
  { path: '/forgot-password', name: 'Забыли пароль', element: <ForgotPassword /> },
  { path: '/register', name: 'Регистрация', element: <Register /> },
  { path: '/reset-password', name: 'Изменить пароль', element: <ResetPassword /> },
];

const supervisorRoutes = [
  { path: '/methodology/:methodologyId', name: 'Методика', element: <Methodology /> },
  { path: '/sections', name: 'Разделы', element: <SectionsList /> },
  { path: '/sections/:sectionId', name: 'Раздел', element: <Section /> },
  { path: '/indicators', name: 'Показатели', element: <IndicatorsList /> },
  { path: '/indicators/:indicatorId', name: 'Показатель', element: <Indicator /> },
  { path: '/documents', name: 'Документы', element: <DocumentsList /> },
  { path: '/documents/:documentId', name: 'Документ', element: <Document /> },
  { path: '/interviews', name: 'Интервью', element: <InterviewsList /> },
  { path: '/interviews/:interviewId', name: 'Интервью', element: <Interview /> },
  {
    path: '/methodology/:methodologyId/interview/:interviewId',
    name: 'Интервью',
    element: <Interview />,
  },
  {
    path: '/methodology/:methodologyId/document/:documentId',
    name: 'Документ',
    element: <Document />,
  },
  { path: '/methodology/:methodologyId/section/:sectionId', name: 'Раздел', element: <Section /> },
  {
    path: '/methodology/:methodologyId/section/:sectionId/indicator/:indicatorId',
    name: 'Показатель',
    element: <Indicator />,
  },
];

export function Router() {
  const { isSupervisor } = useGetRole();
  return (
    <Routes>
      {privateRoutes.map(({ element, ...rest }) => {
        return (
          <Route key={rest.path} element={<PrivateRouter>{element}</PrivateRouter>} {...rest} />
        );
      })}
      {publicRoutes.map((route) => (
        <Route key={route.path} {...route} />
      ))}
      {isSupervisor &&
        supervisorRoutes.map(({ element, ...rest }) => {
          return (
            <Route key={rest.path} element={<PrivateRouter>{element}</PrivateRouter>} {...rest} />
          );
        })}
    </Routes>
  );
}
