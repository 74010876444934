import { Methodologies } from './../../api/Methodologies';
import {
  AuditContractsProjectsCreateMethodologyRequest,
  AuditContractsProjectsUpdateMethodologyRequest,
} from './../../api/data-contracts';
import { ActionWithEntityByIdsArray, ActionWithEntityById, TokenRequest } from './../types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { dropSectionForm } from '../slices/sections';
import { logout } from '../../utils/logout';
import { getSections } from './sections';
import { getIndicators } from './indicators';
import { ApiUrl } from './url';

const methodologiesApi = new Methodologies({
  baseUrl: ApiUrl,
  customFetch: (...fetchParams: Parameters<typeof fetch>) => {
    return fetch(...fetchParams).then((res) => {
      const status = res?.status;
      if (status === 401) logout();

      return res;
    });
  },
});

interface UpdateMethodologyProps extends TokenRequest {
  id: string;
  data: AuditContractsProjectsUpdateMethodologyRequest;
}

interface CloneMethodologyProps extends TokenRequest {
  name: string;
  id: string;
  isSelfRating: boolean;
}

interface CreateMethodologyProps
  extends TokenRequest,
    AuditContractsProjectsCreateMethodologyRequest {}

export const getAllMethodologies = createAsyncThunk(
  'methodologies/getAllMethodologies',
  async ({ token }: TokenRequest) => {
    const res = await methodologiesApi.getMethodologiesList({
      headers: { Authorization: token || '' },
    });
    return res.data;
  },
);

export const getMethodologiesList = createAsyncThunk(
  'methodologies/getMethodologiesList',
  async ({ token, ids }: ActionWithEntityByIdsArray) => {
    const res = await methodologiesApi.getMethodologiesListList(
      { ids },
      {
        headers: { Authorization: token || '' },
      },
    );
    return res.data;
  },
);

export const getMethodologyById = createAsyncThunk(
  'methodologies/getMethodologyById',
  async ({ token, id }: ActionWithEntityById) => {
    const res = await methodologiesApi.getMethodologyDetail(id, {
      headers: { Authorization: token || '' },
    });
    return res.data;
  },
);

export const createMethodology = createAsyncThunk(
  'methodologies/createMethodology',
  async ({ token, userId, name, isSelfRating }: CreateMethodologyProps, { dispatch }) => {
    const res = await methodologiesApi.createMethodologyCreate(
      { userId, name, isSelfRating },
      {
        headers: { Authorization: token || '' },
      },
    );

    dispatch(getAllMethodologies({ token }));
    return res.data;
  },
);

export const updateMethodology = createAsyncThunk(
  'methodologies/updateMethodology',
  async ({ id, data, token }: UpdateMethodologyProps, { dispatch }) => {
    await methodologiesApi.updateMethodologyCreate(id, data, {
      headers: { Authorization: token || '' },
    });
    dispatch(dropSectionForm());
    dispatch(getAllMethodologies({ token }));
  },
);

export const cloneMethodology = createAsyncThunk(
  'methodologies/cloneMethodology',
  async ({ id, name, token, isSelfRating }: CloneMethodologyProps, { dispatch }) => {
    await methodologiesApi.cloneMethodologyPartialUpdate(id, name, isSelfRating, {
      headers: { Authorization: token || '' },
    });

    dispatch(getAllMethodologies({ token }));
    dispatch(getSections({ token }));
    dispatch(getIndicators({ token }));
  },
);
