import { createAsyncThunk } from '@reduxjs/toolkit';
import { Accounts } from '../../api/Accounts';
import { logout } from '../../utils/logout';
import {
  AuditContractsAccountsAuthenticate,
  AuditContractsAccountsRegistrationRequest,
  AuditContractsAccountsForgotPasswordRequest,
  AuditContractsAccountsResetPasswordRequest,
  AuditContractsAccountsUpdatePasswordRequest,
} from '../../api/data-contracts';
import { getAccountDetail } from './user';
import { ActionWithEntityById, TokenRequest } from '../types';
import { ApiUrl } from './url';
import { getAllAccounts } from './user';

const accountsApi = new Accounts({
  baseUrl: ApiUrl,
  customFetch: (...fetchParams: Parameters<typeof fetch>) => {
    return fetch(...fetchParams).then((res) => {
      const status = res?.status;
      if (status === 401) logout();

      return res;
    });
  },
});

export const authenticate = createAsyncThunk(
  'auth/authenticate',
  async (data: AuditContractsAccountsAuthenticate, { dispatch }) => {
    const res = await accountsApi.authenticateCreate(data);
    const loginData = res.data;

    if (loginData.userId && loginData.token) {
      dispatch(getAccountDetail({ id: loginData.userId, token: loginData.token }));
    }

    return loginData;
  },
);

export const sendResetPasswordLink = createAsyncThunk(
  'auth/sendResetPasswordLink',
  async (data: AuditContractsAccountsForgotPasswordRequest) => {
    const res = await accountsApi.forgotPasswordCreate(data);
    return res.data;
  },
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data: AuditContractsAccountsResetPasswordRequest) => {
    const res = await accountsApi.resetPasswordCreate(data);
    return res.data;
  },
);

interface UpdatePasswordParams extends ActionWithEntityById {
  data: AuditContractsAccountsUpdatePasswordRequest;
}

export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async ({ token, id, data }: UpdatePasswordParams) => {
    const res = await accountsApi.updatePasswordPartialUpdate(id, data, {
      headers: { Authorization: token || '' },
    });
    return res.data;
  },
);

interface CreateAccountParams extends TokenRequest {
  data: AuditContractsAccountsRegistrationRequest;
}

export const createAccount = createAsyncThunk(
  'auth/createAccount',
  async ({ token, data }: CreateAccountParams) => {
    const res = await accountsApi.registerCreate(data, {
      headers: { Authorization: token || '' },
    });
    return res.data;
  },
);

export const verifyUser = createAsyncThunk(
  'auth/verifyUser',
  async ({ token, id }: ActionWithEntityById, { dispatch }) => {
    const res = await accountsApi.verifyUserDetail(
      id,
      { userId: id },
      {
        headers: { Authorization: token || '' },
      },
    );

    dispatch(getAllAccounts({ token }));

    return res.data;
  },
);
