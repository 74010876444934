import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AuditEntitiesMethodology } from '../../api/data-contracts';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { methodologiesSelector } from '../../store/selectors/methodologies';
import { projectsSelector } from '../../store/selectors/projects';
import { useSearch } from '../../utils/hooks';

interface ProjectCreateFormMethodologySearchProps {
  onChoose: (methodology?: AuditEntitiesMethodology) => void;
  onlySelfRating?: boolean;
}

export function ProjectCreateFormMethodologySearch({
  onChoose,
  onlySelfRating = false,
}: ProjectCreateFormMethodologySearchProps) {
  const { methodologies } = useSelector(methodologiesSelector);

  const [inputFocus, setInputFocus] = useState<boolean>(false);

  const toggleFocus = () => setInputFocus(!inputFocus);

  const searchFilterFunction = (searchString: string, methodology: AuditEntitiesMethodology) => {
    const searchStringLowercase = searchString.toLowerCase();
    const nameLowercase = methodology.name?.toLowerCase() || '';

    return nameLowercase.includes(searchStringLowercase);
  };

  const readyMethodologies = onlySelfRating
    ? methodologies?.filter((_) => !!_.isSelfRating)
    : methodologies;

  const {
    filteredValue: filteredMethodologies,
    searchInput,
    setSearchInput,
  } = useSearch(readyMethodologies || [], searchFilterFunction);

  const handleChoose = (methodology: AuditEntitiesMethodology) => {
    onChoose(methodology);
    setSearchInput('');
  };

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchInput(e.target.value);
    onChoose(undefined);
  };

  return (
    <div className="relative">
      <Input
        title="Выбор методики"
        className="w-full"
        value={searchInput}
        onChange={handleSearch}
        onFocus={toggleFocus}
        onBlur={toggleFocus}
      />
      {inputFocus ? (
        <div className="w-full flex z-20 absolute flex-col items-start rounded-xl bg-semiDarkGray border-darkGray border-opacity-40 shadow-xl">
          {filteredMethodologies?.length ? (
            filteredMethodologies.map((methodology) => {
              return (
                <Button
                  key={methodology.id}
                  variant="no-bg"
                  scale="large"
                  className="text-main-text pl-6 rounded-xl"
                  onMouseDown={() => handleChoose(methodology)}
                >
                  {methodology.name}
                </Button>
              );
            })
          ) : (
            <div className="px-6 py-2">Не найдено методик</div>
          )}
        </div>
      ) : null}
    </div>
  );
}
