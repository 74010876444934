/* eslint-disable complexity */
import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { store } from '../../store';
import { userSelector } from '../../store/selectors/user';
import { setConfigData, ProfileFormProps, changeCompanyIds } from '../../store/slices/user';
import { updateAccount } from '../../store/requests/user';
import { authSelector } from '../../store/selectors/auth';
import { Link } from 'react-router-dom';

export function Profile() {
  const { userId, token } = useSelector(authSelector);
  const { profileForm } = useSelector(userSelector);

  const handleChangeProfile: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    const newName = name as keyof ProfileFormProps;

    store.dispatch(setConfigData({ name: newName, value }));
  };

  const handleSaveData = () => {
    if (userId) {
      store.dispatch(updateAccount({ id: userId, data: profileForm, token }));
    }
  };

  return (
    <>
      <div className="max-w-[900px] mb-4">
        <Heading>Профиль пользователя</Heading>
        <div className="bg-semiDarkGray rounded-xl px-8 py-10">
          <div className="mb-2 flex gap-x-8">
            <Input
              className="w-full"
              title="Имя"
              placeholder="Введите имя"
              name="firstName"
              onChange={handleChangeProfile}
              value={profileForm?.firstName || ''}
            />
            <Input
              className="w-full"
              title="Фамилия"
              placeholder="Введите фамилию"
              name="lastName"
              onChange={handleChangeProfile}
              value={profileForm?.lastName || ''}
            />
            <Input
              className="w-full"
              title="Отчество"
              placeholder="Введите отчество"
              name="patronymic"
              onChange={handleChangeProfile}
              value={profileForm?.patronymic || ''}
            />
          </div>
          <div className="mb-2 flex gap-x-8">
            <Input
              className="w-full"
              title="Почта"
              placeholder="Введите почту"
              name="email"
              onChange={handleChangeProfile}
              value={profileForm?.email || ''}
            />
            <Input
              className="w-full"
              title="Телефон"
              placeholder="Введите телефон"
              name="phoneNumber"
              onChange={handleChangeProfile}
              value={profileForm?.phoneNumber || ''}
            />
          </div>
          {/* {isCompanyMember ? (
            <ProjectCreateFormCompaniesSearch
              onChoose={setCompanyIds}
              initialCompanyIds={profileForm.companyIds || []}
            />
          ) : null} */}
          <Button onClick={handleSaveData}>Сохранить</Button>
        </div>
      </div>
      <div>
        <Link className="text-decoration-line: underline text-link" to={'reset-password'}>
          Поменять пароль
        </Link>
      </div>
    </>
  );
}
