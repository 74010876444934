import { TokenRequest, ActionWithEntityByIdsArray } from './../types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Accounts } from '../../api/Accounts';
import { AuditContractsAccountsUpdateRequest } from '../../api/data-contracts';
import { logout } from '../../utils/logout';
import { ApiUrl } from './url';

const accountsApi = new Accounts({
  baseUrl: ApiUrl,
  customFetch: (...fetchParams: Parameters<typeof fetch>) => {
    return fetch(...fetchParams).then((res) => {
      const status = res?.status;
      if (status === 401) logout();

      return res;
    });
  },
});

interface AccountActionsProps extends TokenRequest {
  id: string;
}

interface UpdateAccountProps extends AccountActionsProps {
  data: AuditContractsAccountsUpdateRequest;
  isNotSelf?: boolean;
}

export const getAccountDetail = createAsyncThunk(
  'user/getAccountDetail',
  async ({ id, token }: AccountActionsProps) => {
    const res = await accountsApi.getAccountDetail(id, { headers: { Authorization: token || '' } });
    return res.data;
  },
);

export const updateAccount = createAsyncThunk(
  'user/updateAccount',
  async ({ id, data, token, isNotSelf = false }: UpdateAccountProps, { dispatch }) => {
    await accountsApi.updateAccountUpdate(id, data, { headers: { Authorization: token || '' } });
    if (!isNotSelf) {
      dispatch(getAccountDetail({ id, token }));
    }
  },
);

export const getAllAccounts = createAsyncThunk(
  'user/getAllAccounts',
  async ({ token }: TokenRequest) => {
    const res = await accountsApi.getAllAccountsList({ headers: { Authorization: token || '' } });
    return res.data;
  },
);

export const getAccountsList = createAsyncThunk(
  'user/getAccountsList',
  async ({ token, ids }: ActionWithEntityByIdsArray) => {
    const res = await accountsApi.getAccountsListList(
      { ids },
      { headers: { Authorization: token || '' } },
    );
    return res.data;
  },
);
