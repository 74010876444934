import React, { FC } from 'react';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import { Button } from '../Button';

type EditDeleteButtonsProps = {
  onEditClick: () => void;
  onDeleteClick: () => void;
};

export const EditDeleteButtons: FC<EditDeleteButtonsProps> = ({ onEditClick, onDeleteClick }) => {
  return (
    <div className="flex" key="edit-delete">
      <Button key="edit" variant="no-bg" className="ml-4 text-main" onClick={onEditClick}>
        <MdEdit size={24} />
      </Button>
      <Button key="delete" variant="no-bg" className="text-main" onClick={onDeleteClick}>
        <MdDeleteOutline size={24} />
      </Button>
    </div>
  );
};
