import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AuditEntitiesSharedIndicator, AuditEntitiesSharedSection } from '../../api/data-contracts';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { store } from '../../store';
import { authSelector } from '../../store/selectors/auth';
import { useSearch } from '../../utils/hooks';
import { Modal } from '../../components/Modal';
import { updateSectionById } from '../../store/requests/sections';
import { IndicatorsEditForm } from '../Indicators/IndicatorsEditForm';
import { indicatorsSelector } from '../../store/selectors/indicators';

interface AddIndicatorModalProps {
  onClose: () => void;
  modalOpened: boolean;
  section: AuditEntitiesSharedSection;
}

const searchFilterFunction = (searchString: string, section: AuditEntitiesSharedIndicator) => {
  return section.name?.toLowerCase().includes(searchString.toLowerCase()) ?? false;
};

export function AddIndicatorModal({ modalOpened, onClose, section }: AddIndicatorModalProps) {
  const { indicators } = useSelector(indicatorsSelector);
  const { token } = useSelector(authSelector);

  const [selectedIndicator, setSelectedIndicator] = useState<AuditEntitiesSharedIndicator | null>(
    null,
  );

  const [inputFocus, setInputFocus] = useState<boolean>(false);

  const [formVisible, setFormVisible] = useState<boolean>(false);

  const { filteredValue, searchInput, setSearchInput } = useSearch(
    indicators || [],
    searchFilterFunction,
  );

  const alreadySelectedIndicatorsIds = section.indicators ?? [];

  const filteredIndicators = filteredValue.filter(
    (indicator) => indicator.id && !alreadySelectedIndicatorsIds.includes(indicator.id),
  );

  const toggleForm = () => setFormVisible(!formVisible);

  const handleSubmit = () => {
    if (selectedIndicator && selectedIndicator.id) {
      store.dispatch(
        updateSectionById({
          data: { ...section, indicators: [...(section.indicators ?? []), selectedIndicator.id] },
          token,
        }),
      );
      setSelectedIndicator(null);
      onClose();
    }
  };

  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        !formVisible ? (
          <div className="relative">
            <div>
              Вы можете выбрать из имеющихся показателей или
              <Button variant="no-bg" className="text-link ml-1" onClick={toggleForm}>
                создать новый показатель
              </Button>
            </div>
            <Input
              title="Поиск показателя по названию"
              className="w-full"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onFocus={() => setInputFocus(true)}
              onBlur={() => setInputFocus(false)}
            />
            <div
              className={`w-full z-20 flex absolute flex-col items-start rounded-xl bg-semiDarkGray border-darkGray border-opacity-40 shadow-xl`}
            >
              {inputFocus &&
                (filteredIndicators.length ? (
                  filteredIndicators.map((indicator) => (
                    <Button
                      key={indicator.id}
                      variant="no-bg"
                      scale="large"
                      className="text-main-text pl-6 rounded-xl"
                      onMouseDown={() => {
                        setSelectedIndicator(indicator);
                      }}
                    >
                      {indicator.name}
                    </Button>
                  ))
                ) : (
                  <div className="px-6 py-2">Нет доступных для выбора показателей</div>
                ))}
            </div>
            {selectedIndicator && <div>Выбранный показатель: {selectedIndicator.name}</div>}
            <div className="flex gap-x-2 justify-end">
              <Button version="secondary" onClick={onClose}>
                Отменить
              </Button>
              <Button version="secondary" onClick={handleSubmit} disabled={!selectedIndicator}>
                Подтвердить
              </Button>
            </div>
          </div>
        ) : (
          <IndicatorsEditForm onClose={toggleForm} />
        )
      }
    />
  );
}
