/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AuditContractsProjectsCreateMethodologyRequest,
  AuditContractsProjectsGetDocumentListRequest,
  AuditContractsProjectsUpdateMethodologyRequest,
  AuditEntitiesCompany,
  AuditEntitiesMethodology,
  AuditEntitiesSharedDocument,
  AuditEntitiesSharedIndicator,
  AuditEntitiesSharedInterview,
  AuditEntitiesSharedSection,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Methodologies<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetMethodologiesList
   * @summary Получение всех методик
   * @request GET:/methodologies/get-methodologies
   * @secure
   */
  getMethodologiesList = (params: RequestParams = {}) =>
    this.request<AuditEntitiesMethodology[], any>({
      path: `/methodologies/get-methodologies`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetMethodologiesListList
   * @summary Получение методик по списку ID
   * @request GET:/methodologies/get-methodologies-list
   * @secure
   */
  getMethodologiesListList = (
    query?: {
      ids?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<AuditEntitiesMethodology[], any>({
      path: `/methodologies/get-methodologies-list`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetMethodologyDetail
   * @summary Получение методики
   * @request GET:/methodologies/get-methodology/{id}
   * @secure
   */
  getMethodologyDetail = (id: string, params: RequestParams = {}) =>
    this.request<AuditEntitiesMethodology, any>({
      path: `/methodologies/get-methodology/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name CreateMethodologyCreate
   * @summary Создание методики
   * @request POST:/methodologies/create-methodology
   * @secure
   */
  createMethodologyCreate = (
    data: AuditContractsProjectsCreateMethodologyRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/methodologies/create-methodology`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name UpdateMethodologyCreate
   * @summary Обновление методики
   * @request POST:/methodologies/update-methodology/{id}
   * @secure
   */
  updateMethodologyCreate = (
    id: string,
    data: AuditContractsProjectsUpdateMethodologyRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/methodologies/update-methodology/${id}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name CloneMethodologyPartialUpdate
   * @summary Клонирование методики
   * @request PATCH:/methodologies/clone-methodology/{id}/{name}/{isSelfRating}
   * @secure
   */
  cloneMethodologyPartialUpdate = (
    id: string,
    name: string,
    isSelfRating: boolean,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/methodologies/clone-methodology/${id}/${name}/${isSelfRating}`,
      method: 'PATCH',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetSectionsList
   * @summary получение разделов
   * @request GET:/methodologies/get-sections
   * @secure
   */
  getSectionsList = (params: RequestParams = {}) =>
    this.request<AuditEntitiesSharedSection[], any>({
      path: `/methodologies/get-sections`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetSectionDetail
   * @summary Получение раздела по ID
   * @request GET:/methodologies/get-section/{id}
   * @secure
   */
  getSectionDetail = (id: string, params: RequestParams = {}) =>
    this.request<AuditEntitiesSharedSection, any>({
      path: `/methodologies/get-section/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetSectionsListList
   * @summary Получение разделов по списку ID
   * @request GET:/methodologies/get-sections-list
   * @secure
   */
  getSectionsListList = (
    query?: {
      ids?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<AuditEntitiesSharedSection[], any>({
      path: `/methodologies/get-sections-list`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name CreateSectionCreate
   * @summary Создание раздела
   * @request POST:/methodologies/create-section
   * @secure
   */
  createSectionCreate = (data: AuditEntitiesSharedSection, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/methodologies/create-section`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name UpdateSectionPartialUpdate
   * @summary Обновление раздела
   * @request PATCH:/methodologies/update-section
   * @secure
   */
  updateSectionPartialUpdate = (data: AuditEntitiesSharedSection, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/methodologies/update-section`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name RemoveSectionDelete
   * @summary Удаление раздела
   * @request DELETE:/methodologies/remove-section/{id}
   * @secure
   */
  removeSectionDelete = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/methodologies/remove-section/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetIndicatorsList
   * @summary Получение показателей
   * @request GET:/methodologies/get-indicators
   * @secure
   */
  getIndicatorsList = (params: RequestParams = {}) =>
    this.request<AuditEntitiesSharedIndicator[], any>({
      path: `/methodologies/get-indicators`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetIndicatorDetail
   * @summary Получение показателя по ID
   * @request GET:/methodologies/get-indicator/{id}
   * @secure
   */
  getIndicatorDetail = (id: string, params: RequestParams = {}) =>
    this.request<AuditEntitiesSharedIndicator, any>({
      path: `/methodologies/get-indicator/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetIndicatorsListList
   * @summary Получение показателей по списку ID
   * @request GET:/methodologies/get-indicators-list
   * @secure
   */
  getIndicatorsListList = (
    query?: {
      ids?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<AuditEntitiesSharedIndicator[], any>({
      path: `/methodologies/get-indicators-list`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name CreateIndicatorCreate
   * @summary Создание показателя
   * @request POST:/methodologies/create-indicator
   * @secure
   */
  createIndicatorCreate = (data: AuditEntitiesSharedIndicator, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/methodologies/create-indicator`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name UpdateIndicatorPartialUpdate
   * @summary Обновление показателя
   * @request PATCH:/methodologies/update-indicator
   * @secure
   */
  updateIndicatorPartialUpdate = (data: AuditEntitiesSharedIndicator, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/methodologies/update-indicator`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name RemoveIndicatorDelete
   * @summary Удаление показателя
   * @request DELETE:/methodologies/remove-indicator/{id}
   * @secure
   */
  removeIndicatorDelete = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/methodologies/remove-indicator/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetDocumentsList
   * @summary Получение документов
   * @request GET:/methodologies/get-documents
   * @secure
   */
  getDocumentsList = (params: RequestParams = {}) =>
    this.request<AuditEntitiesSharedDocument[], any>({
      path: `/methodologies/get-documents`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetDocumentDetail
   * @summary Получение документа по ID
   * @request GET:/methodologies/get-document/{id}
   * @secure
   */
  getDocumentDetail = (id: string, params: RequestParams = {}) =>
    this.request<AuditEntitiesSharedDocument, any>({
      path: `/methodologies/get-document/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetDocumentsListCreate
   * @summary Получение документов по списку ID
   * @request POST:/methodologies/get-documents-list
   * @secure
   */
  getDocumentsListCreate = (
    data: AuditContractsProjectsGetDocumentListRequest,
    params: RequestParams = {},
  ) =>
    this.request<AuditEntitiesSharedDocument[], any>({
      path: `/methodologies/get-documents-list`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name CreateDocumentCreate
   * @summary Создание документа
   * @request POST:/methodologies/create-document
   * @secure
   */
  createDocumentCreate = (data: AuditEntitiesSharedDocument, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/methodologies/create-document`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name UpdateDocumentPartialUpdate
   * @summary Обновление документа
   * @request PATCH:/methodologies/update-document
   * @secure
   */
  updateDocumentPartialUpdate = (data: AuditEntitiesSharedDocument, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/methodologies/update-document`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name RemoveDocumentDelete
   * @summary Удаление документа
   * @request DELETE:/methodologies/remove-document/{id}
   * @secure
   */
  removeDocumentDelete = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/methodologies/remove-document/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetInterviewsList
   * @summary Получение интервью
   * @request GET:/methodologies/get-interviews
   * @secure
   */
  getInterviewsList = (params: RequestParams = {}) =>
    this.request<AuditEntitiesSharedInterview[], any>({
      path: `/methodologies/get-interviews`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetInterviewDetail
   * @summary Получение интервью по ID
   * @request GET:/methodologies/get-interview/{id}
   * @secure
   */
  getInterviewDetail = (id: string, params: RequestParams = {}) =>
    this.request<AuditEntitiesSharedInterview, any>({
      path: `/methodologies/get-interview/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetInterviewsListList
   * @summary Получение интервью по списку ID
   * @request GET:/methodologies/get-interviews-list
   * @secure
   */
  getInterviewsListList = (
    query?: {
      ids?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<AuditEntitiesSharedInterview[], any>({
      path: `/methodologies/get-interviews-list`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name CreateInterviewCreate
   * @summary Создание интервью
   * @request POST:/methodologies/create-interview
   * @secure
   */
  createInterviewCreate = (data: AuditEntitiesSharedInterview, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/methodologies/create-interview`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name UpdateInterviewPartialUpdate
   * @summary Обновление интервью
   * @request PATCH:/methodologies/update-interview
   * @secure
   */
  updateInterviewPartialUpdate = (data: AuditEntitiesSharedInterview, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/methodologies/update-interview`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name RemoveInterviewDelete
   * @summary Удаление интервью
   * @request DELETE:/methodologies/remove-interview/{id}
   * @secure
   */
  removeInterviewDelete = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/methodologies/remove-interview/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name CreateCompanyCreate
   * @summary Получение интервью
   * @request POST:/methodologies/create-company
   * @secure
   */
  createCompanyCreate = (data: AuditEntitiesCompany, params: RequestParams = {}) =>
    this.request<AuditEntitiesSharedInterview[], any>({
      path: `/methodologies/create-company`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name GetAllCompaniesList
   * @summary Получение интервью по ID
   * @request GET:/methodologies/get-all-companies
   * @secure
   */
  getAllCompaniesList = (params: RequestParams = {}) =>
    this.request<AuditEntitiesCompany[], any>({
      path: `/methodologies/get-all-companies`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Methodologies
   * @name UpdateCompanyPartialUpdate
   * @summary Обновление компании
   * @request PATCH:/methodologies/update-company
   * @secure
   */
  updateCompanyPartialUpdate = (data: AuditEntitiesCompany, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/methodologies/update-company`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
