import React from 'react';

interface ModalProps {
  visible?: boolean;
  onClose?: () => void;
  body?: React.ReactElement;
}

export function Modal({ visible = false, onClose, body }: ModalProps) {
  return visible ? (
    <>
      <div
        className="fixed top-0 left-0 w-full h-full bg-lightGray opacity-30 z-10"
        onClick={onClose}
      />
      <div className="px-6 py-6 min-h-[50px] min-w-[600px] bg-white fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 shadow-2xl max-h-[90vh]">
        {body}
      </div>
    </>
  ) : null;
}
