import React, { useEffect } from 'react';
import { Table } from '../../components/Table';
import { useParams } from 'react-router-dom';
import { Heading } from '../../components/Heading';
import { useSelector } from 'react-redux';
import { formatUnix } from '../../utils/formatUnix';
import { Button } from '../../components/Button';
import { store } from '../../store';
import { changeSectionForm } from '../../store/slices/sections';
import { MethodologySectionsTable } from './MethodologySectionsTable';
import { Modal } from '../../components/Modal';
import { MethodologySectionsChoose } from './MethodologySectionsChoose';
import { MdAdd, MdEdit } from 'react-icons/md';
import { methodologiesSelector } from '../../store/selectors/methodologies';
import { useModal } from '../../utils/hooks/useModal';
import { EditMethodologyModal } from './EditMethodologyModal';
import { MethodologyDocumentsTable } from './MethodologyDocumentsTable';
import { MethodologyInterviewsTable } from './MethodologyInterviewsTable';
import { Tabs } from '../../components/Tabs';

export function Methodology() {
  const {
    modalOpened: chooseSectionsModalOpened,
    closeModal: closeChooseSectionsModal,
    openModal: openChooseSectionsModal,
  } = useModal();
  const {
    modalOpened: editMethodologyModalOpened,
    closeModal: closeEditMethodologyModal,
    openModal: openEditMethodologyModal,
  } = useModal();
  const { methodologies } = useSelector(methodologiesSelector);
  const { methodologyId } = useParams();

  useEffect(() => {
    store.dispatch(changeSectionForm());
  }, []);

  const methodology = methodologies?.find((_) => _.id === methodologyId);

  const tabs = [
    {
      name: 'Рабочие таблицы',
      component: (
        <div>
          <Button className="mb-8" onClick={openChooseSectionsModal}>
            <div className="flex">
              <MdAdd size={24} className="mr-1" />
              <span>Добавить раздел</span>
            </div>
          </Button>
          <MethodologySectionsTable methodology={methodology} isEditable />
        </div>
      ),
    },
  ];

  if (!methodology?.isSelfRating) {
    tabs.push(
      {
        name: 'Документы',
        component: <MethodologyDocumentsTable methodology={methodology} />,
      },
      {
        name: 'Интервью',
        component: <MethodologyInterviewsTable methodology={methodology} />,
      },
    );
  }

  return (
    <div>
      <Heading>{methodology?.name}</Heading>
      <Button className="mb-8 mr-4" onClick={openEditMethodologyModal}>
        <div className="flex">
          <MdEdit size={24} className="mr-1" />
          <span>Редактировать методику</span>
        </div>
      </Button>
      <Table
        className="mb-14"
        cols={[
          'Дата создания',
          'Дата изменения',
          'Комментарий',
          'Отрасль компании',
          'Размер компании',
          'Последнее изменение',
        ]}
        rows={[
          [
            formatUnix(methodology?.createdOn),
            formatUnix(methodology?.changedOn),
            methodology?.comment,
            methodology?.companyIndustry,
            methodology?.companySize,
            <>
              {formatUnix(methodology?.changedOn)}, {methodology?.changedBy}
            </>,
          ],
        ]}
      />
      <Tabs tabs={tabs} />
      <Modal
        onClose={closeChooseSectionsModal}
        visible={chooseSectionsModalOpened}
        body={
          <MethodologySectionsChoose onClose={closeChooseSectionsModal} methodology={methodology} />
        }
      />
      <EditMethodologyModal
        methodology={methodology}
        modalOpened={editMethodologyModalOpened}
        onClose={closeEditMethodologyModal}
      />
    </div>
  );
}
