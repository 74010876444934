import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuditEntitiesSharedDocument } from '../../api/data-contracts';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { Table } from '../../components/Table';
import { documentsSelector } from '../../store/selectors/documents';
import { useSearch } from '../../utils/hooks';
import { formatUnix } from '../../utils/formatUnix';

const searchFilterFunction = (searchString: string, document: AuditEntitiesSharedDocument) => {
  const searchStringLowercase = searchString.toLowerCase();
  const nameLowercase = document.name?.toLowerCase() || '';
  const typeLowercase = document.type?.toLowerCase() || '';

  return (
    (nameLowercase.includes(searchStringLowercase) ||
      typeLowercase.includes(searchStringLowercase)) ??
    false
  );
};

export function DocumentsListTable() {
  const { documents } = useSelector(documentsSelector);

  const {
    filteredValue: filteredDocuments,
    searchInput,
    setSearchInput,
  } = useSearch(documents || [], searchFilterFunction);

  return (
    <div>
      <Input
        className="w-full"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder="Поиск по типу или названию"
        disabled={!documents?.length}
      />
      {filteredDocuments.length ? (
        <Table
          cols={['Тип', 'Название', 'URL', 'Дата создания', 'Комментарий']}
          rows={filteredDocuments.map((document) => {
            return [
              <Link
                className="text-decoration-line: underline text-link"
                to={`${document.id}/`}
                key={document.id}
              >
                {document.type}
              </Link>,
              document.name,
              document.url,
              formatUnix(document.uploadedOn),
              document.comment,
            ];
          })}
          indexColAlign="left"
        />
      ) : (
        <Heading transparent size="S">
          Документы отсутствуют
        </Heading>
      )}
    </div>
  );
}
