import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AuditEntitiesMethodology, AuditEntitiesSharedSection } from '../../api/data-contracts';
import { Table } from '../../components/Table';

type SectionTableProps = {
  section: AuditEntitiesSharedSection;
  methodologies?: AuditEntitiesMethodology[];
};

export const SectionTable: FC<SectionTableProps> = ({ section, methodologies }) => {
  const { projectId } = useParams();

  const cols = ['Название', 'Описание'];
  const rows: React.ReactNode[] = [section.name, section.description];

  if (!projectId) {
    cols.push('Используется в методиках');
    rows.push(
      methodologies?.length ? (
        methodologies.map((methodology, index) => (
          <React.Fragment key={methodology.id}>
            <Link
              to={`../methodology/${methodology.id}`}
              className="text-decoration-line: underline text-link"
            >
              {methodology.name}
            </Link>
            {index !== methodologies.length - 1 && <span>, </span>}
          </React.Fragment>
        ))
      ) : (
        <span className="italic text-secondary-text">Не используется в методиках</span>
      ),
    );
  }

  return <Table className="mb-8" cols={cols} rows={[rows]} />;
};
