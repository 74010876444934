import React, { useState } from 'react';
import { Heading } from '../../components/Heading';
import { TabProps, Tabs } from '../../components/Tabs/Tabs';
import { useGetRole } from '../../utils/hooks';
import { MethodologiesTable } from './MethodologiesTable';
import { ProjectsTable } from './ProjectsTable';
import { CompaniesTable } from './CompaniesTable';
import { UsersList } from './UsersList';

export function Home() {
  const { isSupervisor, isCompanyMember } = useGetRole();

  const tabs: TabProps[] = [
    {
      name: 'Текущие проекты',
      component: <ProjectsTable />,
    },
    {
      name: 'Завершённые проекты',
      component: (
        <Heading transparent size="S">
          Завершённые проекты отсутствуют
        </Heading>
      ),
    },
  ];

  if (isSupervisor) {
    tabs.unshift({ name: 'Методики', component: <MethodologiesTable /> });
    tabs.push({ name: 'Пользователи', component: <UsersList /> });
    tabs.push({ name: 'Компании', component: <CompaniesTable /> });
  }

  return (
    <div className="flex gap-14 flex-wrap flex-col">
      <Tabs tabs={tabs} />
    </div>
  );
}
