import React, { FC, ReactNode } from 'react';

type TooltipProps = {
  tooltipContent: ReactNode;
  children: ReactNode;
};

export const Tooltip: FC<TooltipProps> = ({ children, tooltipContent }) => {
  return (
    <>
      <div className="relative group w-fit">
        {children}
        <div className="z-20 invisible duration-300 opacity-0 absolute flex justify-center items-center px-4 py-2 -top-4 left-16 rounded-xl bg-semiDarkGray border-darkGray border-opacity-40 shadow-xl group-hover:visible group-hover:opacity-100">
          {tooltipContent}
        </div>
      </div>
    </>
  );
};
