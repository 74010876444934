import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AuditEntitiesCompany } from '../../api/data-contracts';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { companiesSelector } from '../../store/selectors/companies';
import { useSearch } from '../../utils/hooks';
import { MdDeleteOutline } from 'react-icons/md';

interface ProjectCreateFormCompaniesSearchProps {
  onChoose: (companyIds: string[]) => void;
  initialCompanyIds?: string[];
}

export function ProjectCreateFormCompaniesSearch({
  onChoose,
  initialCompanyIds,
}: ProjectCreateFormCompaniesSearchProps) {
  const { companies } = useSelector(companiesSelector);

  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [chosenCompanies, setChosenCompanies] = useState<AuditEntitiesCompany[]>(
    initialCompanyIds ? companies?.filter((_) => initialCompanyIds.includes(_.id || '')) || [] : [],
  );

  const toggleFocus = () => setInputFocus(!inputFocus);

  const searchFilterFunction = (searchString: string, company: AuditEntitiesCompany) => {
    const searchStringLowercase = searchString.toLowerCase();
    const nameLowercase = company.name?.toLowerCase() || '';

    return nameLowercase.includes(searchStringLowercase);
  };

  const {
    filteredValue: filteredCompanies,
    searchInput,
    setSearchInput,
  } = useSearch(
    companies?.filter((_) => !chosenCompanies.find((company) => company.id === _.id)) || [],
    searchFilterFunction,
  );

  const handleChoose = (company: AuditEntitiesCompany) => {
    const newCompanies = [...chosenCompanies, company];
    setChosenCompanies(newCompanies);
    onChoose(newCompanies.map((_) => _.id || ''));
  };

  const handleDelete = (company: AuditEntitiesCompany) => {
    const newCompanies = [...chosenCompanies];
    const deletedCompanyIndex = newCompanies.findIndex((_) => _.id === company.id);

    if (deletedCompanyIndex > -1) {
      newCompanies.splice(deletedCompanyIndex, 1);
      setChosenCompanies(newCompanies);
      onChoose(newCompanies.map((_) => _.id || ''));
    }
  };

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div className="relative">
      <Input
        title="Выбор компании"
        className="w-full"
        value={searchInput}
        onChange={handleSearch}
        onFocus={toggleFocus}
        onBlur={toggleFocus}
      />
      {inputFocus ? (
        <div className="w-full flex z-20 absolute flex-col items-start rounded-xl bg-semiDarkGray border-darkGray border-opacity-40 shadow-xl">
          {filteredCompanies?.length ? (
            filteredCompanies.map((company) => {
              return (
                <Button
                  key={company.id}
                  variant="no-bg"
                  scale="large"
                  className="text-main-text pl-6 rounded-xl"
                  onMouseDown={() => handleChoose(company)}
                >
                  {company.name}
                </Button>
              );
            })
          ) : (
            <div className="px-6 py-2">Не найдено компаний</div>
          )}
        </div>
      ) : null}
      {chosenCompanies?.length ? (
        <div className="-mt-4 mb-4 italic">
          Выбранные компании:
          {chosenCompanies.map((company) => (
            <div key={company.id} className="flex items-center">
              <div>{company.name}</div>
              <Button
                variant="no-bg"
                className="text-main-text ml-1"
                onClick={() => handleDelete(company)}
              >
                <MdDeleteOutline size={24} />
              </Button>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
