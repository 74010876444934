import React, { useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuditEntitiesSharedDocument, AuditEntitiesMethodology } from '../../api/data-contracts';
import { Button } from '../../components/Button';
import { EditDeleteButtons } from '../../components/EditDeleteButtons';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { SwapButtons } from '../../components/SwapButtons';
import { Table } from '../../components/Table';
import { store } from '../../store';
import { deleteDocumentById } from '../../store/requests/documents';
import { updateMethodology } from '../../store/requests/methodologies';
import { authSelector } from '../../store/selectors/auth';
import { documentsSelector } from '../../store/selectors/documents';
import { useSearch } from '../../utils/hooks';
import { useModal } from '../../utils/hooks/useModal';
import { EditDocumentModal } from '../Documents/EditDocumentModal';

interface MethodologyDocumentsTableProps {
  methodology?: AuditEntitiesMethodology;
}

const searchFilterFunction = (searchString: string, document: AuditEntitiesSharedDocument) => {
  return (
    document.type?.toLowerCase().includes(searchString?.toLowerCase()) ||
    document.name?.toLowerCase().includes(searchString?.toLowerCase()) ||
    false
  );
};

export function MethodologyDocumentsTable({ methodology }: MethodologyDocumentsTableProps) {
  const { token } = useSelector(authSelector);
  const { documents } = useSelector(documentsSelector);

  const { modalOpened, openModal, closeModal } = useModal();

  const [selectedDocument, setSelectedDocument] = useState<AuditEntitiesSharedDocument | null>(
    null,
  );

  const handleDeleteDocument = (id: string) => {
    store.dispatch(deleteDocumentById({ id, token }));
  };

  const handleEditDocument = (document?: AuditEntitiesSharedDocument) => {
    if (!document) return;
    setSelectedDocument(document);
    openModal();
  };

  const handleCloseModal = () => {
    setSelectedDocument(null);
    closeModal();
  };

  const onSwapDocuments = (from: number, to: number) => {
    if (methodology) {
      handleSwapDocuments(from, to, methodology, token);
    }
  };

  const docs: AuditEntitiesSharedDocument[] = [];
  methodology?.documents?.forEach((docId) => {
    const doc = documents?.find((document) => document.id === docId);
    if (doc) docs.push(doc);
  });

  const {
    filteredValue: filteredDocuments,
    searchInput,
    setSearchInput,
  } = useSearch(docs, searchFilterFunction);

  return (
    <div>
      <Button className="mb-8" onClick={openModal}>
        <div className="flex">
          <MdAdd size={24} className="mr-1" />
          <span>Добавить документ</span>
        </div>
      </Button>
      <Input
        className="w-full"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder="Поиск по названию"
        disabled={!documents?.length}
      />
      {filteredDocuments?.length ? (
        <Table
          cols={['', 'Тип документа', '']}
          rows={filteredDocuments.map((document, index) => {
            if (!document) return [];
            return [
              <SwapButtons
                key="swap"
                index={index}
                totalLength={filteredDocuments.length ?? 0}
                onSwap={onSwapDocuments}
              />,
              <Link
                key={document.id}
                to={`document/${document.id}`}
                className="text-decoration-line: underline text-link"
              >
                {document?.type ?? ''}
              </Link>,
              <EditDeleteButtons
                key="edit-delete"
                onEditClick={() => handleEditDocument(document)}
                onDeleteClick={() => handleDeleteDocument(document.id || '')}
              />,
            ];
          })}
        />
      ) : (
        <Heading size="S" transparent>
          В методике отсутствуют документы
        </Heading>
      )}
      <EditDocumentModal
        parentId={methodology?.id ?? undefined}
        modalOpened={modalOpened}
        onClose={handleCloseModal}
        document={selectedDocument ?? undefined}
      />
    </div>
  );
}

const handleSwapDocuments = (
  from: number,
  to: number,
  methodology: AuditEntitiesMethodology,
  token?: string | null,
  // eslint-disable-next-line max-params
) => {
  if (!methodology.documents) return;

  const documents = [...methodology.documents];

  const temp = documents[from];
  documents[from] = documents[to];
  documents[to] = temp;

  store.dispatch(
    updateMethodology({
      id: methodology.id ?? '',
      token,
      data: { newVersion: false, methodology: { ...methodology, documents } },
    }),
  );
};
