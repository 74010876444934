import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuditEntitiesProject } from '../../api/data-contracts';
import { Table } from '../../components/Table';
import { methodologiesSelector } from '../../store/selectors/methodologies';
import { userSelector } from '../../store/selectors/user';
import { buildName } from '../../utils/buildName';
import { formatUnix } from '../../utils/formatUnix';

type ProjectTableProps = {
  project?: AuditEntitiesProject;
};

export const ProjectTable: FC<ProjectTableProps> = ({ project }) => {
  const { methodologies } = useSelector(methodologiesSelector);
  const { usersList } = useSelector(userSelector);

  const creator = usersList?.find((_) => _.id === project?.createdBy);
  const creatorName = creator ? buildName(creator) : '';

  const methodology = methodologies?.find((_) => _.id === project?.methodologyId);

  return project ? (
    <Table
      className="mb-8"
      cols={[
        'Автор проекта',
        'Методика',
        'Дата начала',
        'Последнее изменение',
        'Дата завершения',
        'Степень выполнения',
        'Аудиторы',
        'Комментарий',
      ]}
      rows={[
        [
          creatorName,
          <Link
            key={project.methodologyId}
            to={`../methodology/${project.methodologyId}`}
            className="text-decoration-line: underline text-link"
          >
            {methodology?.name}
          </Link>,
          formatUnix(project.createdOn),
          <>
            {formatUnix(project.changedOn)}, {project.changedBy}
          </>,
          formatUnix(project.finishedOn),
          `${project.progress || 0}%`,
          usersList
            ?.filter((user) => user.id && project.auditors?.includes(user.id))
            .map((item) => buildName(item))
            .join(', '),
          project.comment,
        ],
      ]}
    />
  ) : (
    <span className="italic text-secondary-text">Что-то пошло не так</span>
  );
};
