import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AuditEntitiesSharedComponent,
  AuditEntitiesSharedIndicator,
} from '../../api/data-contracts';
import { getIndicators, getIndicatorsList } from '../requests/indicators';

interface IndicatorsSliceProps {
  indicators?: AuditEntitiesSharedIndicator[];
  indicatorForm?: AuditEntitiesSharedIndicator;
  componentForm?: AuditEntitiesSharedComponent;
}

const initialState: IndicatorsSliceProps = {
  indicators: [],
};

export const indicatorsSlice = createSlice({
  name: 'indicators',
  initialState,
  reducers: {
    dropIndicators: () => initialState,
    changeIndicatorForm(state, { payload }: PayloadAction<IndicatorsSliceProps['indicatorForm']>) {
      state.indicatorForm = payload;
    },
    dropIndicatorForm(state) {
      state.indicatorForm = undefined;
    },
    changeComponentForm(state, { payload }: PayloadAction<IndicatorsSliceProps['componentForm']>) {
      state.componentForm = payload;
    },
    dropComponentForm(state) {
      state.componentForm = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIndicators.fulfilled, (state, { payload }) => {
      state.indicators = payload;
    });
    builder.addCase(getIndicatorsList.fulfilled, (state, { payload }) => {
      const newArr = state.indicators || [];
      payload.forEach((item) => {
        if (!newArr?.find((_) => _.id === item.id)) {
          newArr?.push(item as AuditEntitiesSharedIndicator);
        }
      });
      state.indicators = newArr;
    });
  },
});

export const indicatorsReducer = indicatorsSlice.reducer;
export const {
  dropIndicators,
  changeIndicatorForm,
  dropIndicatorForm,
  changeComponentForm,
  dropComponentForm,
} = indicatorsSlice.actions;
