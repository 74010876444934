import { getMethodologiesList } from './../requests/methodologies';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuditEntitiesMethodology } from '../../api/data-contracts';
import { getAllMethodologies } from '../requests/methodologies';

interface MethodologiesSliceProps {
  methodologies?: AuditEntitiesMethodology[];
  methodologyForm?: AuditEntitiesMethodology;
}

const initialState: MethodologiesSliceProps = {
  methodologies: [],
};

export const methodologiesSlice = createSlice({
  name: 'methodologies',
  initialState,
  reducers: {
    dropMethodologies: () => initialState,
    changeMethodologyForm(
      state,
      { payload }: PayloadAction<MethodologiesSliceProps['methodologyForm']>,
    ) {
      state.methodologyForm = payload;
    },
    dropMethodologyForm(state) {
      state.methodologyForm = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMethodologies.fulfilled, (state, { payload }) => {
      state.methodologies = payload;
    });
    builder.addCase(getMethodologiesList.fulfilled, (state, { payload }) => {
      const newArr = state.methodologies || [];
      payload.forEach((item) => {
        if (!newArr?.find((_) => _.id === item.id)) {
          newArr?.push(item as AuditEntitiesMethodology);
        }
      });
      state.methodologies = newArr;
    });
  },
});

export const methodologiesReducer = methodologiesSlice.reducer;
export const { dropMethodologies, changeMethodologyForm, dropMethodologyForm } =
  methodologiesSlice.actions;
