import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuditEntitiesSharedIndicator } from '../../api/data-contracts';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { Table } from '../../components/Table';
import { Tooltip } from '../../components/Tooltip';
import { indicatorsSelector } from '../../store/selectors/indicators';
import { sectionsSelector } from '../../store/selectors/sections';
import { useSearch } from '../../utils/hooks';
import { pluralize } from '../../utils/pluralize';
import { IndicatorTooltipComponentsTable } from './IndicatorTooltipComponentsTable';

const searchFilterFunction = (searchString: string, indicator: AuditEntitiesSharedIndicator) => {
  return indicator.name?.includes(searchString) ?? false;
};

export function IndicatorsListTable() {
  const { indicators } = useSelector(indicatorsSelector);
  const { sections } = useSelector(sectionsSelector);

  const {
    filteredValue: filteredIndicators,
    searchInput,
    setSearchInput,
  } = useSearch(indicators || [], searchFilterFunction);

  return (
    <div>
      <Input
        className="w-full"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder="Поиск по названию"
        disabled={!indicators?.length}
      />
      {filteredIndicators.length ? (
        <Table
          cols={['Название', 'Вес', 'Компоненты', 'В каких разделах используется']}
          rows={filteredIndicators.map((indicator) => {
            const usedInSections = sections?.filter((section) =>
              section.indicators?.some((indicatorId) => indicatorId === indicator.id),
            );

            return [
              <Link
                className="text-decoration-line: underline text-link"
                to={`${indicator.id}/`}
                key={indicator.id}
              >
                {indicator.name}
              </Link>,
              indicator.weight,
              indicator.components?.length ? (
                <Tooltip tooltipContent={<IndicatorTooltipComponentsTable indicator={indicator} />}>
                  <span className="font-bold cursor-pointer">
                    Есть {indicator.components.length}{' '}
                    {pluralize(indicator.components.length, [
                      'компонент',
                      'компонента',
                      'компонентов',
                    ])}
                  </span>
                </Tooltip>
              ) : (
                <span className="italic text-secondary-text">Ещё нет компонентов</span>
              ),
              usedInSections?.length ? (
                usedInSections.map((section, index) => (
                  <React.Fragment key={section.id}>
                    <Link
                      to={`../sections/${section.id}`}
                      className="text-decoration-line: underline text-link"
                    >
                      {section.name}
                    </Link>
                    {index !== usedInSections.length - 1 && <span>, </span>}
                  </React.Fragment>
                ))
              ) : (
                <span className="italic text-secondary-text">Не используется в разделах</span>
              ),
            ];
          })}
          indexColAlign="left"
        />
      ) : (
        <Heading size="S" transparent>
          Показатели отсутствуют
        </Heading>
      )}
    </div>
  );
}
