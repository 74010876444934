import React, { ReactNode, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { store } from '../../store';
import { userSelector } from '../../store/selectors/user';
import { setActiveTab } from '../../store/slices/user';
import { Button } from '../Button';

export interface TabProps {
  name: string;
  component: ReactNode;
}

interface TabsProps {
  tabs: TabProps[];
}

export function Tabs({ tabs }: TabsProps) {
  const [activeLocalTab, setActiveLocalTab] = useState<string>('');
  const location = useLocation();
  const { mainPageActiveTab } = useSelector(userSelector);

  const isMainPage = location.pathname === '/';

  const handleChangeTab = (tab: TabProps) => {
    if (isMainPage) {
      store.dispatch(setActiveTab(tab.name));
    } else {
      setActiveLocalTab(tab.name);
    }
  };

  const getActiveTab = () => {
    if (isMainPage) {
      return tabs.find((_) => _.name === mainPageActiveTab);
    } else {
      return tabs.find((_) => _.name === activeLocalTab);
    }
  };

  useEffect(() => {
    if ((isMainPage && !mainPageActiveTab) || !isMainPage) {
      handleChangeTab(tabs[0]);
    }
  }, []);

  const currentTab = getActiveTab();

  return (
    <div className="flex flex-col">
      <div className="flex w-full items-center mb-12">
        {tabs.map((tab) => (
          <div
            key={tab.name}
            className={currentTab?.name === tab.name ? 'border-b-2 border-b-main mr-4' : 'mr-4'}
          >
            <Button
              onClick={() => handleChangeTab(tab)}
              variant="no-bg"
              className={`text-main text-xl pl-6 ${
                !(currentTab?.name === tab.name) ? 'opacity-50' : ''
              }`}
            >
              {tab.name}
            </Button>
          </div>
        ))}
      </div>
      {currentTab?.component}
    </div>
  );
}
