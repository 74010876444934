import { AuditEntitiesAccountAccount } from '../../api/data-contracts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAccountDetail, getAccountsList, getAllAccounts } from '../requests/user';

export interface ProfileFormProps {
  firstName?: AuditEntitiesAccountAccount['firstName'];
  lastName?: AuditEntitiesAccountAccount['lastName'];
  patronymic?: AuditEntitiesAccountAccount['patronymic'];
  email?: AuditEntitiesAccountAccount['email'];
  phoneNumber?: AuditEntitiesAccountAccount['phoneNumber'];
  organization?: AuditEntitiesAccountAccount['organization'];
  companyIds?: AuditEntitiesAccountAccount['companyIds'];
}

interface SetConfigDataProps {
  name: keyof ProfileFormProps;
  value: string;
}

export interface UserSliceProps {
  userData?: AuditEntitiesAccountAccount;
  profileForm: ProfileFormProps;
  usersList?: AuditEntitiesAccountAccount[];
  mainPageActiveTab?: string;
}

const initialState: UserSliceProps = {
  profileForm: {},
  usersList: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    dropUser: () => initialState,
    setConfigData(state, { payload: { name, value } }: PayloadAction<SetConfigDataProps>) {
      if (state.profileForm && name !== 'companyIds') {
        state.profileForm[name] = value;
      }
    },
    changeCompanyIds(state, { payload }: PayloadAction<AuditEntitiesAccountAccount['companyIds']>) {
      state.profileForm.companyIds = payload;
    },
    setActiveTab(state, { payload }: PayloadAction<string>) {
      state.mainPageActiveTab = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountDetail.fulfilled, (state, { payload }) => {
      state.userData = payload;
      state.profileForm = payload;
    });
    builder.addCase(getAllAccounts.fulfilled, (state, { payload }) => {
      state.usersList = payload;
    });
    builder.addCase(getAccountsList.fulfilled, (state, { payload }) => {
      const newArr = state.usersList || [];
      payload.forEach((item) => {
        if (!newArr?.find((_) => _.id === item.id)) {
          newArr?.push(item as AuditEntitiesAccountAccount);
        }
      });
      state.usersList = newArr;
    });
  },
});

export const userReducer = userSlice.reducer;
export const { dropUser, setConfigData, changeCompanyIds, setActiveTab } = userSlice.actions;
