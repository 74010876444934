import React from 'react';
import { CommonElementsProps } from '../types';

type TextareaProps = JSX.IntrinsicElements['textarea'] &
  CommonElementsProps & {
    title?: string;
  };

export function Textarea({ scale = 'medium', title, className = '', ...rest }: TextareaProps) {
  return (
    <div className="text-primary-text">
      {title ? <div className="select-none text-secondary-text">{title}</div> : null}
      <textarea className={`${getTextareaStyles(scale)} ${className}`} {...rest} />
    </div>
  );
}

function getTextareaStyles(scale: TextareaProps['scale']) {
  let styles =
    'duration-300 rounded focus-visible:outline-auto resize-none border border-darkGray border-opacity-40 ';

  switch (scale) {
    case 'small':
      styles += 'px-2 py-1.5 text-sm';
      break;
    case 'medium':
      styles += 'px-4 py-2.5 text-base';
      break;
    case 'large':
      styles += 'px-6 py-3.5 text-lg font-semibold';
      break;
  }

  return styles;
}
