import { TokenRequest, ActionWithEntityById, ActionWithEntityByIdsArray } from './../types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Methodologies } from '../../api/Methodologies';
import { AuditEntitiesSharedInterview } from '../../api/data-contracts';
import { logout } from '../../utils/logout';
import { getAllMethodologies } from './methodologies';
import { ApiUrl } from './url';

const methodologiesApi = new Methodologies({
  baseUrl: ApiUrl,
  customFetch: (...fetchParams: Parameters<typeof fetch>) => {
    return fetch(...fetchParams).then((res) => {
      const status = res?.status;
      if (status === 401) logout();

      return res;
    });
  },
});

interface CreateOrUpdateInterviewRequest extends TokenRequest {
  data: AuditEntitiesSharedInterview;
}

export const getInterviews = createAsyncThunk(
  'interviews/getInterviews',
  async ({ token }: TokenRequest) => {
    const res = await methodologiesApi.getInterviewsList({
      headers: { Authorization: token || '' },
    });
    return res.data;
  },
);

export const getInterviewById = createAsyncThunk(
  'interviews/getInterviewById',
  async ({ token, id }: ActionWithEntityById) => {
    const res = await methodologiesApi.getInterviewDetail(id, {
      headers: { Authorization: token || '' },
    });
    return res.data as AuditEntitiesSharedInterview;
  },
);

export const updateInterviewById = createAsyncThunk(
  'interviews/updateInterviewById',
  async ({ token, data }: CreateOrUpdateInterviewRequest, { dispatch }) => {
    const res = await methodologiesApi.updateInterviewPartialUpdate(data, {
      headers: { Authorization: token || '' },
    });

    dispatch(getInterviewById({ id: data.id ?? '', token }));
    dispatch(getInterviews({ token }));
    return res.data;
  },
);

export const deleteInterviewById = createAsyncThunk(
  'interviews/deleteInterviewById',
  async ({ token, id }: ActionWithEntityById, { dispatch }) => {
    const res = await methodologiesApi.removeInterviewDelete(id, {
      headers: { Authorization: token || '' },
    });

    dispatch(getInterviews({ token }));
    dispatch(getAllMethodologies({ token }));

    return res.data;
  },
);

export const createInterview = createAsyncThunk(
  'interviews/createInterview',
  async ({ token, data }: CreateOrUpdateInterviewRequest, { dispatch }) => {
    const res = await methodologiesApi.createInterviewCreate(data, {
      headers: { Authorization: token || '' },
    });

    dispatch(getInterviews({ token }));
    dispatch(getAllMethodologies({ token }));

    return res.data;
  },
);

export const getInterviewsList = createAsyncThunk(
  'interviews/getInterviewsList',
  async ({ token, ids }: ActionWithEntityByIdsArray) => {
    const res = await methodologiesApi.getInterviewsListList(
      { ids },
      {
        headers: { Authorization: token || '' },
      },
    );
    return res.data;
  },
);
