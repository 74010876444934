import { getInterviewsList } from './../requests/interviews';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AuditEntitiesSharedInterview,
  AuditEntitiesSharedInterviewSubject,
} from '../../api/data-contracts';
import { getInterviews } from '../requests/interviews';

interface InterviewsSliceProps {
  interviews?: AuditEntitiesSharedInterview[];
  interviewForm?: AuditEntitiesSharedInterview;
  interviewSubjectForm?: AuditEntitiesSharedInterviewSubject;
}

const initialState: InterviewsSliceProps = {
  interviews: [],
};

export const interviewsSlice = createSlice({
  name: 'interviews',
  initialState,
  reducers: {
    dropInterviews: () => initialState,
    changeInterviewForm(state, { payload }: PayloadAction<InterviewsSliceProps['interviewForm']>) {
      state.interviewForm = payload;
    },
    dropInterviewForm(state) {
      state.interviewForm = undefined;
    },
    changeInterviewSubjectForm(
      state,
      { payload }: PayloadAction<InterviewsSliceProps['interviewSubjectForm']>,
    ) {
      state.interviewSubjectForm = payload;
    },
    dropInterviewSubjectForm(state) {
      state.interviewSubjectForm = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInterviews.fulfilled, (state, { payload }) => {
      state.interviews = payload;
    });
    builder.addCase(getInterviewsList.fulfilled, (state, { payload }) => {
      const newArr = state.interviews || [];
      payload.forEach((item) => {
        if (!newArr?.find((_) => _.id === item.id)) {
          newArr?.push(item as AuditEntitiesSharedInterview);
        }
      });
      state.interviews = newArr;
    });
  },
});

export const interviewsReducer = interviewsSlice.reducer;
export const {
  dropInterviews,
  changeInterviewForm,
  dropInterviewForm,
  changeInterviewSubjectForm,
  dropInterviewSubjectForm,
} = interviewsSlice.actions;
