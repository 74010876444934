import React from 'react';
import { useSelector } from 'react-redux';
import { AuditEntitiesSharedDocument, AuditEntitiesProject } from '../../api/data-contracts';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { Table } from '../../components/Table';
import { store } from '../../store';
import { downloadDocument } from '../../store/requests/projects';
import { authSelector } from '../../store/selectors/auth';
import { documentsSelector } from '../../store/selectors/documents';
import { useSearch } from '../../utils/hooks';
import { ProjectUploadDocument } from './ProjectUploadDocument';

interface ProjectDocumentsTableProps {
  project?: AuditEntitiesProject;
}

const searchFilterFunction = (searchString: string, document: AuditEntitiesSharedDocument) => {
  return (
    document.type?.toLowerCase().includes(searchString?.toLowerCase()) ||
    document.name?.toLowerCase().includes(searchString?.toLowerCase()) ||
    false
  );
};

export function ProjectDocumentsTable({ project }: ProjectDocumentsTableProps) {
  const { token } = useSelector(authSelector);
  const { documents } = useSelector(documentsSelector);

  const handleDownload = (document: AuditEntitiesSharedDocument) => () => {
    if (!document.id || !document.fileName) return;
    store.dispatch(downloadDocument({ token, id: document.id, fileName: document.fileName }));
  };

  const {
    filteredValue: filteredDocuments,
    searchInput,
    setSearchInput,
  } = useSearch(
    documents?.filter((document) => document.parentId === project?.id) || [],
    searchFilterFunction,
  );

  return (
    <div>
      <Input
        className="w-full"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder="Поиск по названию"
        disabled={!documents?.length}
      />
      {filteredDocuments?.length ? (
        <Table
          cols={['Тип документа', 'Документ', 'Загрузка документа']}
          rows={filteredDocuments.map((_, index) => [
            _.type,
            <Button
              key={'doc' + index}
              variant="no-bg"
              className="text-link"
              onClick={handleDownload(_)}
            >
              {_.fileName || ''}
            </Button>,
            <ProjectUploadDocument key={index} document={_} />,
          ])}
        />
      ) : (
        <Heading size="S" transparent>
          В проекте отсутствуют документы
        </Heading>
      )}
    </div>
  );
}
