import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Heading } from '../../components/Heading';
import { Button } from '../../components/Button';
import { authSelector } from '../../store/selectors/auth';
import { Modal } from '../../components/Modal';
import {
  AuditEntitiesSharedInterview,
  AuditEntitiesSharedQuestionModel,
} from '../../api/data-contracts';
import { updateInterviewById } from '../../store/requests/interviews';
import { store } from '../../store';
import { createHash } from '../../utils/createHash';
import { useParams } from 'react-router-dom';
import { Textarea } from '../../components/Textarea';

interface EditQuestionModalProps {
  interview: AuditEntitiesSharedInterview;
  question?: AuditEntitiesSharedQuestionModel;
  modalOpened: boolean;
  onClose: () => void;
}

type FormData = {
  question: string;
  answer: string;
};

export function EditQuestionModal({
  interview,
  modalOpened,
  onClose,
  question,
}: EditQuestionModalProps) {
  const { projectId } = useParams();
  const [formData, setFormData] = useState<FormData>({ question: '', answer: '' });
  const { token } = useSelector(authSelector);

  useEffect(() => {
    if (question) {
      setFormData({ answer: question.answer ?? '', question: question.question ?? '' });
    }
  }, [question]);

  const handleChangeForm: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = () => {
    if (question) {
      const questions = (interview.questions ?? []).map((q) =>
        question.id === q.id ? { ...q, question: formData.question, answer: formData.answer } : q,
      );
      store.dispatch(
        updateInterviewById({
          data: {
            ...interview,
            questions,
          },
          token,
        }),
      );
    } else {
      store.dispatch(
        updateInterviewById({
          data: {
            ...interview,
            questions: [
              ...(interview.questions ?? []),
              {
                id: createHash(),
                question: formData.question,
                answer: formData.answer,
              },
            ],
          },
          token,
        }),
      );
    }
    setFormData({ answer: '', question: '' });
    onClose();
  };

  const questionMessage = question ? 'Редактирование вопроса' : 'Создание вопроса';
  const questionAction = question ? 'Обновить' : 'Создать';
  const headingMessage = projectId ? 'Ответ на вопрос' : questionMessage;
  const actionMessage = projectId ? 'Добавить ответ' : questionAction;

  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        <div>
          <Heading className="mb-4" size="S">
            {headingMessage}
          </Heading>
          {projectId ? (
            <Textarea
              title="Ответ"
              onChange={handleChangeForm}
              name="answer"
              value={formData.answer}
              className="h-[300px] w-full mb-4"
            />
          ) : (
            <Textarea
              title="Вопрос"
              onChange={handleChangeForm}
              name="question"
              value={formData.question}
              className="w-full h-[300px]"
            />
          )}
          <div className="flex justify-end gap-2">
            <Button onClick={onClose}>Отменить</Button>
            <Button
              onClick={handleSubmit}
              disabled={projectId ? !formData.answer : !formData.question}
            >
              {actionMessage}
            </Button>
          </div>
        </div>
      }
    />
  );
}
