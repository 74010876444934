import React from 'react';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { FullscreenLoader } from '../../components/FullscreenLoader';
import { Heading } from '../../components/Heading';
import { store } from '../../store';
import { authSelector } from '../../store/selectors/auth';
import { useModal } from '../../utils/hooks/useModal';
import { EditInterviewModal } from './EditInterviewModal';
import { InterviewTable } from './InterviewTable';
import { interviewsSelector } from '../../store/selectors/interviews';
import { deleteInterviewById } from '../../store/requests/interviews';
import { InterviewQuestionsTable } from './InterviewQuestionsTable';
import { EditQuestionModal } from './EditQuestionModal';

export function Interview() {
  const { interviewId = '', projectId } = useParams();

  const { token } = useSelector(authSelector);
  const { interviews } = useSelector(interviewsSelector);

  const currentInterview = interviews?.find((_) => _.id === interviewId);

  const navigate = useNavigate();

  const {
    modalOpened: editModalOpened,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();

  const {
    modalOpened: questionModalOpened,
    closeModal: closeQuestionModal,
    openModal: openQuestionModal,
  } = useModal();

  const handleDeleteInterview = async () => {
    await store.dispatch(deleteInterviewById({ id: interviewId, token }));
    navigate(-1);
  };

  return currentInterview ? (
    <>
      <Heading>Интервью &#34;{currentInterview.subject?.position ?? ''}&#34;</Heading>
      {!projectId && (
        <>
          <Button className="mb-8 mr-4" onClick={openEditModal}>
            <div className="flex">
              <MdEdit size={24} className="mr-1" />
              <span>Редактировать интервью</span>
            </div>
          </Button>
          <Button className="mb-8" onClick={handleDeleteInterview}>
            <div className="flex">
              <MdDelete size={24} className="mr-1" />
              <span>Удалить интервью</span>
            </div>
          </Button>
        </>
      )}
      <InterviewTable interview={currentInterview} />
      <div className="text-main-text font-semibold text-2xl mb-4">Вопросы в интервью</div>
      {!projectId && (
        <Button className="mb-8" onClick={openQuestionModal}>
          <div className="flex">
            <MdAdd size={24} className="mr-1" />
            <span>Добавить вопрос</span>
          </div>
        </Button>
      )}
      <InterviewQuestionsTable interview={currentInterview} />
      <EditInterviewModal
        interview={currentInterview}
        modalOpened={editModalOpened}
        onClose={closeEditModal}
      />
      <EditQuestionModal
        interview={currentInterview}
        modalOpened={questionModalOpened}
        onClose={closeQuestionModal}
      />
    </>
  ) : (
    <FullscreenLoader />
  );
}
