import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuditEntitiesCompany } from '../../api/data-contracts';
import { getAllCompanies } from '../requests/companies';

interface CompaniesSliceProps {
  companies?: AuditEntitiesCompany[];
  companyForm?: AuditEntitiesCompany;
}

const initialState: CompaniesSliceProps = {
  companies: [],
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    dropCompanies: () => initialState,
    changeCompanyForm(state, { payload }: PayloadAction<CompaniesSliceProps['companyForm']>) {
      state.companyForm = payload;
    },
    dropCompanyForm(state) {
      state.companyForm = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCompanies.fulfilled, (state, { payload }) => {
      state.companies = payload;
    });
  },
});

export const companiesReducer = companiesSlice.reducer;
export const { dropCompanies, changeCompanyForm, dropCompanyForm } = companiesSlice.actions;
