import React from 'react';
import { MdAdd } from 'react-icons/md';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { useModal } from '../../utils/hooks/useModal';
import { DocumentsListTable } from './DocumentsListTable';
import { EditDocumentModal } from './EditDocumentModal';

export function DocumentsList() {
  const { modalOpened, openModal, closeModal } = useModal();

  return (
    <>
      <Heading className="mb-8">Документы</Heading>
      <Button className="mb-8" onClick={openModal}>
        <div className="flex">
          <MdAdd size={24} className="mr-1" />
          <span>Добавить документ</span>
        </div>
      </Button>
      <DocumentsListTable />
      <EditDocumentModal modalOpened={modalOpened} onClose={closeModal} />
    </>
  );
}
