import { useMemo, useState } from 'react';
import { useDebounce } from './useDebounce';

export const useSearch = <T>(
  valueArray: T[],
  filterFn: (searchString: string, element: T) => boolean,
  defaultInput = '',
) => {
  const [searchInput, setSearchInput] = useState(defaultInput);

  const debouncedSearchInput = useDebounce(searchInput);

  const filteredValue = useMemo(
    () => valueArray?.filter((element) => filterFn(debouncedSearchInput, element)),
    [debouncedSearchInput, valueArray, filterFn],
  );

  return { searchInput, setSearchInput, filteredValue };
};
