import {
  AuditEntitiesSharedComponent,
  AuditEntitiesSharedDocument,
} from '../../api/data-contracts';
import { QuestionModel } from './IndicatorComponentsTable';

export const getComponentDocuments = (
  component: AuditEntitiesSharedComponent,
  allDocuments: AuditEntitiesSharedDocument[],
) => {
  const componentDocumentIds = (component.source ?? [])
    .map((source) => source.documentId)
    .filter((id) => Boolean(id));

  const componentDocuments = allDocuments.filter((document) =>
    componentDocumentIds.some((id) => id === document.id),
  );

  const uniqueComponentDocuments: AuditEntitiesSharedDocument[] = [];
  componentDocuments.forEach((item) => {
    if (!uniqueComponentDocuments.find((elem) => elem.id === item.id)) {
      uniqueComponentDocuments.push(item);
    }
  });

  const componentDocumentsWithComments = uniqueComponentDocuments.map((document) => {
    const sourceItem = (component.source ?? []).find((source) => source.documentId === document.id);
    return {
      ...document,
      sourceComment: sourceItem?.comment,
    };
  });
  return componentDocumentsWithComments;
};

export const getComponentQuestions = (
  component: AuditEntitiesSharedComponent,
  allQuestions: (QuestionModel & { interviewId?: string | null })[],
) => {
  const componentQuestionIds = (component.source ?? [])
    .map((source) => source.questionId)
    .filter((id) => Boolean(id));
  const componentQuestions = allQuestions.filter((question) =>
    componentQuestionIds.some((id) => id === question.id),
  );

  const uniqueComponentQuestions: (QuestionModel & {
    interviewId?: string | null | undefined;
  })[] = [];

  componentQuestions.forEach((item) => {
    if (!uniqueComponentQuestions.find((elem) => elem.id === item.id)) {
      uniqueComponentQuestions.push(item);
    }
  });

  const componentQuestionsWithComments = uniqueComponentQuestions.map((question) => {
    const sourceItem = (component.source ?? []).find((source) => source.questionId === question.id);
    return {
      ...question,
      sourceComment: sourceItem?.comment,
    };
  });

  return componentQuestionsWithComments;
};
