import React, { useState, useEffect } from 'react';
import { MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { AuditEntitiesMethodology } from '../../api/data-contracts';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { store } from '../../store';
import { createProject } from '../../store/requests/projects';
import { authSelector } from '../../store/selectors/auth';
import { ProjectCreateFormAuditorsSearch } from './ProjectCreateFormAuditorsSearch';
import { ProjectCreateFormMethodologySearch } from './ProjectCreateFormMethodologySearch';
import { ProjectCreateFormCompaniesSearch } from './ProjectCreateFormCompaniesSearch';
import { useGetRole } from '../../utils/hooks';
import { userSelector } from '../../store/selectors/user';

interface ProjectCreateFormProps {
  onClose?: () => void;
}

export function ProjectCreateForm({ onClose }: ProjectCreateFormProps) {
  const { token, userId } = useSelector(authSelector);
  const { userData } = useSelector(userSelector);
  const { isCompanyMember } = useGetRole();

  useEffect(() => {
    if (isCompanyMember) {
      handleChangeAuditors([userId || '']);
      handleChangeCompanies(userData?.companyIds || []);
    }
  }, [isCompanyMember, userId, userData]);

  const [chosenMethodology, setChosenMethodology] = useState<
    AuditEntitiesMethodology | undefined
  >();
  const [chosenAuditors, setChosenAuditors] = useState<string[]>([]);
  const [chosenCompanies, setChosenCompanies] = useState<string[]>([]);
  const [name, setName] = useState<string>();

  const handleChooseMethodology = (methodology?: AuditEntitiesMethodology) => {
    if (!methodology) return;
    setChosenMethodology(methodology);
  };

  const handleChangeAuditors = (auditors: string[]) => {
    setChosenAuditors(auditors);
  };

  const handleChangeCompanies = (companyIds: string[] | undefined) => {
    companyIds && setChosenCompanies(companyIds);
  };

  const handleChangeName: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
  };

  const handleCreateProject = () => {
    if (userId && name) {
      store.dispatch(
        createProject({
          token,
          userId,
          data: {
            methodologyId: chosenMethodology?.id || '',
            name,
            createdBy: userId,
            auditors: chosenAuditors,
            companyIds: chosenCompanies,
          },
        }),
      );
      onClose && onClose();
    }
  };

  return (
    <div className="relative">
      <Input title="Название проекта" className="w-full" value={name} onChange={handleChangeName} />
      {!chosenMethodology && (
        <ProjectCreateFormMethodologySearch
          onChoose={handleChooseMethodology}
          onlySelfRating={isCompanyMember}
        />
      )}
      {chosenMethodology && (
        <div className="text-primary-text mb-4 italic flex items-center">
          Выбранная методика - {chosenMethodology.name}
          <Button
            variant="no-bg"
            className="ml-4 text-main-text"
            onClick={() => setChosenMethodology(undefined)}
          >
            <MdEdit size={24} />
          </Button>
        </div>
      )}
      {isCompanyMember ? null : <ProjectCreateFormAuditorsSearch onChange={handleChangeAuditors} />}
      {isCompanyMember ? null : (
        <ProjectCreateFormCompaniesSearch onChoose={handleChangeCompanies} />
      )}
      <div className="flex gap-x-2 justify-end">
        <Button version="secondary" onClick={onClose}>
          Отменить
        </Button>
        <Button
          version="secondary"
          onClick={handleCreateProject}
          disabled={!name || !chosenMethodology || !chosenAuditors.length}
        >
          Подтвердить
        </Button>
      </div>
    </div>
  );
}
