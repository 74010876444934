import React, { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { AuditEntitiesMethodology } from '../../api/data-contracts';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { store } from '../../store';
import { cloneMethodology } from '../../store/requests/methodologies';
import { authSelector } from '../../store/selectors/auth';
import { ProjectCreateFormMethodologySearch } from '../Project/ProjectCreateFormMethodologySearch';

interface MethodologyCloneFormProps {
  onClose?: () => void;
}

export function MethodologyCloneForm({ onClose }: MethodologyCloneFormProps) {
  const { token } = useSelector(authSelector);

  const [isSelfRating, setIsSelfRating] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [methodology, setMethodology] = useState<AuditEntitiesMethodology>();

  const handleChangeName: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
  };

  const handleChooseMethodology = (methodology?: AuditEntitiesMethodology) => {
    setMethodology(methodology);
  };

  const handleCloneMethodology = () => {
    store.dispatch(cloneMethodology({ token, id: methodology?.id || '', name, isSelfRating }));
    onClose && onClose();
  };

  return (
    <div className="flex flex-col">
      <Heading size="S">Клонирование методики</Heading>
      <Input
        title="Введите название методики"
        className="w-full"
        value={name}
        onChange={handleChangeName}
      />
      <div className="flex align-start" onClick={() => setIsSelfRating(!isSelfRating)}>
        <Input type="checkbox" className="mr-1" checked={isSelfRating} />
        <span className="text-secondary-text">Методика самооценки</span>
      </div>
      {methodology ? (
        <div className="text-primary-text mb-4 italic flex items-center">
          Выбранная методика - {methodology.name}
          <Button
            variant="no-bg"
            className="ml-4 text-main-text"
            onClick={() => setMethodology(undefined)}
          >
            <MdEdit size={24} />
          </Button>
        </div>
      ) : (
        <ProjectCreateFormMethodologySearch onChoose={handleChooseMethodology} />
      )}
      <div className="flex gap-x-2 justify-end">
        <Button version="secondary" onClick={onClose}>
          Отменить
        </Button>
        <Button
          version="secondary"
          onClick={handleCloneMethodology}
          disabled={!name || !methodology}
        >
          Подтвердить
        </Button>
      </div>
    </div>
  );
}
