import { AuditEntitiesMethodology } from './../../api/data-contracts';
import { TokenRequest, ActionWithEntityById, ActionWithEntityByIdsArray } from './../types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Methodologies } from '../../api/Methodologies';
import { AuditEntitiesSharedDocument } from '../../api/data-contracts';
import { logout } from '../../utils/logout';
import { getAllMethodologies } from './methodologies';
import { ApiUrl } from './url';

const methodologiesApi = new Methodologies({
  baseUrl: ApiUrl,
  customFetch: (...fetchParams: Parameters<typeof fetch>) => {
    return fetch(...fetchParams).then((res) => {
      const status = res?.status;
      if (status === 401) logout();

      return res;
    });
  },
});

interface CreateOrUpdateDocumentRequest extends TokenRequest {
  data: AuditEntitiesSharedDocument;
  methodology?: AuditEntitiesMethodology;
}

export const getDocuments = createAsyncThunk(
  'documents/getDocuments',
  async ({ token }: TokenRequest) => {
    const res = await methodologiesApi.getDocumentsList({
      headers: { Authorization: token || '' },
    });
    return res.data;
  },
);

export const getDocumentById = createAsyncThunk(
  'documents/getDocumentById',
  async ({ token, id }: ActionWithEntityById) => {
    const res = await methodologiesApi.getDocumentDetail(id, {
      headers: { Authorization: token || '' },
    });
    return res.data as AuditEntitiesSharedDocument;
  },
);

export const updateDocumentById = createAsyncThunk(
  'documents/updateDocumentById',
  async ({ token, data }: CreateOrUpdateDocumentRequest, { dispatch }) => {
    const res = await methodologiesApi.updateDocumentPartialUpdate(data, {
      headers: { Authorization: token || '' },
    });

    dispatch(getDocumentById({ id: data.id ?? '', token }));
    dispatch(getDocuments({ token }));
    return res.data;
  },
);

export const deleteDocumentById = createAsyncThunk(
  'documents/deleteDocumentById',
  async ({ token, id }: ActionWithEntityById, { dispatch }) => {
    const res = await methodologiesApi.removeDocumentDelete(id, {
      headers: { Authorization: token || '' },
    });

    dispatch(getDocuments({ token }));
    dispatch(getAllMethodologies({ token }));
    return res.data;
  },
);

export const createDocument = createAsyncThunk(
  'documents/createDocuments',
  async ({ token, data, methodology }: CreateOrUpdateDocumentRequest, { dispatch }) => {
    const res = await methodologiesApi.createDocumentCreate(data, {
      headers: { Authorization: token || '' },
    });

    dispatch(getDocuments({ token }));
    dispatch(getAllMethodologies({ token }));

    // if (methodology && res.data.id) {
    //   dispatch(
    //     updateMethodology({
    //       token,
    //       data: {
    //         methodology: {
    //           ...methodology,
    //           documents: [...(methodology.documents || []), res.data.id],
    //         },
    //       },
    //       id: methodology.id || '',
    //     }),
    //   );
    // }

    return res.data;
  },
);

export const getDocumentsList = createAsyncThunk(
  'documents/getDocumentsList',
  async ({ token, ids }: ActionWithEntityByIdsArray) => {
    const res = await methodologiesApi.getDocumentsListCreate(
      { documentIds: ids },
      {
        headers: { Authorization: token || '' },
      },
    );
    return res.data;
  },
);
