import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from '../../components/Table';
import { projectsSelector } from '../../store/selectors/projects';
import { userSelector } from '../../store/selectors/user';
import { formatUnix } from '../../utils/formatUnix';
import { buildName } from '../../utils/buildName';
import { Button } from '../../components/Button';
import { Modal } from '../../components/Modal';
import { ProjectCreateForm } from '../Project/ProjectCreateForm';
import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useGetRole } from '../../utils/hooks';
import { companiesSelector } from '../../store/selectors/companies';

export function ProjectsTable() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { projects } = useSelector(projectsSelector);
  const { usersList } = useSelector(userSelector);
  const { companies } = useSelector(companiesSelector);
  const { isSupervisor, isCompanyMember } = useGetRole();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div>
      <Table
        cols={[
          'Название',
          'Дата начала',
          'Дата завершения',
          'Степень выполнения',
          'Аудиторы',
          'Компания',
          'Последнее изменение',
        ]}
        rows={projects?.map((_) => {
          return [
            <Link
              key={_.id}
              to={`project/${_.id}`}
              className="text-decoration-line: underline text-link"
            >
              {_.name}
            </Link>,
            formatUnix(_.createdOn),
            formatUnix(_.finishedOn),
            `${_.progress || 0}%`,
            <>
              {usersList
                ?.filter((user) => user.id && _.auditors?.includes(user.id))
                .map((item) => buildName(item))
                .join(', ')}
            </>,
            companies
              ?.filter((company) => _.companyIds?.includes(company?.id || ''))
              .map((_) => _.name),
            <>
              {formatUnix(_.changedOn)}, {_.changedBy}
            </>,
          ];
        })}
      />
      {(isSupervisor || isCompanyMember) && (
        <Button className="mt-6" onClick={toggleModal}>
          <div className="flex">
            <MdAdd size={24} className="mr-1" />
            <span>Создать проект {isCompanyMember ? 'по самооценке' : ''}</span>
          </div>
        </Button>
      )}
      <Modal
        onClose={toggleModal}
        visible={modalOpen}
        body={<ProjectCreateForm onClose={toggleModal} />}
      />
    </div>
  );
}
