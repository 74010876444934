import { Methodologies } from './../../api/Methodologies';
import { AuditEntitiesCompany } from './../../api/data-contracts';
import { TokenRequest } from './../types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from '../../utils/logout';
import { ApiUrl } from './url';

interface CompanyReqest extends TokenRequest, AuditEntitiesCompany {}

const methodologiesApi = new Methodologies({
  baseUrl: ApiUrl,
  customFetch: (...fetchParams: Parameters<typeof fetch>) => {
    return fetch(...fetchParams).then((res) => {
      const status = res?.status;
      if (status === 401) logout();

      return res;
    });
  },
});

export const getAllCompanies = createAsyncThunk(
  'methodologies/getAllCompanies',
  async ({ token }: TokenRequest) => {
    const res = await methodologiesApi.getAllCompaniesList({
      headers: { Authorization: token || '' },
    });
    return res.data;
  },
);

export const createCompany = createAsyncThunk(
  'methodologies/createCompany',
  async ({ token, name, industry, inn, size }: CompanyReqest, { dispatch }) => {
    const res = await methodologiesApi.createCompanyCreate(
      { name, industry, inn, size },
      {
        headers: { Authorization: token || '' },
      },
    );
    dispatch(getAllCompanies({ token }));
    return res.data;
  },
);

export const updateCompany = createAsyncThunk(
  'methodologies/updateCompany',
  async ({ token, ...data }: CompanyReqest, { dispatch }) => {
    const res = await methodologiesApi.updateCompanyPartialUpdate(data, {
      headers: { Authorization: token || '' },
    });
    dispatch(getAllCompanies({ token }));
    return res.data;
  },
);
