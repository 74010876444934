import React, { FC } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

type SwapButtonsProps = {
  index: number;
  totalLength: number;
  onSwap: (from: number, to: number) => void;
};

export const SwapButtons: FC<SwapButtonsProps> = ({ index, totalLength, onSwap }) => {
  return (
    <div className="flex">
      {index !== totalLength - 1 ? (
        <div className="cursor-pointer" onClick={() => onSwap(index, index + 1)}>
          <MdKeyboardArrowDown key="down" size={24} />
        </div>
      ) : (
        ''
      )}
      {index !== 0 ? (
        <div className="cursor-pointer" onClick={() => onSwap(index, index - 1)}>
          <MdKeyboardArrowUp key="up" size={24} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
