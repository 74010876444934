import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuditEntitiesProject } from '../../api/data-contracts';
import { Heading } from '../../components/Heading';
import { Table } from '../../components/Table';
import { interviewsSelector } from '../../store/selectors/interviews';

interface ProjectInterviewsTableProps {
  project?: AuditEntitiesProject;
}

export function ProjectInterviewsTable({ project }: ProjectInterviewsTableProps) {
  const { interviews } = useSelector(interviewsSelector);

  const projectInterviews = interviews?.filter((interview) => interview.parentId === project?.id);

  return (
    <div>
      {projectInterviews?.length ? (
        <Table
          cols={['Должность респондента', 'Количество вопросов']}
          rows={projectInterviews.map((_) => {
            return [
              <Link
                key={_.id}
                to={`interview/${_.id}`}
                className="text-decoration-line: underline text-link"
              >
                {_.subject?.position}
              </Link>,
              _.questions?.length ?? 0,
            ];
          })}
        />
      ) : (
        <Heading size="S" transparent>
          Ссылки на интервью не используются в данном проекте
        </Heading>
      )}
    </div>
  );
}
