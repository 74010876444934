import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuditEntitiesMethodology, AuditEntitiesProject } from '../../api/data-contracts';
import { getProjectsDetails, getUserProjects } from '../requests/projects';

interface ProjectsSliceProps {
  projects?: AuditEntitiesProject[];
  currentProject?: AuditEntitiesMethodology | AuditEntitiesProject;
}

const initialState: ProjectsSliceProps = {
  projects: [],
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    dropProjects: () => initialState,
    filterProjectsByIdArray: (state, { payload }: PayloadAction<string[]>) => {
      const newProjects = [...(state.projects || [])].filter((project) =>
        payload.includes(project.id || ''),
      );
      state.projects = newProjects;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectsDetails.fulfilled, (state, { payload }) => {
      const newProjects = state.projects || [];
      if (!newProjects.find((_) => _.id === payload.id)) {
        newProjects.push(payload);
      }

      state.projects = newProjects;
    });
    builder.addCase(getUserProjects.fulfilled, (state, { payload }) => {
      if (!payload.length) state.projects = [];
    });
  },
});

export const projectsReducer = projectsSlice.reducer;
export const { dropProjects, filterProjectsByIdArray } = projectsSlice.actions;
