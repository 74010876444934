import React from 'react';
import { Table } from '../../components/Table';
import { IndicatorComponentsTableProps } from './IndicatorComponentsTable';

export function IndicatorTooltipComponentsTable({ indicator }: IndicatorComponentsTableProps) {
  return (
    <div>
      {indicator?.components?.length ? (
        <Table
          cols={['Номер', 'Что оценивается']}
          rows={indicator.components.map((component, index) => {
            return [
              index + 1,
              component.measuring?.length && component.measuring?.length > 60
                ? component.measuring.slice(0, 60) + '...'
                : component.measuring,
            ];
          })}
          indexColAlign="center"
        />
      ) : (
        <span className="italic text-secondary-text">Компоненты отсутствуют</span>
      )}
    </div>
  );
}
