/* eslint-disable max-lines */
import React, { useState } from 'react';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  AuditEntitiesSharedComponent,
  AuditEntitiesSharedDocument,
  AuditEntitiesSharedIndicator,
  AuditEntitiesSharedQuestionModel,
} from '../../api/data-contracts';
import { Button } from '../../components/Button';
import { SwapButtons } from '../../components/SwapButtons';
import { Table } from '../../components/Table';
import { store } from '../../store';
import { updateIndicatorById } from '../../store/requests/indicators';
import { downloadDocument } from '../../store/requests/projects';
import { authSelector } from '../../store/selectors/auth';
import { documentsSelector } from '../../store/selectors/documents';
import { interviewsSelector } from '../../store/selectors/interviews';
import { useModal } from '../../utils/hooks/useModal';
import { EditComponentModal } from './EditComponentModal';
import { getComponentDocuments, getComponentQuestions } from './helpers';

export type IndicatorComponentsTableProps = {
  indicator: AuditEntitiesSharedIndicator;
};

export interface EditedComponentProps extends AuditEntitiesSharedComponent {
  tableIndex?: number;
}

export interface QuestionModel extends AuditEntitiesSharedQuestionModel {
  takenBy?: string;
}

export function IndicatorComponentsTable({ indicator }: IndicatorComponentsTableProps) {
  const { methodologyId, projectId } = useParams();
  const { interviews } = useSelector(interviewsSelector);
  const { documents } = useSelector(documentsSelector);

  const isProject = !!projectId;

  const allQuestions: QuestionModel[] | undefined = interviews?.reduce(
    (acc: QuestionModel[], interview) => [
      ...acc,
      ...(interview.questions ?? []).map((question) => ({
        ...question,
        interviewId: interview.id,
        takenBy: interview.subject?.position || '',
      })),
    ],
    [],
  );

  const [chosenComponent, setChosenComponent] = useState<EditedComponentProps>();
  const { token } = useSelector(authSelector);

  const onSwapComponents = (from: number, to: number) => {
    handleSwapComponents(from, to, indicator, token);
  };

  const {
    modalOpened: editModalOpened,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();

  const handleOpenModal = (component?: EditedComponentProps) => {
    if (!component) return;
    setChosenComponent(component);
    openEditModal();
  };

  const handleCloseModal = () => {
    setChosenComponent(undefined);
    closeEditModal();
  };

  const handleDeleteComponent = (index: number) => {
    store.dispatch(
      updateIndicatorById({
        data: {
          ...indicator,
          components: (indicator.components ?? []).filter(
            (_, componentIndex) => componentIndex !== index,
          ),
        },
        token,
      }),
    );
  };

  const cols = ['Номер', 'Что оценивается', 'Источники информации'];
  if (!isProject) {
    cols.unshift('');
  } else {
    cols.push(
      'Собранные материалы и основания',
      'Оценка Компании',
      'Комментарий Компании',
      'Оценка Аудитора',
      'Комментарий Аудитора',
      '',
    );
  }

  const sourceId = projectId || methodologyId;
  const sourceName = projectId ? 'project' : 'methodology';

  const handleDownload = (document: AuditEntitiesSharedDocument) => () => {
    if (!document.id || !document.fileName) return;
    store.dispatch(downloadDocument({ token, id: document.id, fileName: document.fileName }));
  };

  return (
    <div>
      {indicator?.components?.length ? (
        <Table
          cols={cols}
          // eslint-disable-next-line complexity
          rows={indicator.components.map((component, index) => {
            const componentDocuments = getComponentDocuments(component, documents || []);
            const componentQuestions = getComponentQuestions(component, allQuestions || []);

            const rows = [
              index + 1,
              component.measuring,
              <>
                {componentDocuments.length !== 0 &&
                  componentDocuments.map((document, index) => (
                    <div key={String(document.id) + '-' + index} className="mb-2">
                      {isProject && document.fileName ? (
                        <div>
                          Документ:{' '}
                          <Button
                            key={'doc' + index}
                            variant="no-bg"
                            className="text-link"
                            onClick={handleDownload(document)}
                          >
                            {document.fileName || ''}
                          </Button>
                          {document.sourceComment && (
                            <div className="italic">Комментарий: {document.sourceComment}</div>
                          )}
                        </div>
                      ) : (
                        <>
                          Документ:{' '}
                          <Link
                            className="text-decoration-line: underline text-link"
                            to={`../${sourceName}/${sourceId}/document/${document.id}/`}
                          >
                            {document.type}
                          </Link>
                          {document.sourceComment && (
                            <div className="italic">Комментарий: {document.sourceComment}</div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                {componentQuestions.length !== 0 &&
                  componentQuestions.map((question, index) => {
                    return (
                      <div key={String(question.id) + '-' + index} className="mb-2">
                        <span className="italic">{question.takenBy}: </span>
                        {sourceId ? (
                          <Link
                            className="text-decoration-line: underline text-link"
                            to={`../${sourceName}/${sourceId}/interview/${question.interviewId}`}
                          >
                            {question.question}
                          </Link>
                        ) : (
                          <div>{question.question}</div>
                        )}
                        {question.sourceComment && (
                          <div className="italic">Комментарий: {question.sourceComment}</div>
                        )}
                      </div>
                    );
                  })}
                {componentDocuments.length === 0 && componentQuestions.length === 0 && (
                  <span className="italic text-secondary-text">Нет источников</span>
                )}
              </>,
            ];

            if (!isProject) {
              rows.unshift(
                <SwapButtons
                  key="swap"
                  index={index}
                  totalLength={indicator?.components?.length || 0}
                  onSwap={onSwapComponents}
                />,
              );
              rows.push(
                <div className="flex" key="edit-delete">
                  <Button
                    key="edit"
                    variant="no-bg"
                    className="ml-4 text-main"
                    onClick={() => handleOpenModal({ ...component, tableIndex: index })}
                  >
                    <MdEdit size={24} />
                  </Button>
                  <Button
                    key="delete"
                    variant="no-bg"
                    className="text-main"
                    onClick={() => {
                      handleDeleteComponent(index);
                    }}
                  >
                    <MdDeleteOutline size={24} />
                  </Button>
                </div>,
              );
            } else {
              rows.push(
                component.justification,
                component.companyRating,
                component.companyComment,
                component.auditorRating,
                component.auditorComment,
                <Button onClick={() => handleOpenModal({ ...component, tableIndex: index })}>
                  <div className="flex">
                    <MdEdit size={24} className="mr-1" /> <div>Заполнить</div>
                  </div>
                </Button>,
              );
            }

            return rows;
          })}
          indexColAlign="left"
        />
      ) : (
        <span className="italic text-secondary-text">Компоненты отсутствуют</span>
      )}
      <EditComponentModal
        indicator={indicator}
        component={chosenComponent}
        modalOpened={editModalOpened}
        onClose={handleCloseModal}
        methodologyId={methodologyId}
      />
    </div>
  );
}

const handleSwapComponents = (
  from: number,
  to: number,
  indicator: AuditEntitiesSharedIndicator,
  token?: string | null,
  // eslint-disable-next-line max-params
) => {
  if (!indicator.components) return;

  const components = [...indicator.components];

  const temp = components[from];
  components[from] = components[to];
  components[to] = temp;

  store.dispatch(updateIndicatorById({ data: { ...indicator, components }, token }));
};
