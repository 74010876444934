import React, { useState } from 'react';
import { Heading } from '../../components/Heading';
import { Button } from '../../components/Button';
import { Modal } from '../../components/Modal';
import { AuditEntitiesSharedQuestionModel } from '../../api/data-contracts';
import { Textarea } from '../../components/Textarea';
import { Select } from '../../components/Select';
import { QuestionWithComment, QuestionWithPosition } from './types';
import { MdEdit } from 'react-icons/md';

interface EditComponentSourceQuestionModalProps {
  modalOpened: boolean;
  onSave: (questionWithComment: QuestionWithComment) => void;
  onClose: () => void;
  questions: QuestionWithPosition[];
}

export function EditComponentSourceQuestionModal({
  onSave,
  onClose,
  modalOpened,
  questions,
}: EditComponentSourceQuestionModalProps) {
  const [selectedQuestion, setSelectedQuestion] = useState<AuditEntitiesSharedQuestionModel | null>(
    null,
  );
  const [comment, setComment] = useState('');

  const handleSave = () => {
    if (selectedQuestion) {
      onSave({ ...selectedQuestion, sourceComment: comment });
    }
    setSelectedQuestion(null);
    setComment('');
    onClose();
  };

  return (
    <Modal
      visible={modalOpened}
      onClose={onClose}
      body={
        <div>
          <Heading className="mb-4" size="S">
            Создание источника информации
          </Heading>
          {!selectedQuestion && (
            <Select
              title="Вопросы"
              items={questions}
              itemToString={(question) => `${question.position}: ${question.question}`}
              onChoose={setSelectedQuestion}
              filterFunction={(searchString, question) =>
                `${question.position}: ${question.question}`
                  .toLowerCase()
                  .includes(searchString.toLowerCase()) ?? false
              }
            />
          )}
          {selectedQuestion && (
            <div className="text-primary-text mb-4 italic flex items-center">
              Выбранный вопрос - {selectedQuestion.question}
              <Button
                variant="no-bg"
                className="ml-4 text-main-text"
                onClick={() => setSelectedQuestion(null)}
              >
                <MdEdit size={24} />
              </Button>
            </div>
          )}
          <Textarea
            title="Комментарий"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            className="w-full h-40"
          />
          <div className="flex justify-end gap-2">
            <Button onClick={onClose}>Отменить</Button>
            <Button disabled={!selectedQuestion} onClick={handleSave}>
              Создать
            </Button>
          </div>
        </div>
      }
    />
  );
}
