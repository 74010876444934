import React from 'react';
import { MdAdd } from 'react-icons/md';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { useModal } from '../../utils/hooks/useModal';
import { EditSectionModal } from './EditSectionModal';
import { SectionsListTable } from './SectionsListTable';

export function SectionsList() {
  const { modalOpened, openModal, closeModal } = useModal();

  return (
    <>
      <Heading className="mb-8">Разделы</Heading>
      <Button className="mb-8" onClick={openModal}>
        <div className="flex">
          <MdAdd size={24} className="mr-1" />
          <span>Добавить раздел</span>
        </div>
      </Button>
      <SectionsListTable />
      <EditSectionModal modalOpened={modalOpened} onClose={closeModal} />
    </>
  );
}
