/* eslint-disable max-lines */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  AuditEntitiesMethodology,
  AuditEntitiesProject,
  AuditEntitiesSharedSection,
} from '../../api/data-contracts';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { Modal } from '../../components/Modal';
import { Table } from '../../components/Table';
import { store } from '../../store';
import { updateMethodology } from '../../store/requests/methodologies';
import { authSelector } from '../../store/selectors/auth';
import { sectionsSelector } from '../../store/selectors/sections';
import { MdDeleteOutline } from 'react-icons/md';
import { SwapButtons } from '../../components/SwapButtons';

interface MethodologySectionsTableProps {
  methodology?: AuditEntitiesMethodology;
  project?: AuditEntitiesProject;
  isEditable?: boolean;
}

export function MethodologySectionsTable({
  methodology,
  project,
  isEditable = false,
}: MethodologySectionsTableProps) {
  const { token } = useSelector(authSelector);
  const { sections } = useSelector(sectionsSelector);

  const [deleteModalData, setDeleteModalData] = useState<AuditEntitiesSharedSection | null>();

  const handleCloseModal = () => {
    setDeleteModalData(null);
  };

  const onDeleteSection = () => {
    handleDeleteSection(methodology, deleteModalData, token);
    handleCloseModal();
  };

  const onSwapSections = (from: number, to: number) => {
    handleSwapSections(from, to, methodology, token);
  };

  const cols = ['Раздел'];
  if (isEditable) {
    cols.unshift('');
    cols.push('');
  }

  const source = methodology || project;

  return (
    <div>
      {source?.sections?.length ? (
        <Table
          cols={cols}
          rows={source.sections.map((sectionId, index) => {
            const section = sections?.find((section) => section.id === sectionId);
            if (!section || !source.sections) return [];

            const rows = [
              <div key={section.id} className="flex items-center">
                <Link
                  className="text-decoration-line: underline text-link"
                  to={`section/${section.id}/`}
                >
                  {section.name}
                </Link>
              </div>,
            ];

            if (isEditable) {
              rows.unshift(
                <SwapButtons
                  key="swap"
                  index={index}
                  totalLength={source.sections.length}
                  onSwap={onSwapSections}
                />,
              );
              rows.push(
                <div key="delete">
                  <Button
                    variant="no-bg"
                    className="text-main ml-4"
                    onClick={() => setDeleteModalData(section)}
                  >
                    <MdDeleteOutline size={24} />
                  </Button>
                </div>,
              );
            }

            return rows;
          })}
          indexColAlign="left"
        />
      ) : (
        <Heading size="S" transparent>
          Рабочие таблицы отсутствуют
        </Heading>
      )}
      <Modal
        visible={!!deleteModalData}
        onClose={handleCloseModal}
        body={
          <div className="flex flex-col">
            <Heading size="XS">
              <span className="font-normal">Вы действительно хотите удалить раздел</span> &quot;
              {deleteModalData?.name}&quot; <span className="font-normal">из методики</span> &quot;
              {methodology?.name}&quot;
            </Heading>
            <div className="flex gap-x-2 justify-end">
              <Button version="secondary" onClick={handleCloseModal}>
                Отменить
              </Button>
              <Button version="secondary" onClick={onDeleteSection}>
                Подтвердить
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
}

const handleDeleteSection = (
  project?: AuditEntitiesMethodology,
  deleteModal?: AuditEntitiesSharedSection | null,
  token?: string | null,
) => {
  if (!project?.sections || !project.id) return;

  const sections = [...project.sections];
  const deletedSectionIndex = sections.findIndex((_) => _ === deleteModal?.id);

  if (deletedSectionIndex > -1) {
    sections.splice(deletedSectionIndex, 1);
    store.dispatch(
      updateMethodology({
        data: {
          methodology: { ...project, sections },
          newVersion: false,
        },
        id: project.id,
        token,
      }),
    );
  }
};

const handleSwapSections = (
  from: number,
  to: number,
  methodology?: AuditEntitiesMethodology,
  token?: string | null,
  // eslint-disable-next-line max-params
) => {
  if (!methodology?.sections || !methodology.id) return;

  const sections = [...methodology.sections];

  const temp = sections[from];
  sections[from] = sections[to];
  sections[to] = temp;

  store.dispatch(
    updateMethodology({
      data: {
        methodology: { ...methodology, sections },
        newVersion: false,
      },
      id: methodology.id,
      token,
    }),
  );
};
