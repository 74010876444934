import React from 'react';
import { useParams } from 'react-router-dom';
import { Heading } from '../../components/Heading';
import { useSelector } from 'react-redux';
import { projectsSelector } from '../../store/selectors/projects';
import { MethodologySectionsTable } from '../Methodology/MethodologySectionsTable';
import { ProjectTable } from './ProjectTable';
import { ProjectDocumentsTable } from './ProjectDocumentsTable';
import { ProjectInterviewsTable } from './ProjectInterviewsTable';
import { Tabs } from '../../components/Tabs';

export function Project() {
  const { projects } = useSelector(projectsSelector);
  const { projectId } = useParams();

  const project = projects?.find((_) => _.id === projectId);

  const tabs = [
    {
      name: 'Рабочие таблицы',
      component: <MethodologySectionsTable project={project} />,
    },
  ];

  if (!project?.isSelfRating) {
    tabs.push(
      {
        name: 'Документы',
        component: <ProjectDocumentsTable project={project} />,
      },
      {
        name: 'Интервью',
        component: <ProjectInterviewsTable project={project} />,
      },
    );
  }

  return (
    <div>
      <Heading>{project?.name}</Heading>
      <ProjectTable project={project} />
      <Tabs tabs={tabs} />
    </div>
  );
}
