import React, { useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuditEntitiesSharedInterview, AuditEntitiesMethodology } from '../../api/data-contracts';
import { Button } from '../../components/Button';
import { EditDeleteButtons } from '../../components/EditDeleteButtons';
import { Heading } from '../../components/Heading';
import { Table } from '../../components/Table';
import { store } from '../../store';
import { deleteInterviewById } from '../../store/requests/interviews';
import { authSelector } from '../../store/selectors/auth';
import { interviewsSelector } from '../../store/selectors/interviews';
import { useModal } from '../../utils/hooks/useModal';
import { EditInterviewModal } from '../Interviews/EditInterviewModal';
import { updateMethodology } from '../../store/requests/methodologies';
import { SwapButtons } from '../../components/SwapButtons';

interface MethodologyInterviewsTableProps {
  methodology?: AuditEntitiesMethodology;
}

export function MethodologyInterviewsTable({ methodology }: MethodologyInterviewsTableProps) {
  const { token } = useSelector(authSelector);
  const { interviews } = useSelector(interviewsSelector);

  const { modalOpened, openModal, closeModal } = useModal();

  const [selectedInterview, setSelectedInterview] = useState<AuditEntitiesSharedInterview | null>(
    null,
  );

  const handleDeleteInterview = (id: string) => {
    store.dispatch(deleteInterviewById({ id, token }));
  };

  const handleEditInterview = (interview?: AuditEntitiesSharedInterview) => {
    if (!interview) return;
    setSelectedInterview(interview);
    openModal();
  };

  const handleCloseModal = () => {
    setSelectedInterview(null);
    closeModal();
  };

  const onSwapInterviews = (from: number, to: number) => {
    if (methodology) {
      handleSwapInterviews(from, to, methodology, token);
    }
  };

  return (
    <div>
      <Button className="mb-8" onClick={openModal}>
        <div className="flex">
          <MdAdd size={24} className="mr-1" />
          <span>Добавить интервью</span>
        </div>
      </Button>
      {methodology?.interviews?.length ? (
        <Table
          cols={['', 'Должность респондента', 'Количество вопросов', '']}
          rows={methodology.interviews.map((interviewId, index) => {
            const interview = interviews?.find((interview) => interview.id === interviewId);
            return [
              <SwapButtons
                key="swap"
                index={index}
                totalLength={methodology?.interviews?.length ?? 0}
                onSwap={onSwapInterviews}
              />,
              <Link
                key={interviewId}
                to={`interview/${interviewId}`}
                className="text-decoration-line: underline text-link"
              >
                {interview?.subject?.position ?? ''}
              </Link>,
              interview?.questions?.length ?? 0,
              <EditDeleteButtons
                key="edit-delete"
                onEditClick={() => handleEditInterview(interview)}
                onDeleteClick={() => handleDeleteInterview(interviewId)}
              />,
            ];
          })}
        />
      ) : (
        <Heading size="S" transparent>
          Ссылки на интервью не используются в данной методике
        </Heading>
      )}
      <EditInterviewModal
        parentId={methodology?.id ?? undefined}
        modalOpened={modalOpened}
        onClose={handleCloseModal}
        interview={selectedInterview ?? undefined}
      />
    </div>
  );
}

const handleSwapInterviews = (
  from: number,
  to: number,
  methodology: AuditEntitiesMethodology,
  token?: string | null,
  // eslint-disable-next-line max-params
) => {
  if (!methodology.interviews) return;

  const interviews = [...methodology.interviews];

  const temp = interviews[from];
  interviews[from] = interviews[to];
  interviews[to] = temp;

  store.dispatch(
    updateMethodology({
      id: methodology.id ?? '',
      token,
      data: { newVersion: false, methodology: { ...methodology, interviews } },
    }),
  );
};
