import React, { FC } from 'react';
import { AuditEntitiesSharedInterview } from '../../api/data-contracts';
import { Table } from '../../components/Table';

type InterviewTableProps = {
  interview: AuditEntitiesSharedInterview;
};

export const InterviewTable: FC<InterviewTableProps> = ({ interview }) => {
  return (
    <Table
      className="mb-8"
      cols={[
        // 'Имя респондента',
        'Должность респондента',
        // 'Дата начала работы респондента в должности',
        // 'Интервьюер',
        // 'URL',
        'Количетсво вопросов',
      ]}
      rows={[
        [
          // interview.subject?.name ?? '',
          interview.subject?.position ?? '',
          // interview.subject?.inPositionFrom ?? '',
          // interview.takenBy,
          // interview.audiofilePath,
          interview.questions?.length ?? 0,
        ],
      ]}
    />
  );
};
