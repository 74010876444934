import React from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/selectors/user';
import { Table } from '../../components/Table';
import { buildFullName } from '../../utils/buildFullName';
import { getRoleName } from '../../utils/hooks/useGetRole';
import { formatUnix } from '../../utils/formatUnix';
import { AuditEntitiesAccountRole } from '../../api/data-contracts';
import { MdCheck } from 'react-icons/md';
import { Button } from '../../components/Button';
import { companiesSelector } from '../../store/selectors/companies';
import { store } from '../../store';
import { verifyUser } from '../../store/requests/auth';
import { authSelector } from '../../store/selectors/auth';

export function UsersList() {
  const { usersList } = useSelector(userSelector);
  const { companies } = useSelector(companiesSelector);
  const { token } = useSelector(authSelector);

  const handleVerifyUser = (id: string) => {
    store.dispatch(verifyUser({ id, token }));
  };

  return (
    <Table
      cols={[
        'ФИО',
        'Роль',
        'Email',
        'Дата регистрации',
        'Номер телефона',
        'Компании',
        'Подтвердить',
      ]}
      rows={usersList?.map((_) => [
        buildFullName(_),
        getRoleName(_.role),
        _.email,
        formatUnix(_.createdAt),
        _.phoneNumber,
        companies
          ?.filter((__) => _.companyIds?.includes(__.id || ''))
          .map((__) => __.name)
          .join(', '),
        _.role === AuditEntitiesAccountRole.Supervisor ? (
          <>
            {_.isVerified ? (
              <div className="flex items-center text-green-300">
                Пользователь подтверждён <MdCheck />
              </div>
            ) : (
              <Button
                variant="no-bg"
                className="text-main"
                onClick={() => handleVerifyUser(_.id || '')}
              >
                Подтвердить
              </Button>
            )}
          </>
        ) : (
          <div key="0-0" className="text-secondary-text italic">
            Подтверждение не требуется
          </div>
        ),
      ])}
    />
  );
}
